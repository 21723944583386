import theEcho from '@/echoInstance'
import axios from "@/axiosInstance"

const classifiers = {
    namespaced: true,
    state: () => ({
        all: [],
        channel: null,
        loadingExams: [],
        importing: {},
        fetched: {},
    }),
    getters: {
        fetched: (state) => (id) => {
            if (state.fetched[id] == null) {
                return false
            }
            return state.fetched[id]
        },
        class: (state) => (id) => {
            return state.all.filter(({ exam_class_id }) => exam_class_id == id)
        },
        exam: (state) => (id) => {
            const classifiers = state.all.filter(({ exam_id }) => exam_id == id)
            return classifiers
        },
        examFirst: (state) => (id) => {
            return state.all.find(({ exam_id }) => exam_id == id)
        },
        getId: (state) => (id) => {
            return state.all.find(classifier => classifier.id == id)
        },
        groups: (state) => (groups) => {
            return state.all.filter(classifier => groups.includes(classifier.exam_class_id))
        },
        groupsClassifiers: (state) => (groups) => {
            return state.all.filter(classifier => !classifier.supervisor && groups.includes(classifier.exam_class_id))
        },
        groupsSupervisors: (state) => (groups) => {
            return state.all.filter(classifier => classifier.supervisor && groups.includes(classifier.exam_class_id))
        },
        loading: (state) => (id) => {
            return state.loadingExams.includes(id)
        },
        importing: state => id => {
            return state.importing[id] == null ? false : state.importing[id]
        },
        userId: (state) => (id) => {
            return state.all.find(classifier => classifier.user_id == id)
        },
    },
    mutations: {
        add(state, classifier) {
            let index = state.all.findIndex(({ id }) => id == classifier.id)
            if (index < 0) {
                state.all.push(classifier)
                return
            }
            state.all.splice(index, 1, classifier)
        },
        addMany(state, { classifiers, id, fetched }) {
            state.fetched[id] = fetched
            for (let classifier of classifiers) {
                let index = state.all.findIndex(({ id }) => id == classifier.id)
                if (index > -1) {
                    state.all.splice(index, 1, classifier)
                } else {
                    state.all.push(classifier)
                }
            }
        },
        channel(state, channel) {
            state.channel = channel
        },
        clear(state) {
            state.all = []
            state.loadExams = []
        },
        importing(state, data) {
            state.importing[data.examId] = data.state
        },
        loadExam(state, examId) {
            state.loadingExams.push(examId)
        },
        loadedExam(state, examId) {
            state.loadingExams = state.loadingExams.filter(id => id != examId)
        },
        remove(state, classifier) {
            let index = state.all.findIndex(({ id }) => id == classifier.id)
            if (index < 0) {
                return
            }
            state.all.splice(index, 1)
        },
        update(state, { classifierId, itemId, o, n }) {
            let index = state.all.findIndex(({ id }) => id == classifierId)
            if (index < 0) {
                return
            }
            let classifier = state.all[index]
            let classified = classifier.classified_items
            if (classifier == null) {
                return
            }
            if (classified[itemId] == null) {
                classified[itemId] = {}
            }
            if (classified[itemId][o] == null) {
                classified[itemId][o] = 0
            }
            if (classified[itemId][n] == null) {
                classified[itemId][n] = 0
            }
            classified[itemId][o] -= 1
            classified[itemId][n] += 1
            classifier.classified_items = classified
            state.all.splice(index, 1, { ...classifier })
        },
    },
    actions: {
        async import({ commit }, { examId, file }) {
            commit('importing', { examId, state: true })
            let formData = new FormData()
            formData.append("file", file)
            await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            commit('importing', { examId, state: false })
        },
        async update({ commit }, { classifier, o, n }) {
            if (classifier == null) {
                return
            }
            commit('update', { classifier, rem: o, add: n })
        },
        async upgrade({ commit }, classifierId) {
            let res = await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/classifiers/${classifierId}`,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            commit('add', res.data.data)
        },

        async finishIt({ commit }, id) {
            let res = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/classifiers/${id}/finish`)
            console.log(res.data.data)
            commit('add', res.data.data)
        },

        async get({ commit }, id) {
            let res = await axios.get(`${process.env.VUE_APP_API_HOST}/users/${id}/classifiers`)
            commit('addMany', res.data.data)
        },
        async getAll({ state, commit }, { examId, page = null }) {
            if (state.loadingExams.includes(examId)) {
                return
            }
            commit('loadExam', examId)
            let res = null
            if (page == null) {
                res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`)
            } else {
                res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`, {
                    params: {
                        page
                    }
                })
            }
            commit('addMany', { classifiers: res.data.data, id: examId, fetched: true })
            commit('loadedExam', examId)
        },
        async getId({ commit }, id) {
            const res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/classifiers/${id}`)
            commit('add', res.data.data)
        },
        async getClassClassifiers({ commit }, classId) {
            try {
                let res = await axios.get(`${process.env.VUE_APP_API_HOST}/exams/classes/${classId}/classifiers`)
                commit('addMany', res.data.data)
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        listen({ state, commit }, classifier_id) {
            if (state.channel != null) {
                console.log("STOP LISTENING CLASS");
                state.channel.stopListening('Classifiers\\Updated')
                commit('channel', null)
            }
            let channel = theEcho.echo.private(`classifiers.${classifier_id}`)
            console.log(`LISTENING CLASSIFIER ${classifier_id}`);
            channel.listen('Classifiers\\Updated', (e) => {
                let classifier = e.classifier
                classifier.exam_id = e.exam_id
                console.log(e)
                commit('add', classifier)
            })
            commit('channel', channel)
        },

        async add({ commit }, { examClass, classifierId }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/classes/${examClass}/classifiers`,
                    { user_id: classifierId }
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async remove({ commit }, { classifierId }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/classes/classifiers/${classifierId}`,
                );
                commit("remove", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

    },
};

export default classifiers;
