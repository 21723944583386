<template>
    <div class="content-exams-edit">
        <BubbleContent class="exams-edit-team" @submit="addIaveTeam" :title="`${$tc('generic.teamIave')}`">
            <template #form>
                <p v-text="`${$t('action.shaping.lookForSupervisor')}`"></p>
                <input type="text" v-model="searchSupervisor">
                <div class="usersList">
                    <p v-for="supervisor in groupSupervisor" :key="supervisor.id"
                        @click.prevent="addIaveTeam(supervisor)">
                        <span v-if="supervisor.name">
                            <strong v-text="`${$t('generic.name')}:`"></strong> {{ supervisor.name }} |
                        </span>
                        <span v-if="supervisor.email">
                            <strong v-text="`${$t('generic.email')}:`"></strong> {{ supervisor.email }} |
                        </span>
                        <span v-if="supervisor.nr_classificador">
                            <strong v-text="`${$t('generic.classifierNumber')}:`"></strong> {{
                                supervisor.nr_classificador }} |
                        </span>
                    </p>
                </div>

                <ul>
                    <li v-for="user in iaveTeamMembers" :key="user.id" @click.prevent="removeIaveTeam(user)">
                        <span v-text="user.nr_classificador"></span>
                        <span v-text="user.name"></span>
                    </li>
                </ul>
            </template>
        </BubbleContent>

        <CreateGroup :id="id" class="group-new" v-if="exam && exam.kind == 0" />

        <div class="groups" v-if="exam && exam.kind == 0">
            <ExamGroupCard v-for="group in groups" :key="group.id" :id="group.id" class="group" />
        </div>

    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';

import CreateGroup from '@/components/exams/CreateGroup.vue'
import ExamGroupCard from '@/components/exams/GroupCard.vue'

import moment from 'moment'
import uuid4 from 'uuid4';

import axios from "@/axiosInstance"

import api from "@/api"

export default {
    components: {
        BubbleContent,
        CreateGroup,
        ExamGroupCard,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            name: "",
            table: "",
            studentId: "",
            searchSupervisor: "",
            selectedIds: [],
            kind: '',
            starts_at: '',
            ends_at: '',
            utilizador: '',
            uid: null,
            options: [
                { text: this.$t('generic.regular'), value: '0' },
                { text: this.$t('generic.practical'), value: '1' },
            ],
            date: new Date().toLocaleString(),
            iaveTeamButtons: [
                { text: `${this.$t('action.add')}`, type: 'submit' },
            ],
            iaveTeamMembers: [],
            importFile: null,
            importFileAttachments: null,
            uploadFiles: null,
            uploading: [],
            uploaded: false,
            exportButtons: [
                { text: this.$t('action.create'), type: 'submit' },
                { text: this.$t('action.update'), type: 'submit', click: this.refreshExportFiles },
            ],
        };
    },
    methods: {
        async addIaveTeam(user) {
            console.log(user)
            await api.exams.iaveTeams.create(this.id, user.id)
            this.getIaveTeam()
        },
        download(file) {
            this.$store.dispatch('exams/download', { id: this.id, file })
        },
        async getIaveTeam() {
            this.iaveTeamMembers = (await api.exams.iaveTeams.index(this.id)).data.data
        },
        refreshExportFiles() {
            this.$store.dispatch('exams/getExportFiles', this.id)
        },
        async removeIaveTeam(user) {
            await api.exams.iaveTeams.destroy(this.id, user.id)
            this.getIaveTeam()
        },
        startExport() {
            this.$store.dispatch('exams/export', this.id)
        },
        importFiles() {
            this.$store.dispatch('exams/import', {
                id: this.id,
                file: this.importFile ? this.importFile[0] : null,
                attachment: this.importFileAttachments ? this.importFileAttachments[0] : null,
            })
        },
        async upload() {
            this.uploaded = false
            this.uploading = []
            for (let file of this.uploadFiles) {
                this.uploading.push({
                    file,
                    status: this.$t('action.waiting'),
                    complete: false,
                    error: false,
                })
            }
            for (let file of this.uploading) {
                let formData = new FormData()
                formData.append("upload", file.file)
                try {
                    file.status = `${this.$t('action.shaping.uploading')}...`
                    let res = await axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch (e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
            }
        },
        submit() {
            this.$store.dispatch('exams/edit', { id: this.id, uid: this.uid, exam: this.ExamData })
        },
    },
    computed: {
        button() {
            return [
                { text: this.$t('action.edit'), type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.name &&
                this.table &&
                this.studentId
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        groupSupervisor() {
            if (this.searchSupervisor) {
                return this.remainingUsers.filter((supervisor) => {
                    let search = this.searchSupervisor.toString().toLocaleLowerCase()
                    let includesName = false
                    if (supervisor.name) includesName = supervisor.name.toLowerCase().includes(search)
                    let includesNr = false
                    if (supervisor.nr_classificador) includesNr = supervisor.nr_classificador.toLowerCase().includes(search)
                    return includesName || includesNr
                }).slice(0, 5)
            } else {
                return []
            }
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        uploadingText() {
            if (this.uploaded) {
                return this.$t('action.shaping.uploaded')
            }
            return this.$t('action.shaping.uploading')
        },
        users() {
            return this.$store.state.users.all
        },
        remainingUsers() {
            return this.users.filter(({ id }) => !this.selectedIds.includes(id))
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        startsAtDate() {
            return moment(this.starts_at).format("YYYY-MM-DD HH:mm:ss")
        },
        endsAtDate() {
            return moment(this.ends_at).format("YYYY-MM-DD HH:mm:ss")
        },
        ExamData() {
            return {
                name: this.name,
                table: this.table,
                student_col: this.studentId,
                kind: this.kind,
                starts_at: this.startsAtDate,
                ends_at: this.endsAtDate,
            }
        },
        examExportFiles() {
            return this.$store.getters['exams/exportFiles'](this.id)
        },
        examExportFilesDownload() {
            return this.$store.state.exams.examExportFilesDownload
        },
    },
    watch: {
        exam: {
            immediate: true,
            handler() {
                if (this.exam != null) {
                    this.name = this.exam.name
                    this.table = this.exam.table
                    this.studentId = this.exam.student_col
                    this.kind = this.exam.kind
                    this.starts_at = this.exam.starts_at
                    this.ends_at = this.exam.ends_at
                }
            },
        }
    },
    mounted() {
        this.uid = uuid4();
        this.refreshExportFiles();
        this.getIaveTeam()
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';


.content-exams-edit {
    --gap: #{$regular-padding};
    color: $black;
    display: grid;
    grid-gap: var(--gap);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content auto;
    grid-template-areas:
        "edit team"
        "groups groups";

    .groups {
        grid-column: 1 / 3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--gap);

        .group {
            min-height: 300px;
            height: 800px;
            max-height: 90vh;
        }
    }

    .exams-edit-upload {
        grid-area: upload;
    }

    .exams-edit-edit {
        grid-area: edit;
    }

    .exams-edit-team {
        grid-area: team;
    }

    .exams-edit-import {
        grid-area: import;
    }

    .exams-edit-export {
        grid-area: export;
    }
}
</style>
