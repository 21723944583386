<template>
    <BubbleContent :title="`${$tc('generic.new')}`" :buttons="buttons" @submit="submit" :loadingInfo="loading"
        class="new">
        <template #form>
            <InputContent :label="`${$t('generic.name')}`" v-model="name" />
            <SelectComponent :label="$t('models.school')" v-model="schoolId" :options="schoolOptions" />

            <InputContent :label="$t('generic.executionDate')" type="datetime-local" v-model="executionDate"
                :choose="date" />
            <InputContent :label="$t('generic.startAt')" type="datetime-local" v-model="fromDate" :choose="date" />
            <InputContent :label="$t('generic.endAt')" type="datetime-local" v-model="toDate" :choose="date" />
        </template>
    </BubbleContent>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';
import SelectComponent from "@/components/input/SelectComponent"

export default {
    props: {
        exam: {
            required: true,
        },
        schools: {
            required: true,
        },
    },
    components: {
        BubbleContent,
        InputContent,
        SelectComponent,
    },
    data() {
        return {
            name: "",
            schoolId: "",
            executionDate: "",
            fromDate: "",
            toDate: "",
            buttons: [
                { text: `${this.$t('action.save')}`, type: 'submit' },
            ],
            names: [
                { text: `${this.$t('generic.name')}` },
                { text: `${this.$t('generic.code')}` },
            ],
            loading: {
                timeout: 3000,
                done: false,
            }
        };
    },
    methods: {
        submit() {
            this.$store.dispatch('exams/sessions/create', { exam: this.exam.id, session: this.newSessionData })
            // TODO: Wait fot session to be created
            this.loading = { timeout: 1000, done: true }
        },
    },
    computed: {
        schoolOptions() {
            return this.schools.map((school) => {
                return { text: `[${school.code}] ${school.name}`, value: school.id }
            })
        },
        newSessionData() {
            return {
                name: this.name,
                school_id: this.schoolId,
                execution_at: this.executionDate,
                classification_from: this.fromDate,
                classification_to: this.toDate,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.new {
    color: $black;
    height: fit-content;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;
            }


        }
    }

}
</style>
