<template>
    <BubbleContent :loadingInfo="loadingInfo" :title="`${$t('action.import')}`" :buttons="button" @submit="upload">
        <template #form>
            <InputFile :button="`${$t('generic.attachment')}`" :label="`${$t('generic.attachment')}`" :multiple="true"
                v-model="uploadFiles" />
            <div class="files" v-if="uploading.length">
                <h3 v-text="uploadingText"></h3>
                <ul>
                    <li v-for="file in uploading" :key="file.file.name">
                        {{ file.file.name }} - {{ file.status }}
                    </li>
                </ul>
            </div>
        </template>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputFile from '../Inputs/InputFile.vue';
import uuid4 from 'uuid4';
import axios from "@/axiosInstance"

export default {
    components: {
        BubbleContent,
        InputFile,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            uid: null,
            date: new Date().toLocaleString(),
            importFile: null,
            importFileAttachments: null,
            uploadFiles: [],
            uploading: [],
            uploaded: false,
        };
    },
    methods: {
        async upload() {
            for (let file of this.uploadFiles) {
                this.uploading.push({
                    file,
                    status: 'Em espera',
                    complete: false,
                    error: false,
                })
            }
            for (let file of this.uploading) {
                let formData = new FormData()
                formData.append("upload", file.file)
                try {
                    file.status = "A carregar..."
                    let res = await axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch (e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
                let successObj = { message: 'uploaded', error: false, done: true, uid: this.uid }
                this.$store.commit('loadingMessages/messages', successObj, { root: true })
            }
        },
    },
    computed: {
        button() {
            return [
                { text: `${this.$t('action.import')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.uploadFiles[0] != null
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        loadingInfo() {
            return this.messages
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
    },
    mounted() {
        this.uid = uuid4();
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }

    },

}
</script>

<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';
</style>
