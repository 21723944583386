<template>
    <div class="item-container">
        <div class="code-name-content" v-for="name in codeParams" :key="name">
            <h4 class="item-code-name">{{ name }}</h4>
            <div class="item-code-values">
                <button v-for="code in codeValues[name]" :key="code" class="buttons-item-codes"
                    @click.prevent="selectCode(code)" :class="{
                        selected: selected.includes(code),
                        oldSelected: selectedCodes.includes(code.id),
                    }" :disabled="restriction.includes(code.id)">
                    <p>{{ code.value }}</p>
                    <div class="others">
                        <template v-for="others, i in otherCodes" :key="i">
                            <div :class="`other-${i}`" v-if="others.includes(code.id)"></div>
                        </template>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        canSelect: {
            default: true,
        },
        codes: {
            required: true
        },
        modelValue: { default: null, type: Array },
        selectedCodes: {
            default: [],
        },
        itemId: {
            required: true,
        },
        otherCodes: {
            default: [],
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selected: this.modelValue,
        }
    },
    methods: {
        selectCode(code) {
            if (!this.canSelect) {
                return
            }
            if (this.selected.includes(code)) {
                this.selected = this.selected.filter((c) => c != code)
                return
            }
            this.selected = this.selected.filter((c) => c.name != code.name)
            this.selected.push(code)
            this.$emit('update:modelValue', this.selected)
        },
    },
    computed: {

        codeParams() {
            if (this.codes) {
                let names = this.codes.map(({ name }) => name)
                return names.filter((name, index) => names.indexOf(name) === index)
            }
            return []
        },
        codeValues() {
            const codeValues = {}
            let codes = this.codes

            for (let param of this.codeParams) {
                codeValues[param] = codes
                    .filter(code => code.name == param)
            }
            let result = codeValues
            return result
        },
        restriction() {
            if (this.selected.length == 0) {
                return []
            }
            let selectedIds = this.selected.map(({ id }) => id)
            let restriction = this.restrictions.find((restriction) => {
                let combination = restriction.rules.combination
                for (let codeId of combination) {
                    if (!selectedIds.includes(codeId)) {
                        return false
                    }
                }
                return selectedIds.length >= combination.length
            })
            if (restriction == null) {
                return []
            }
            return restriction.rules.blocked
        },
        restrictions() {
            return this.$store.getters['exams/items/restrictions'](this.itemId)
        },
    },
    watch: {
        restriction() {
            if (this.restriction.length == 0) {
                return
            }
            console.log(this.restriction)
            this.selected = this.selected.filter(({ id }) => {
                console.log(id)
                console.log(!this.restriction.includes(id))
                return !this.restriction.includes(id)
            })
        },
        modelValue() {
            if (this.modelValue.length == 0) {
                this.selected = this.modelValue
            }
        },
    },
    mounted() { },
}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.item-container {
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .others {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;

        >* {
            flex-grow: 1;
            flex-basis: 0;
            height: 100%;
        }

        .other-0 {
            background: rgba(#aa4400, .4);
        }

        .other-1 {
            background: rgba(#991122, .4);
        }

        .other-2 {
            background: rgba(#2200aa, .4);
        }

        .other-3 {
            background: rgba(#22aaaa, .4);
        }

        .other-4 {
            background: rgba(#aa992a, .4);
        }

    }

    .code-name-content {
        flex-grow: 1;
    }

    .item-code-values {
        display: grid;
        grid-gap: 0.5rem;

        .buttons-item-codes {
            background-color: $white;
            cursor: pointer;
            color: $black;
            padding: 0.5em;
            border: 1px solid $primary-color;
            width: 100%;
            margin-top: 5px;
            user-select: none;
            position: relative;

            &:disabled {
                color: rgba($black, .7);
                border-color: rgba($black, 1);
                background-color: rgba($black, .15);
                pointer-events: none;
            }

            &:active {
                background-color: rgba($black, .3);

            }

            &.oldSelected {
                background-color: rgba($primary-color, .3);

            }

            &.selected {
                background-color: $primary-color;
                color: $white;
            }

            p {
                margin: 0;
                font-weight: 700;
                z-index: 10;
                position: relative;
            }

            &.codes_active {
                background-color: $primary-color;
                color: $white;
            }
        }
    }

}
</style>
