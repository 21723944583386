<template>
    <tr class="info-list-classifiers" :style="{ '--progress': `${progressPercent}%` }"
        v-if="info.total > 0 && item != null">
        <td v-text="item.name"></td>
        <td v-text="info.done"></td>
        <td v-text="info.total - info.done"></td>
        <td v-text="info.total"></td>
    </tr>
</template>

<script>
export default {
    props: {
        itemId: {
            required: true,
        },
        info: {
            required: true,
        },
    },
    data() {
        return {
            assigned: 0,
            classified: 0,
        };
    },
    computed: {
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
        progressPercent() {
            return (this.info.done / this.info.total * 100)
        },
    },
    watch: {
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.info-list-classifiers {
    margin: 0;
    border-top: 1px solid gray;
    position: relative;

    td {
        padding: calc($regular-padding/2) $regular-padding;
        white-space: nowrap;

        &:first-child {
            width: 100%;
        }

        &:last-child {
            text-align: right;
        }
    }

    &:after {
        content: '';
        height: 100%;
        width: var(--progress);
        left: 0;
        position: absolute;
        background: rgba($secondary-color, .5);
        z-index: 1;
    }

    >* {
        z-index: 10;
        position: relative;
    }

    &:hover {
        background: rgba($primary-color, 0.1);
        cursor: pointer;

    }

}
</style>
