<template>
    <tableCard :columns="columns" :rows="rows">
    </tableCard>
</template>

<script>
import TableCard from '@/components/TableCard.vue'

export default {
    props: {
        exam: {
            required: true,
        },
        sessions: {
            required: true,
        },
    },
    components: {
        TableCard,
    },
    data() {
        return {
        }
    },
    methods: {
    },
    computed: {
        columns() {
            return [
                { text: this.$tc('models.session') },
                { text: this.$tc('models.school') },
                { text: this.$tc('models.student', 0) },
                { text: this.$t('generic.executionAt') },
                { text: this.$t('generic.startAt') },
                { text: this.$t('generic.endAt') },
                { text: this.$t('generic.state') },
            ]
        },
        rows() {
            if (this.schools == null) {
                return []
            }
            return this.sessions.reduce((rows, session) => {
                let link = { name: 'ExamSession', params: { session_id: session.id } }
                rows.push([
                    { text: session.name, link },
                    { text: this.schools[session.school_id].name, link },
                    { text: `${session.students_count}`, link },
                    { text: session.execution_at.locale('pt-PT').calendar(), link },
                    { text: session.classification_from.locale('pt-PT').calendar(), link },
                    { text: session.classification_to.locale('pt-PT').calendar(), link },
                    { text: session.state_description, link },
                ])
                return rows
            }, [])
        },
        schoolIds() {
            return this.sessions.reduce((ids, session) => {
                if (!ids.includes(session.school_id)) {
                    ids.push(session.school_id)
                }
                return ids
            }, [])
        },
        schools() {
            return this.$store.getters['schools/ids'](this.schoolIds)
        },
    },
    watch: {
    },
    mounted() {
    },
    unmounted() {
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

span {
    &.activated {
        background-color: $white;
    }
}

.card {
    display: flex;
    flex-direction: column;
}

.number,
.effort {
    padding: 0 1em;
}

table {
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    flex-grow: 1;
    overflow: auto;
    position: relative;

    thead {
        position: sticky;
        top: 0;
        background-color: rgba($white, 1);
        z-index: 10;

        th {
            border-bottom: 1px solid rgba($black, .5);
        }
    }

    td,
    th {
        white-space: nowrap;
        width: 1%;

        &.classifier_id {
            line-height: 1;
        }

        &.number,
        &.classifier_id,
        &.effort {
            width: 0%;
        }

        a {
            will-change: background;
        }

        &.item {
            &.active {
                a {
                    background-color: rgba($alert-color, .75);
                }
            }
        }
    }

    td {
        text-align: center;
        padding: .2em;
        cursor: default;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: rgba($primary-color, .1);
            }

            &.supervisor {
                background-color: rgba($secondary-color, .85);

                .classifier_id {
                    font-weight: bolder;
                }

                &:nth-child(odd) {
                    background-color: rgba($secondary-color, .95);
                }
            }
        }
    }

    .left {
        text-align: left;
    }

    .right {
        text-align: center;
    }
}
</style>
