<template>
    <div class="exam-answers-content" v-if="itemClassification">
        <BubbleContent :title="theTitle" :buttons="button" :loadingInfo="loading">
            <div class="content-grid" :class="{ 'content-grid_popup': isVisible }">
                <div class="content-info">
                    <div class="answers-iconItem-content">
                        <a href="#" @click.prevent="back()" v-if="back != null">
                            <IconComponent icon="chevron-left" class="material-icons" />
                        </a>
                        <router-link :to="{ name: 'Exam', params: { id: id } }" v-else>
                            <IconComponent icon="chevron-left" class="material-icons" />
                        </router-link>
                        <h2 v-if="item">{{ vTextItem }}: {{ item.name }} - {{ currentPage }}/{{ classificationsCount }}
                        </h2>
                    </div>
                    <h2>{{ vTextAnswer }} #{{ itemClassification.id }}</h2>
                    <div class="buttons-content">
                        <button class="buttons-beforeNext" @click.prevent="page -= 1" :disabled="page == 1">
                            <span v-text="`${$t('action.before')}`"></span>
                        </button>
                        <button class="buttons-beforeNext" @click.prevent="page += 1"
                            :disabled="page == classificationsCount">
                            <span v-text="`${$t('action.next')}`"></span>
                        </button>
                    </div>
                    <div class="answers-content">
                        <div class="attached-customFile" v-if="itemClassification.custom_file">
                            <a target="_blank" :href="itemClassification.custom_file">
                                <IconComponent icon="clip" class="material-icons" />
                                <span v-text="`${$t('action.shaping.toClassifyAnswerSeeFileAttach')}`"></span>
                            </a>
                        </div>
                        <div v-if="item" ref="answer">
                            <div v-for="answer, index in answers" :key="index" class="answer-part">
                                <div class="answer" v-html="answer"></div>
                            </div>
                        </div>
                        <strong v-if="!hideWordCount" class="answer-number-words">{{ vTextWords }}:
                            {{ itemClassification.words_count }}</strong>
                    </div>
                    <div>
                        <h3 v-text="`${$tc('models.classification')}`"></h3>
                        <button v-if="!isVisible" class="answers-credits" @click="showPopup"
                            v-text="`${$t('action.shaping.seeCriteria')}`"></button>
                        <button v-if="isVisible" class="answers-credits" @click="closePopup"
                            v-text="`${$t('action.shaping.hideCriteria')}`"></button>
                        <SelectItemCodes :codes="itemCodes" :class="{ active: restrictionsCombined }"
                            :otherCodes="otherCodes" :canSelect="!disabled" :disabled="restrictionsBlocked"
                            :itemId="itemId" :selectedCodes="codesSelected" v-model="selectedCode" />
                        <div class="content-conditions" v-if="!disabled && !supervise">
                            <button class="buttons-conditions" :class="{ active: selectedState == 1 }"
                                v-if="state != 1 && showSaveForLater" @click.prevent="selectState(1)"
                                v-text="`${$t('action.shaping.seeLater')}`">
                            </button>
                            <button class="buttons-conditions buttons-conditions_right"
                                :class="{ active: selectedState == 2 }" @click.prevent="selectState(2)"
                                v-text="`${$t('action.shaping.sendToReview')}`" v-if="showSendToReview">
                            </button>
                        </div>
                        <div class="messages">
                            <MessageBox class="message" v-for="message in messages" :key="message.id"
                                :message="message" />
                        </div>
                        <div v-if="messageIsVisible || (supervise && state == 2)">
                            <InputContent :label="`${$t('action.shaping.writeMessage')}`" v-model="messageReview" />
                        </div>
                    </div>
                </div>
                <transition name="fade">
                    <ClassifierExamCriteriaPopup class="popup" v-if="isVisible" @close="closePopup" :id="id"
                        :itemId="itemId" />
                </transition>
            </div>
        </BubbleContent>
    </div>
</template>

<script>
import IconComponent from '@/components/IconComponent.vue'
import SelectItemCodes from '@/components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifierExamAnswersContent/SelectItemCodes.vue'
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ClassifierExamCriteriaPopup from '@/components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifierExamAnswersContent/ClassifierExamCriteriaPopup.vue'
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

import MessageBox from '@/components/messages/MessageBox'

import renderMathInElement from 'katex/dist/contrib/auto-render';
import 'katex/dist/katex.min.css';

export default {
    props: {
        back: { default: null },
        classifierId: { default: null },
        disabled: { default: null },
        id: { required: true },
        itemId: { required: true },
        state: { required: true },
        supervise: { default: false },
        title: { default: null },
    },
    components: {
        IconComponent,
        BubbleContent,
        SelectItemCodes,
        ClassifierExamCriteriaPopup,
        InputContent,
        MessageBox,
    },
    data() {
        return {
            defaultTitle: this.$t('action.classify'),
            vTextItem: `${this.$tc('models.item')}:`,
            vTextAnswer: `${this.$tc('models.answer_nr')}:`,
            vTextWords: `${this.$tc('generic.word')}:`,
            page: 0,
            selectedCode: [],
            selectedState: 3, // Done,
            isVisible: false,
            messageReview: '',
            selected: [],
            active: [],
            delimiters: [
                { left: '$$', right: '$$', display: true },
                { left: '$', right: '$', display: false },
                { left: '(formula: ', right: ')', display: false },
                { left: "\\(", right: "\\)", display: false },
                { left: "\\begin{equation}", right: "\\end{equation}", display: true },
                { left: "\\begin{align}", right: "\\end{align}", display: true },
                { left: "\\begin{alignat}", right: "\\end{alignat}", display: true },
                { left: "\\begin{gather}", right: "\\end{gather}", display: true },
                { left: "\\begin{CD}", right: "\\end{CD}", display: true },
                { left: "\\[", right: "\\]", display: true }
            ],
            loading: {
                done: true,
            },
        }
    },
    methods: {
        closePopup() {
            this.isVisible = false
        },
        selectState(state) {
            if (this.selectedState == state) {
                this.selectedState = 3
                return
            }
            this.selectedState = state
        },
        showPopup() {
            this.isVisible = true
        },
        submit() {
            if (this.canSubmit) {
                this.loading = { done: false }
                this.$store.dispatch('itemClassification/classify', {
                    classification: this.itemClassification,
                    code: this.selectedCode,
                    state: this.selectedState,
                    message: this.messageReview,
                })
            }
        },
        isUrl(str) {
            if ((!str.includes(".")) || /\s/.test(str)) {
                return false;
            }
            let formats = [
                ".tif",
                ".gif",
                ".jpeg",
                ".jpg",
                ".png",
                ".pdf",
            ];
            let includes = false;
            for (var format in formats) {
                if (str.includes(formats[format])) {
                    includes = true;
                    break;
                }
            }
            if (!includes) {
                return false;
            }

            let regex = new RegExp("((http|https)://)(www.)?"
                + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]"
                + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)")
            if (!regex.test(str)) {
                return false;
            } else {
                return true;
            }
        },
    },
    computed: {
        answers() {
            if (!this.item) return []
            if (!this.itemClassification) return []

            let answers = []
            if (!this.item.latex) {
                let answer = ''
                for (let ans of this.itemClassification.answers) {
                    if (this.isUrl(ans)) {
                        if (ans.endsWith("pdf")) {
                            answer = `<object data='${ans}' type='application/pdf' height='800px' style='width: 75vw;'></object>`
                        } else {
                            answer = "<p><img src='" + ans + "'></p>"
                        }
                    } else {
                        answer = ans.replace(/\\taoBr/g, '\\\\');
                    }
                    answers.push(answer);
                }
                return answers
            }

            if (this.itemClassification.answers) {
                let answer = ''
                for (let ans of this.itemClassification.answers) {
                    if (this.isUrl(ans)) {
                        answer = "<p><img src='" + ans + "'></p>"
                    } else {
                        answer = ans.replace(/\\taoBr/g, '\\\\');
                    }
                }
                answers.push(answer);
            }
            return answers
        },
        attribution() {
            if (this.item == null) {
                return null
            }
            if (this.classifier == null) {
                return null
            }
            return this.$store.getters['exams/distribution/attribution'](this.id, this.classifier.id, this.item.id)
        },
        classifier() {
            if (this.classifierId == null) {
                return this.$store.getters['exams/classifiers/userId'](this.user.id)
                // return this.$store.getters['exams/classifiers/examFirst'](this.id)
            }
            return this.$store.getters['exams/classifiers/getId'](this.classifierId)
        },
        button() {
            if (this.disabled) {
                return []
            }
            let text = this.$tc('action.classify')
            if (this.supervise && this.state == 2) {
                text = this.$tc('action.reply')
            } else if (this.selectedState == 1) {
                text = this.$tc('action.saveForLater')
            } else if (this.selectedState == 2) {
                text = this.$tc('action.sendToReview')
            }
            return [
                {
                    text,
                    type: 'submit',
                    click: this.submit,
                    disabled: !this.canSubmit
                },
            ]
        },
        currentPage() {
            if (this.classifier == null) {
                return 0
            }
            if (this.classifier.classified_items == null) {
                return 0
            }
            if (this.item == null) {
                return 0
            }
            if (this.classifier.classified_items[this.item.id] == null) {
                return 0
            }
            // if (this.attribution == null) {
            //     return 0
            // }
            let classifiedItem = this.classifier.classified_items[this.item.id]
            let classified = 0
            switch (this.state) {
                case 0:
                    classified = classifiedItem.done
                    classified += classifiedItem.review
                    classified += classifiedItem.saved
                    return this.page + classified
                default:
                    return this.page
            }
        },
        messages() {
            return this.itemClassification.messages
            // let messages = this.$store.state.itemClassification.messages
            // messages.push(this.messageReview)
            // let phrase = messages.slice(-1)[0]
            // return phrase
        },
        showSaveForLater() {
            let setting = this.$store.getters['settings/name']('saveForLater')
            if (setting != null) {
                return setting.value
            }
            return true
        },
        showSendToReview() {
            let setting = this.$store.getters['settings/name']('sendToReview')
            if (setting != null) {
                return setting.value
            }
            return true
        },
        hideWordCount() {
            return this.item && this.item.latex
        },
        nrCodeParams() {
            if (this.itemCodes) {
                let names = this.itemCodes.map(({ name }) => name)
                return names.filter((name, index) => names.indexOf(name) === index).length
            }
            return -1
        },
        canSubmit() {
            if (this.messageIsVisible && this.messageReview.length < 20) {
                return false
            }
            return this.selectedCode.length == this.nrCodeParams
        },
        codesSelected() {
            return this.itemClassification.item_code_ids
        },
        classificationsCount() {
            if (this.supervise && this.state == 2) {
                return this.getItemClassifications.length
            }
            if (this.attribution && this.state == 0) {
                return this.attribution.classifications_number
            }
            if (this.classifier == null) {
                return 0
            }
            if (this.classifier.classified_items == null) {
                return 0
            }
            if (this.classifier.classified_items[this.itemId] == null) {
                return 0
            }
            switch (this.state) {
                case 0:
                    return this.classifier.classified_items[this.itemId].saved
                case 1:
                    return this.classifier.classified_items[this.itemId].saved
                case 2:
                    return this.classifier.classified_items[this.itemId].review
                case 3:
                    return this.classifier.classified_items[this.itemId].done
                case 4:
                    return this.classifier.classified_items[this.itemId].resolve
            }
            return 0
        },
        itemCodes() {
            return this.$store.state.itemCodes.all
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
        itemClassification() {
            if (this.supervise && this.state == 2) {
                return this.getItemClassifications[this.page]
            }
            if (this.page > 0) {
                return this.getItemClassifications[this.page - 1]
            }
            return null
        },
        getItemClassifications() {
            if (this.supervise && this.state == 2) {
                return this.$store.getters['itemClassification/getItemStatus'](this.itemId, this.state)
            }
            return this.$store.getters['itemClassification/getItem'](this.itemId, this.state)
        },
        messageIsVisible() {
            return this.selectedState == 2 && !this.supervise
        },
        otherCodes() {
            if (this.itemClassification == null) {
                return []
            }
            if (this.itemClassification.otherCodes == null) {
                return []
            }
            return this.itemClassification.otherCodes
        },
        selectedParams() {
            let params = [];
            for (var code in this.selected) {
                params.push(this.selected[code].name);
            }
            return params;
        },
        restricted() {
            return this.itemCodes.filter((code) => {
                return !(this.selectedParams.includes(code.name) || this.active.includes(code));
            })
        },
        restrictions() {
            return this.$store.getters['exams/items/restrictions'](this.itemId)
        },
        restrictionsBlocked() {
            return this.restrictions.map((restriction) => restriction.rules.blocked)
        },
        restrictionsCombined() {
            return this.restrictions.map((restriction) => restriction.rules.combination)
        },
        theTitle() {
            if (this.title == null) {
                return this.defaultTitle
            }
            return this.title
        },
        user() {
            return this.$store.state.users.currentUser
        },
    },
    watch: {
        classifier() {
            console.log("classifier updated")
            this.loading = { done: true, timeout: 100 }
        },
        page() {
            let params = {
                exam: this.id,
                examItem: this.itemId,
                page: this.page + 1,
                state: this.state,
            }
            if (this.classifierId) {
                params.classifier = this.classifierId
            }
            this.$store.dispatch('itemClassification/getOne', params)
        },
        itemClassification() {
            this.selectedCode = []
            this.selectedState = 3
            let params = {
                exam: this.id,
                examItem: this.itemId,
                page: this.page + 1,
                state: this.state,
            }
            if (this.classifierId) {
                params.classifier = this.classifierId
            }

            this.$store.dispatch('itemClassification/getOne', params)
            this.loading = { done: true, timeout: 100 }
            if (this.itemClassification != null && this.itemClassification.state == 4) {
                this.$store.dispatch('itemClassification/getConflicts', params)
            }
        },
        getItemClassifications() {
            if (this.getItemClassifications.length == 0) {
                this.$router.replace({ name: "Exam" })
            }
        },
        answers: {
            immediate: true,
            handler() {
                if (this.answers.length < 1) {
                    return
                }
                this.$nextTick().then(() => {
                    renderMathInElement(this.$refs.answer, {
                        delimiters: this.delimiters,
                        throwOnError: false,
                    })
                })
            }
        },
        user: {
            immediate: true,
            handler() {
                if (!this.user) {
                    return
                }
                this.$store.dispatch('exams/attributions/listen', this.user.id)
            },
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getRestrictions', this.itemId)
        this.$store.dispatch('itemCodes/getAll', { item: this.itemId })
        if (this.supervise && this.state == 2) {
            return
        }
        let params = {
            exam: this.id,
            examItem: this.itemId,
            page: this.page + 1,
            state: this.state,
        }
        if (this.classifierId) {
            params.classifier = this.classifierId
        }
        this.$store.dispatch('itemClassification/getOne', params)
        if (this.page == 0) {
            this.page += 1
        }
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';


.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.item-title {
    margin-top: 0;
}


.exam-answers-content {

    .content-grid {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 0fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'popup info'
        ;
        gap: 0px;
        transition: .3s ease-out;
        transition-property: gap, grid-template-columns;

        &_popup {
            gap: 20px;
            grid-template-columns: 1fr 1fr;
        }

        .popup {
            grid-area: popup;
        }

        .content-info {
            grid-area: info;
            display: flex;
            flex-direction: column;
        }

        .answers-iconItem-content {
            display: flex;
            align-items: baseline;

        }

        .material-icons {
            width: 15px;
            fill: $primary-color;
            margin-right: 10px;
        }

        .buttons-content {
            display: flex;
            justify-content: space-between;
            padding: 0 20%;
            border-top: 1px solid $primary-color;
            border-bottom: 1px solid $primary-color;

            .buttons-beforeNext {
                background: rgba($black, 0);
                border: none;
                display: inline-block;
                text-transform: uppercase;
                padding: 5px 0;
                cursor: pointer;
                transition-duration: 0.7s;
                position: relative;
                width: 100%;

                &:before {
                    content: '';
                    background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: opacity .2s ease;
                }

                &:disabled {
                    opacity: .15;
                }

                &:not(:disabled):hover {
                    transition: background-color .7s;

                    &::before {
                        opacity: .25;
                    }
                }
            }
        }

        .answers-content {
            border-bottom: 1px solid rgba($black, .5);
        }

        .attached-customFile {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            color: $primary-color;
        }

        .answer {
            margin: auto;
            width: fit-content;
            max-width: 100%;

            &-part {
                border-bottom: 1px solid rgba($black, 1);
                padding: 30px;
                width: 100%;
            }
        }

        .answers-credits {
            background-color: $primary-color;
            cursor: pointer;
            color: $white;
            padding: 0.5em;
            border: 1px solid $primary-color;
        }


        .buttons-item-codes {
            background-color: $white;
            cursor: pointer;
            color: $black;
            padding: 0.5em;
            border: 1px solid $primary-color;
            margin-top: 20px;

            p {
                margin: 0;
                font-weight: 700;

            }
        }

        .content-conditions {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .buttons-conditions {
                border: 1px solid $primary-color;
                background: $white;
                color: $text-color;
                font-weight: 700;
                cursor: pointer;
                padding: 10px;

                &_right {
                    margin: 0 0 0 auto;
                }

                &.active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }
}
</style>
