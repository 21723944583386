<template>
    <div class="pop-container">
        <div class="pop-inner">
            <h2 v-text="`${$t('action.shaping.areYouSureYouWantToDelete')}`"></h2>
            <div class="pop-answers">
                <button class="pop-btn-execute" @click="execute" v-text="`${$t('generic.confirmation')}`"></button>
                <button class="pop-btn-cancel" @click="cancel" v-text="`${$t('action.cancel')}`"></button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    methods: {
        cancel() {
            this.$emit("cancel");
        },
        execute() {
            this.$emit("execute");
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../assets/utilities/_variables.scss';

$contColor: $white;
$textColor: $black;
$brdColor: $primary-color;
$btnColor: $primary-color;
$textColor-btn: $white;

@mixin vertFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pop {
    &-container {
        @include vertFlex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($black, .5);
        z-index: 2;
    }

    &-inner {
        background: $white;
        padding: 40px;
        width: auto;
        background-color: $contColor;
        border-radius: 20px;
        border: 1px solid $brdColor;
    }

    &-answers {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &-btn-execute {
        background-color: $btnColor;
        border: none;
        color: $textColor-btn;
        padding: 10px 10px;
        font-size: 16px;
        border-radius: 10px;
        cursor: pointer;
    }

    &-btn-cancel {
        background-color: rgba($black, 0.3);
        color: $textColor-btn;
        border: none;
        font-size: 16px;
        padding: 10px 10px;
        border-radius: 10px;
        cursor: pointer;
    }
}
</style>
