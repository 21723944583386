<template>
    <div class="content-users-edit">
        <BubbleContent :title="`${$t('generic.accessUserData')}`" :buttons=buttons @submit="changeAccess"
            :loadingInfo="changeAccessLoadingInfo">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputContent :label="`${$t('generic.idNumber')}`" v-model="nr_classificador" />
                <InputContent :label="`${$tc('models.school')}`" />
                <InputContent :label="`${$t('generic.email')}`" type="email" v-model="email" />
                <strong v-text="`${$t('generic.passwordInfo')}`"></strong>
                <InputContent :label="`${$t('generic.password')}`" type="password" v-model="password" />
                <InputContent :label="`${$t('action.shaping.repeatPass')}`" type="password" v-model="confirmPassword" />
            </template>
        </BubbleContent>
    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

export default {
    name: 'ProfileData',
    components: {
        BubbleContent,
        InputContent,
    },
    props: {
        id: {
            default: null
        }
    },
    data() {
        return {
            name: "",
            nr_classificador: "",
            school_id: "",
            email: "",
            password: "",
            confirmPassword: "",
            buttons: [
                { text: `${this.$t('action.change')}`, type: 'submit' },
            ],
        };
    },
    methods: {
        changeAccess() {
            if (this.password != this.confirmPassword) {
                return
            }
            this.$store.dispatch('users/edit', {
                id: this.currentUser.id,
                uid: 'changeAccess',
                user: {
                    ...this.currentUser,
                    name: this.name,
                    nr_classificador: this.nr_classificador,
                    school_id: this.school_id,
                    password: this.password,
                    email: this.email,
                },
            })
        },
    },
    computed: {
        changeAccessLoadingInfo() {
            return this.$store.getters['loadingMessages/uid']('changeAccess')
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
    },
    watch: {
        changeAccessLoadingInfo() {
            if (this.changeAccessLoadingInfo == null) {
                return
            }
            if (this.changeAccessLoadingInfo == true) {
                this.$store.dispatch('users/login', {
                    email: this.currentUser.email,
                    password: this.password,
                })
            }
        },
        currentUser: {
            immediate: true,
            handler() {
                if (this.currentUser != null) {
                    this.name = this.currentUser.name
                    this.email = this.currentUser.email
                    this.school_id = this.currentUser.school_id
                    this.nr_classificador = this.currentUser.nr_classificador
                }
            },
        }
    },
    mounted() { },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.content-users-edit {
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }   }
    }
}
</style>
