<template>
    <ContentCard :buttons=buttons :title="`${$t('action.createGroup')}`"
        @submit.prevent="$store.dispatch('exams/groups/create', { newGroup: newGroupData, exam: id })">
        <template #form>
            <InputContent :label="`${$t('generic.groupName')}`" v-model="name" />
            <div>
                <p v-text="`${$tc('generic.supervisorSelected', 2)}`"></p>
                <div>
                    <div v-for="user in usersList" :key="user.id">
                        <p>
                            <strong>{{ user.email || user.nr_classificador || user.name }}</strong>
                            -
                            <span @click.prevent="removeSupervisor(user)" v-text="`${$t('action.remove')}`"></span>
                        </p>
                    </div>
                </div>
                <p v-text="`${$t('action.shaping.lookForSupervisor')}`"></p>
                <input type="text" v-model="searchSupervisor">
                <div class="usersList">
                    <p v-for="supervisor in groupSupervisor" :key="supervisor.id"
                        @click.prevent="addSupervisor(supervisor)">
                        <span v-if="supervisor.name">
                            <strong v-text="`${$t('generic.name')}:`"></strong> {{ supervisor.name }} |
                        </span>
                        <span v-if="supervisor.email">
                            <strong v-text="`${$t('generic.email')}:`"></strong> {{ supervisor.email }} |
                        </span>
                        <span v-if="supervisor.nr_classificador">
                            <strong v-text="`${$t('generic.classifierNumber')}:`"></strong> {{
                                supervisor.nr_classificador }} |
                        </span>
                    </p>
                </div>
                <br>
            </div>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

export default {
    components: {
        ContentCard,
        InputContent,
    },
    props: {
        id: {
            required: true,
        },
    },
    data() {
        return {
            buttons: [
                { text: `${this.$t('action.add')}`, type: 'submit' },
            ],
            searchSupervisor: '',
            usersList: [],
        };
    },
    computed: {
        users() {
            return this.$store.state.users.all
        },
        remainingUsers() {
            return this.users.filter(({ id }) => !this.selectedIds.includes(id))
        },
        selectedIds() {
            return this.usersList.map(({ id }) => id)
        },
        groupSupervisor() {
            if (this.searchSupervisor) {
                return this.remainingUsers.filter((supervisor) => {
                    let search = this.searchSupervisor.toString().toLocaleLowerCase()
                    let includesName = false
                    if (supervisor.name) includesName = supervisor.name.toLowerCase().includes(search)
                    let includesNr = false
                    if (supervisor.nr_classificador) includesNr = supervisor.nr_classificador.toLowerCase().includes(search)
                    return includesName || includesNr
                }).slice(0, 5)
            } else {
                return []
            }
        },
        newGroupData() {
            return {
                name: this.name,
                supervisors: this.usersList.map(({ id }) => id),
            }
        },
    },
    methods: {
        addSupervisor(user) {
            this.usersList.push(user)
        },
        removeSupervisor(user) {
            this.usersList = this.usersList.filter(users => users !== user)
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';
</style>
