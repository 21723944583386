<template>
    <div class="exams">
        <TopContainerNav :title="title" />
        <TopContainerNavBody :actions="actions" />
        <router-view class="view"></router-view>
    </div>
</template>

<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';
export default {
    props: {
        id: {
            default: null,
        },
        state: {
            default: null
        },
    },
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    data() {
        return {
        }
    },
    computed: {
        actions() {
            if (this.isAdmin) {
                return [
                    ...this.regularActions,
                    ...this.classifierActions,
                    ...this.adminActions,
                ]
            }
            return [
                ...this.regularActions,
                ...this.classifierActions,
            ]
        },
        adminActions() {
            if (!this.isAdmin) {
                return []
            }
            if (this.exam == null) {
                return [
                    { text: this.$t('action.create'), route: '/exams/create', },
                ]
            }
            let actions = [
                {
                    text: this.$t('generic.information'),
                    route: { name: "Exam", params: { id: this.id } }
                },
            ]
            actions.push({
                text: this.$t('generic.progress'),
                route: { name: "ExamProgress", params: { id: this.id } }
            })
            if (this.exam.kind == 0) {
                actions.push({
                    text: this.$tc('models.classifier', 2),
                    route: { name: "ExamClassifiers", params: { id: this.id } }
                })
            } else {
                actions.push({
                    text: this.$tc('models.session', 2),
                    route: { name: "ExamSessions", params: { id: this.id } }
                })
            }
            actions.push({
                text: this.$tc('models.item', 2),
                route: { name: "ExamItems", params: { id: this.id } }
            })
            actions.push({
                text: this.$tc('generic.team', 2),
                route: { name: "ExamTeams", params: { id: this.id } }
            })
            actions.push({
                text: this.$t('action.edit'),
                route: { name: "ExamEdit", params: { id: this.id } }
            })
            actions.push({
                text: this.$tc('models.answer', 2),
                route: { name: "ExamAnswers", params: { id: this.id } }
            })
            return actions
            // {
            //     text: this.$t('action.distribute'),
            //     route: { name: "ExamDistribute", params: { id: this.id } }
            // },
            // { text: this.$tc('generic.group'), route: { name: "ExamGroups", params: { id: this.id } } },
            // { text: this.$tc('models.answer'), route: { name: "ExamAnswers", params: { id: this.id } } },
            // { text: this.$t('generic.statistic'), route: { name: "ExamStatistics", params: { id: this.id } } },
        },
        attributions() {
            if (this.classifier == null) {
                return []
            }
            // return this.$store.getters['exams/attributions/classifier'](this.classifier.id)
            return this.$store.getters['exams/distribution/examClassifier'](this.id, this.classifier.id)
        },
        classifierActions() {
            if (this.isAdmin || this.id == null) {
                return []
            }
            if (this.exam == null) {
                return []
            }
            let isPratical = this.exam.kind == 1
            let actions = []
            if (!isPratical) {
                if (Object.keys(this.attributions).length && this.countClassifyTotal > 0) {
                    actions.push({
                        text: `${this.$t('action.classify')} (${this.countClassify})`,
                        route: { name: "Exam", params: { id: this.id } }
                    })
                }
            } else {
                actions.push({
                    text: `${this.$t('action.classify')}`,
                    route: { name: "ExamSessions", params: { id: this.id } }
                })
            }
            if (Object.keys(this.attributions).length && this.countClassifyTotal > 0) {
                if (this.showSaveForLater && !isPratical) {
                    actions.push({
                        text: `${this.$t('generic.saved')} (${this.countSaved})`,
                        route: { name: "ClassifiersExamSaved", params: { id: this.id } }
                    })
                }
                if (this.showSendToReview && !isPratical && this.countClassifyTotal > 0) {
                    actions.push({
                        text: `${this.$t('action.shaping.sentToReview')} (${this.countToReview})`,
                        route: { name: "ClassifiersExamToBeReviewd", params: { id: this.id } }
                    })
                }
                if (!isPratical) {
                    actions.push({
                        text: `${this.$tc('generic.classified_f', 2)} (${this.countReview})`,
                        route: { name: "ClassifiersExamToReview", params: { id: this.id } }
                    })
                }
            }
            if (this.classifier == null) {
                return actions
            }
            if (this.classifier.supervisor) {
                actions.push({
                    text: `${this.$t('action.shaping.toReview')} (${this.countToSupervise})`,
                    route: { name: "ExamSupervise", params: { id: this.id } }
                })
                actions.push({
                    text: `${this.$tc('generic.conflict', 0)} (${this.countToResolve})`,
                    route: { name: "ExamResolve", params: { id: this.id } }
                })
            }
            return actions
        },
        regularActions() {
            if (this.id != null || this.$route.name == 'ExamCreate') {
                return [
                    {
                        text: this.$tc('models.exam', 2),
                        route: { name: 'ExamsIndex' }
                    },
                ]
            }
            return []
        },
        showSaveForLater() {
            let setting = this.$store.getters['settings/name']('saveForLater')
            if (setting != null) {
                return setting.value
            }
            return false
        },
        showSendToReview() {
            let setting = this.$store.getters['settings/name']('sendToReview')
            if (setting != null) {
                return setting.value
            }
            return false
        },
        title() {
            let title = this.$tc('models.exam', 2)
            if (this.exam != null) {
                title += ` > ${this.exam.name}`
            }
            if (this.$route.name == 'ExamCreate') {
                title += ` > ${this.$t('generic.new')}`
            }
            return title
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
        items() {
            return this.$store.state.exams.items.all
        },
        classifier() {
            if (this.currentUser == null) {
                return null
            }
            return this.$store.getters['exams/classifiers/userId'](this.currentUser.id)
        },
        // classifier() {
        //     if (this.currentUser == null) {
        //         return null
        //     }
        //     return this.$store.getters['exams/classifiers/userId'](this.currentUser.id)
        // },
        // -------------------- GET A WAY TO OPTIMIZE THIS PART OF CODE
        classify() {
            const item = this.items.map(item => item.classifications_count[0])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        saved() {
            const item = this.items.map(item => item.classifications_count[1])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        markToReview() {
            const item = this.items.map(item => item.classifications_count[2])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        countClassify() {
            if (this.attributions == null) {
                return ".."
            }
            let classifications_number = 0
            for (let itemId in this.attributions) {
                let attr = this.attributions[itemId]
                classifications_number += attr.classifications_number
            }
            return classifications_number - this.countSaved - this.countToReview - this.countReview
        },
        countClassifyTotal() {
            if (this.attributions == null) {
                return ".."
            }
            let classifications_number = 0
            for (let itemId in this.attributions) {
                let attr = this.attributions[itemId]
                classifications_number += attr.classifications_number
            }
            return classifications_number
        },
        countReview() {
            if (this.classifier == null) {
                return ".."
            }
            if (this.classifier.classified_items == null) {
                return ".."
            }
            let count = 0
            for (let itemId in this.classifier.classified_items) {
                if (this.classifier.classified_items[itemId]['done'] != null) {
                    count += this.classifier.classified_items[itemId]['done']
                }
            }
            return count
        },
        countSaved() {
            if (this.classifier == null) {
                return ".."
            }
            if (this.classifier.classified_items == null) {
                return ".."
            }
            return Object.values(this.classifier.classified_items).reduce(
                (acc, curr) => acc + (curr.saved == null ? 0 : curr.saved),
                0,
            )
        },
        countToResolve() {
            if (this.classifier == null) {
                return ".."
            }
            if (this.classifier.classified_items == null) {
                return ".."
            }
            let count = 0
            count = Object.values(this.classifier.classified_items).reduce(
                (acc, curr) => acc + (curr.resolve == null ? 0 : curr.resolve),
                0,
            )
            return count
        },
        countToReview() {
            if (this.classifier == null) {
                return ".."
            }
            if (this.classifier.classified_items == null) {
                return ".."
            }
            let count = 0
            count = Object.values(this.classifier.classified_items).reduce(
                (acc, curr) => acc + (curr.review == null ? 0 : curr.review),
                0,
            )
            return count
        },
        countToSupervise() {
            if (this.classifier == null) {
                return ".."
            }
            if (this.items.length == 0) {
                return ".."
            }
            return this.$store.getters['itemClassification/getState'](this.items.map(i => i.id), 2).length
        },
    },
    watch: {
        classifier: {
            immediate: true,
            handler() {
                if (this.classifier == null) {
                    return
                }
                if (this.classifier.supervisor) {
                    let params = {
                        exam: this.id,
                        supervisor: this.classifier.id,
                    }
                    this.$store.dispatch('itemClassification/getToReview', params)
                }
                console.log(this.classifier)
                this.$store.dispatch('exams/classifiers/listen', this.classifier.id)
            },
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        this.$store.dispatch('exams/listenAll')
        this.$store.dispatch('exams/distribution/listenUpdates')
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.view {
    overflow: auto;
    height: 100%;
    padding: $regular-padding;
}
</style>
