<template>
    <div class="content-exams-edit">
        <CardTitle class="input" :title="$t('action.shaping.importClassifiers')" :buttons="classifiersImportButton"
            :loadingInfo="classifiersLoadingInfo" @submit.prevent="submitClassifiers">
            <InputFile :label="$t('generic.file')" v-model="classifierFiles"></InputFile>
        </CardTitle>

        <BubbleContent class="exams-edit-edit" :title="$t('action.edit')" :loadingInfo="loadingInfo" :buttons=button
            @submit="submit">
            <template #form>
                <InputContent :label="$t('generic.name')" v-model="name" />
                <InputSelectContent :label="$t('generic.table')" :options="exam_tables"
                    :choose="$t('action.shaping.selectType')" v-model="table" />
                <InputContent :label="$t('generic.studentColId')" v-model="studentId" />
                <InputSelectContent :label="$t('generic.type')" :options="options"
                    :choose="$t('action.shaping.selectType')" v-model="kind" />
                <InputContent :label="$t('generic.startAt')" type="datetime-local" v-model="starts_at" :choose="date" />
                <InputContent :label="$t('generic.endAt')" type="datetime-local" v-model="ends_at" :choose="date" />
            </template>
        </BubbleContent>

        <BubbleContent class="exams-edit-team" :title="`${$tc('generic.teamIave')}`">
            <template #form>
                <InputContent :label="$tc('models.user', 1)" v-model="utilizador" />
                <div>
                    <h3>{{ $tc('models.user', 2) }}</h3>
                    <div>
                        <p>{{ $t('generic.name') }}</p>
                        <p>{{ $t('models.classification', 2) }}</p>
                    </div>
                </div>
            </template>
        </BubbleContent>

        <ExamImportAnswers :id="id" class="exams-edit-import" />
        <ExamImport :id="id" class="exams-edit-upload" />

        <BubbleContent class="exams-edit-export" type="file" :title="$t('action.export')" :buttons="exportButtons"
            :loadingInfo="exportLoadingInfo2" @submit="startExport">
            <template #form>
                <div class="files">
                    <ul>
                        <li v-for="file in examExportFiles" :key="file.id">
                            <a v-text="file.filename" target="_blank" href="#" @click.prevent="download(file)">
                            </a>
                            <span v-if="examExportFilesDownload.includes(file.id)"> - {{ $t('action.downloading')
                                }}</span>
                        </li>
                    </ul>
                </div>
            </template>
        </BubbleContent>
    </div>
</template>

<script>
import CardTitle from '@/components/CardTitle.vue';
import InputFile from '@/components/SCOI/Inputs/InputFile.vue';

import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';
import ExamImport from '@/components/SCOI/TabExams/ExamImport.vue';
import ExamImportAnswers from '@/components/SCOI/TabExams/ExamImportAnswers.vue';
import moment from 'moment'
import uuid4 from 'uuid4';
import axios from "@/axiosInstance"
import api from "@/api"

export default {
    components: {
        BubbleContent,
        CardTitle,
        InputFile,
        InputContent,
        InputSelectContent,
        ExamImport,
        ExamImportAnswers,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            exam_tables: [],
            name: "",
            table: "",
            studentId: "",
            starts_at: '',
            kind: '',
            ends_at: '',
            utilizador: '',
            uid: null,
            options: [
                { text: this.$t('generic.regular'), value: '0' },
                { text: this.$t('generic.practical_m'), value: '1' },
            ],
            date: new Date().toLocaleString(),
            exportLoadingInfo2: null,
            importFile: null,
            importFileAttachments: null,
            uploadFiles: null,
            uploading: [],
            uploaded: false,
            exportButtons: [
                { text: this.$t('action.create'), type: 'submit' },
                { text: this.$t('action.update'), type: 'submit', click: this.refreshExportFiles },
            ],
            classifiersImportButton: [
                { text: this.$t('action.import'), type: 'submit' },
            ],
            classifierFiles: [],
        };
    },
    methods: {
        download(file) {
            this.$store.dispatch('exams/download', { id: this.id, file })
        },
        refreshExportFiles() {
            this.$store.dispatch('exams/getExportFiles', this.id)
        },
        startExport() {
            this.$store.dispatch('exams/export', this.id)
            this.exportLoadingInfo2 = { done: true, timeout: 500 }
        },
        importFiles() {
            this.$store.dispatch('exams/import', {
                id: this.id,
                file: this.importFile ? this.importFile[0] : null,
                attachment: this.importFileAttachments ? this.importFileAttachments[0] : null,
            })
        },
        async upload() {
            this.uploaded = false
            this.uploading = []
            for (let file of this.uploadFiles) {
                this.uploading.push({
                    file,
                    status: this.$t('action.shaping.waiting'),
                    complete: false,
                    error: false,
                })
            }
            for (let file of this.uploading) {
                let formData = new FormData()
                formData.append("upload", file.file)
                try {
                    file.status = `${this.$t('action.shaping.uploading')}...`
                    let res = await axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch (e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
            }
        },
        submit() {
            this.$store.dispatch('exams/edit', { id: this.id, uid: this.uid, exam: this.ExamData })
        },
        submitClassifiers() {
            this.$store.dispatch("exams/classifiers/import", { file: this.classifierFiles[0], examId: this.id })
        },
    },
    computed: {
        button() {
            return [
                { text: this.$t('action.edit'), type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.name &&
                this.table &&
                this.studentId
        },
        exportLoadingInfo() {
            return this.$store.state.exams.exportLoadingInfo
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        classifiersLoadingInfo() {
            return {
                done: !this.$store.getters['exams/classifiers/importing'](this.id)
            }
        },
        uploadingText() {
            if (this.uploaded) {
                return this.$t('action.shaping.uploaded')
            }
            return this.$t('action.shaping.uploading')
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        startsAtDate() {
            return moment(this.starts_at).format("YYYY-MM-DD HH:mm:ss")
        },
        endsAtDate() {
            return moment(this.ends_at).format("YYYY-MM-DD HH:mm:ss")
        },
        ExamData() {
            return {
                name: this.name,
                table: this.table,
                student_col: this.studentId,
                kind: this.kind,
                starts_at: this.startsAtDate,
                ends_at: this.endsAtDate,
            }
        },
        examExportFiles() {
            return this.$store.getters['exams/exportFiles'](this.id)
        },
        examExportFilesDownload() {
            return this.$store.state.exams.examExportFilesDownload
        },
    },
    watch: {
        exam: {
            immediate: true,
            handler() {
                if (this.exam != null) {
                    this.name = this.exam.name
                    this.table = this.exam.table
                    this.studentId = this.exam.student_col
                    this.kind = this.exam.kind
                    this.starts_at = this.exam.starts_at
                    this.ends_at = this.exam.ends_at
                }
            },
        }
    },
    mounted() {
        this.uid = uuid4();
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }
        this.refreshExportFiles();
        api.exams.tables().then((res) => {
            for (let table of res.data.data) {
                this.exam_tables.push({
                    text: table,
                    value: table,
                })
            }
        })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.content-exams-edit {
    color: $black;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-areas:
        "exams-edit-edit exams-edit-team"
        "exams-edit-edit upload"
        "exams-edit-import exams-edit-import"
        "exams-edit-upload exams-edit-upload"
        "exams-edit-export exams-edit-export";

    .input {
        grid-area: upload;
    }

    .exams-edit-upload {
        grid-area: exams-edit-upload;
        height: max-content;
    }

    .exams-edit-edit {
        grid-area: exams-edit-edit;
        height: max-content;
    }

    .exams-edit-team {
        grid-area: exams-edit-team;
        height: max-content;
    }

    .exams-edit-import {
        grid-area: exams-edit-import;
        height: max-content;
    }

    .exams-edit-export {
        grid-area: exams-edit-export;
        height: max-content;
    }
}
</style>
