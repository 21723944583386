import { createStore } from "vuex";
import documents from "./documents.js";
import users from "./users.js";
import schools from "./schoolsModule.js";
import settings from "./settings.js";
import exams from "./exams.js";
import messages from "./messages.js";
import students from "./students.js";
import itemCodes from "./exams-itemCodesModule.js";
import itemClassification from "./exams-itemsClassification.js";
import loadingMessages from "./loadingMessages.js";
import nomenclature from "./nomenclature.js";

import axios from '../axiosInstance';

const store = createStore({
    state: {
        echo: null,
        currentUser: null,
        currentUserError: null,
        authorized: false,
        afterLoginRoute: "/",
        online: [],
        errorMsg: '',
    },

    getters: {
        token(state) {
            if (state.users.currentUser == null) {
                return null;
            }
            return state.users.currentUser.api_token;
        },
    },

    mutations: {
        clear(state) {
            state.afterLoginRoute = "/";
        },
        errorMsg(state, error) {
            state.errorMsg = error;
        },
        setAfterLoginRoute(state, route) {
            state.afterLoginRoute = route;
        },
    },

    actions: {
        async getUser({ dispatch, commit }) {
            try {
                await axios.get(`${process.env.VUE_APP_API_HOST}/user`);
                let res = await axios.get(`${process.env.VUE_APP_API_HOST}/user`);
                commit("users/setCurrentUser", res.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
                dispatch("logout");
            }
        },

        getUserFromLocalStorage({ commit }) {
            const user = JSON.parse(localStorage.getItem("user"));
            commit("users/setCurrentUser", user);
        },

        async loginWithToken({ commit }, token) {
            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/login/token`,
                    { token }
                );
                commit("users/setCurrentUser", res.data.data);
                console.log(res.data);
            } catch (error) {
                console.log(error);
            }
        },
        async logout({ commit }) {
            commit("users/setCurrentUser", null);
            commit("clear");
            commit("classifiers/clear");
            commit("exams/clear");
            commit("exams/distribution/clear");
            commit("exams/items/clear");
            commit("exams/groups/clear");
            commit("itemClassification/clear");
            commit("itemCodes/clear");
            commit("users/clear");
            localStorage.removeItem("user");
        },
    },

    modules: {
        documents,
        exams,
        itemClassification,
        itemCodes,
        messages,
        users,
        schools,
        settings,
        students,
        history,
        loadingMessages,
        nomenclature,
    },
});

export default store;
