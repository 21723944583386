<template>
    <div v-if="progress != 0 && progress != 1" :style="{'--progress': progress}">
    </div>
</template>

<script>
export default {
    props: {
        exam: {
            default: true,
        },
    },
    data() {
        return {
        }
    },
    computed: {
        storeProgress() {
            return this.$store.getters['exams/distribution/progress'](this.exam.id)
        },
        progress() {
            if (this.storeProgress == null) {
                return 0
            }
            return this.storeProgress
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/utilities/variables";

div {
    width: calc(100% * var(--progress));
    height: 3px;
    background-color: $primary-color;
    transition: 1s linear width;
    flex-shrink: 0;
}

</style>
