<template>
    <div class="distribution-content">
        <ExamStatus :exam="exam">
        </ExamStatus>
        <DistributeTable :id="id" class="table" />
    </div>
</template>

<script>
import DistributeTable from '@/components/exams/DistributeTable';
import ExamStatus from '@/components/exams/ExamStatus'

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        DistributeTable,
        ExamStatus,
    },
    data() {
        return {
            attributions: {},
        };
    },
    methods: {
        attributeItems(value, groupId) {
            this.attributions[groupId] = value
        },
    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return attributions
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
        file() {
            return this.files[0]
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getAll', this.id)
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.distribution-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-height: 100%;
}

.input {
    flex-shrink: 0;
}

.table {
    flex-grow: 1;
}
</style>
