import axios from "@/axiosInstance"

const items = {
    namespaced: true,
    state: () => ({
        all: [],
        item: null,
        fetchedItems: {},
        restrictions: [],
    }),
    getters: {
        fetched: (state) => (id) => {
            if (state.fetchedItems[id] == null) {
                return false
            }
            return state.fetchedItems[id]
        },
        getId: (state) => (id) => {
            return state.all.find((item) => item.id === parseInt(id));
        },
        byExamId: (state) => (examId) => {
            examId = parseInt(examId)
            const items = state.all.filter((item) => item.exam_id === examId)
            return items
        },
        restrictions: (state) => (itemId) => {
            return state.restrictions.filter(
                ({ exam_item_id }) => exam_item_id == itemId
            )
        },
    },
    mutations: {
        add(state, newItem) {
            state.all.push(newItem);
        },
        all(state, { items, id }) {
            let fetchedItems = {}
            fetchedItems[id] = true
            state.fetchedItems = fetchedItems
            state.all = items
        },
        clear(state) {
            state.all = [];
            state.item = null;
        },
        delete(state, item) {
            const index = state.all.findIndex(({ id }) => item.id === id);
            state.all.splice(index, 1);
        },
        item(state, item) {
            const index = state.all.findIndex(({ id }) => item.id === id);
            if (index < 0) {
                state.all.push(item);
            } else {
                state.all.splice(index, 1, item);
            }
        },
        itemRestrictions(state, restrictions) {
            for (let restriction of restrictions) {
                const index = state.restrictions.findIndex(
                    ({ id }) => restriction.id === id
                );
                if (index < 0) {
                    state.restrictions.push(restriction);
                } else {
                    state.restrictions.splice(index, 1, restriction);
                }
            }
        },
        itemRestriction(state, restriction) {
            const index = state.restrictions.findIndex(
                ({ id }) => restriction.id === id
            );
            if (index < 0) {
                state.restrictions.push(restriction);
            } else {
                state.restrictions.splice(index, 1, restriction);
            }
        },
        removeItemRestriction(state, restriction) {
            const index = state.restrictions.findIndex(
                ({ id }) => restriction.id === id
            );
            if (index > -1) {
                state.restrictions.splice(index, 1);
            }
        },
    },
    actions: {
        async create({ commit }, { data, examId, uid }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/items`,
                    data
                );
                commit("add", results.data.data);
                let successObj = {
                    message: results.data.message,
                    error: false,
                    done: true,
                    uid,
                };
                commit("loadingMessages/messages", successObj, { root: true });
            } catch (error) {
                console.log(error);
                let errorObj = error.response.data;
                let errorMessage = errorObj.message;
                errorObj = { message: errorMessage, error: true, done: true, uid };
                commit("loadingMessages/messages", errorObj, { root: true });
            }
        },
        async edit({ commit }, { exam, itemId, item, uid }) {
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${itemId}`,
                    item
                );
                commit("item", results.data.data);
                let successObj = {
                    message: results.data.message,
                    error: false,
                    done: true,
                    uid,
                };
                commit("loadingMessages/messages", successObj, { root: true });
            } catch (error) {
                console.log(error);
                let errorObj = error.response.data;
                let errorMessage = errorObj.message;
                errorObj = { message: errorMessage, error: true, done: true, uid };
                commit("loadingMessages/messages", errorObj, { root: true });
            }
        },
        async delete({ commit }, { exam_id, id }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam_id}/items/${id}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getAll({ commit }, exam) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/items`
                );
                commit("all", { items: results.data.data, id: exam });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getId({ commit }, { exam, id }) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${id}`
                );
                commit("item", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getRestrictions({ commit }, itemId) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/items/${itemId}/restrictions`
                );
                commit("itemRestrictions", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async postRestriction(
            { commit },
            { itemId, blocked, combination }
        ) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/items/${itemId}/restrictions`,
                    { blocked, combination }
                );
                commit("itemRestriction", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async deleteRestriction({ commit }, restrictionId) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/items/restrictions/${restrictionId}`
                );
                commit("removeItemRestriction", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
    },
};

export default items;
