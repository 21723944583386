import axios from '@/axiosInstance'

const sessionClassifiers = {
    namespaced: true,

    state: {
        all: {},
    },

    getters: {
        id: (state) => (id) => {
            let students = state.all[id]
            if (students == null) {
                return []
            }
            return students
        },
    },

    mutations: {
        add(state, { student, id }) {
            let session = state.all[id]
            if (session == null) {
                session = []
            }
            const index = session.findIndex(({ id }) => student.id == id);
            if (index < 0) {
                session.push(student);
            } else {
                session.splice(index, 1, student);
            }
            state.all[id] = session
        },
        all(state, { students, id }) {
            state.all[id] = students;
        },
        clear(state) {
            state.all = {}
        },
        delete(state, { student, id }) {
            const index = state.all[id].findIndex(({ id }) => student.id === id);
            state.all[id].splice(index, 1);
        },
    },

    actions: {
        async create({ commit }, { session, student_id }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/students`,
                    { student_id }
                );
                commit("add", { student: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async delete({ commit }, { session, student }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/students/${student}`,
                );
                commit("delete", { student: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getAll({ commit }, session) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/students`,
                );
                commit("all", { students: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        // async update({ commit }, session) {
        //     console.log(session)
        //     try {
        //         const results = await axios.post(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${session.id}`,
        //             session
        //         );
        //         commit("add", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async get({ commit }, id) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${id}`
        //         );
        //         commit("add", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async getClassifiers({ commit }, id) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${id}/classifiers`
        //         );
        //         commit("addClassifiers", { id, data: results.data.data });
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async getId({ commit }, { exam, session }) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/${exam}/sessions/${session}`
        //         );
        //         commit("group", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },
    },
};

export default sessionClassifiers;
