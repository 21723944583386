<template>
    <div class="table">
        <table>
            <thead>
                <tr>
                    <th v-for="column, i of columns" v-text="column.text" :class="column.class" @click.prevent="sort(i)"
                        :key="column.text">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row, i in sortedRows" :key="i">
                    <td v-for="v, index in row" :key="v.id" :class="[v.class, columns[index].class]">
                        <a v-text="v.text" :href="v.url" v-if="v.url && v.text">
                        </a>
                        <router-link v-text="v.text" :to="v.link" v-else-if="v.link && v.text">
                        </router-link>
                        <router-link :to="v.link" v-else-if="v.link && v.icon">
                            <IconComponent :icon="v.icon" class="icon" />
                        </router-link>
                        <span v-else-if="v.action && v.text" v-text="v.text" @click.prevent="v.action"></span>
                        <IconComponent v-else-if="v.action && v.icon" :icon="v.icon" @click.prevent="v.action"
                            class="icon pointer" />
                        <IconComponent v-else-if="v.icon" :icon="v.icon" class="icon" />
                        <span v-else v-text="v.text"></span>
                    </td>
                </tr>
            </tbody>
        </table>
        <slot></slot>
    </div>
</template>

<script>
import IconComponent from './IconComponent.vue'

export default {
    props: ['columns', 'rows'],
    components: {
        IconComponent,
    },
    data() {
        return {
            sortBy: null,
            sortAsc: true,
        }
    },
    methods: {
        sort(index) {
            if (this.columns[index].sortable == false) {
                return
            }
            if (this.sortBy == index) {
                this.sortAsc = !this.sortAsc
                return
            }
            this.sortBy = index
            this.sortAsc = true
        },
    },
    computed: {
        sortedRows() {
            if (this.sortBy == null) {
                return this.rows
            }
            return [...this.rows].sort((a, b) => {
                let aTxt = this.sortAsc ? a[this.sortBy].text : b[this.sortBy].text
                let bTxt = this.sortAsc ? b[this.sortBy].text : a[this.sortBy].text
                if (aTxt == null && bTxt == null) {
                    return 0
                }
                if (aTxt == null) {
                    return -1
                }
                if (bTxt == null) {
                    return 1
                }
                if (typeof aTxt == 'string' && typeof bTxt == 'string') {
                    console.log('sorting strings')
                    return aTxt.localeCompare(bTxt)
                }
                return aTxt - bTxt
            })
        },
    },
    mounted() {
        console.log(this.rows)
        console.log(this.columns)
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.table {
    width: 100%;
    max-height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    position: relative;
    border-radius: 1.2rem;
    box-shadow: 0 3px 6px rgba($black, .16), 0 3px 6px rgba($black, .23);

    .pointer {
        cursor: pointer;
    }
}

table {
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    background: $white;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .9rem;
        background-color: rgba($secondary-color, 1);
        color: black;

        th {
            cursor: pointer;
            border-bottom: 1px solid rgba($black, .5);
        }
    }

    td,
    th {
        white-space: nowrap;

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        &.fit {
            width: 0;
        }

        &.nowrap {
            white-space: nowrap;
        }
    }

    td {
        cursor: default;

        a {
            padding: .5em 1em;
            display: block;
        }

        &.alert {
            padding: 0 1rem;

            span {
                color: $red;
                border: none;
                border: 1px solid $red;
                cursor: pointer;
                padding: 5px;
                margin-left: 5px;
                border-radius: 5px;
                font-size: small;
                margin: auto;

                &:hover {
                    background-color: $red;
                    color: $white;
                }
            }
        }
    }

    .icon {
        width: 1rem;
        height: 1rem;
    }

    tbody {
        tr {
            transition: .1s ease-out background-color;

            &:nth-child(odd) {
                background-color: rgba($primary-color, .1);
            }

            &.supervisor {
                background-color: rgba($black, .15);

                &:nth-child(odd) {
                    background-color: rgba($black, .2);
                }
            }
        }
    }
}
</style>
