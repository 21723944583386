<template>
    <div class="topbar">
        <router-link class="logo" :to="{ name: 'home' }">
            {{ vTextScoi }}
            <small v-text="`${$t('generic.scoiVersion')}`"></small>
        </router-link>

        <div class="menu">
            <router-link class="links-anchor" to="#">
                <IconComponent class="icon" icon="notification" />
            </router-link>

            <router-link class="links-anchor" to="#">
                <IconComponent class="icon" icon="chat" />
            </router-link>

            <div class="logout-anchor" v-if="enterWithNoEmail" @click.prevent="$store.dispatch('logout')">
                <span v-text="`${$t('action.exit')}`"></span>
                <IconComponent class="icon logout" icon="logout" />
            </div>

            <div class="dropdown" v-else>
                <button class="dropbtn" v-if="currentUser">{{ currentUser.name }}</button>
                <div class="dropdown-content">
                    <router-link to='/perfil'>
                        <span class="dropdown-option" v-text="`${$t('generic.perfil')}`"></span>
                    </router-link>
                    <router-link to='#' @click.prevent="$store.dispatch('logout')">
                        <span class="dropdown-option exit" v-text="`${$t('action.exit')}`"></span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconComponent from '@/components/IconComponent.vue'

export default {
    components: {
        IconComponent,
    },
    props: {
    },
    data() {
        return {
            vTextScoi: `${this.$t('generic.scoi')}`,
        }
    },
    methods: {
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        enterWithNoEmail() {
            return this.$store.state.users.currentUser.email == ''
        },

    },
    mounted() {
        this.$store.dispatch("getUserFromLocalStorage")
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/utilities/_variables.scss';

.topbar {
    @media print {
        display: none;
    }

    background: $topbar-bg-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    // FIXED LAYOUT
    position: relative;
    height: $topbar-height;
    z-index: 1050;


    // SHADOW
    &::after {
        content: '';
        position: absolute;
        bottom: -$topbar-shadow-size;
        left: 0;
        right: 0;
        height: $topbar-shadow-size;
        background-image: linear-gradient(180deg, $topbar-shadow-color, rgba($topbar-shadow-color, 0));
    }

    .logo {
        color: $primary-color;
        text-decoration: inherit;
        margin: 0 auto 0 0;
    }

    .menu {
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        height: 100%;
        flex-shrink: 0;
        width: max-content;

        .icon {
            width: 20px;
            fill: $primary-color;
        }
    }

    input {
        color: $black;
        margin: 0;
        width: 100px;
        height: 3em;
        padding: 0 1em;
        outline: none;
        appearance: none;
        border: none;
        font-size: 100%;
        margin: 0;
        border-bottom: 3px solid $primary-color;
    }

    .links-anchor {
        color: inherit;
        text-decoration: inherit;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: rgba(0, 0, 0, 0);
        transition-property: color, background-color;
        padding: 0 20px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }

    .logout-anchor {
        display: flex;
        align-items: center;
        padding: 10px;

        span {
            opacity: 0;
        }


        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;

            span {
                opacity: 100%;
                color: $red;
            }
        }

        .logout {
            margin-left: 10px;
            fill: $red;
        }
    }

    .dropdown {
        position: relative;
        flex-shrink: 0;

        .dropbtn {
            color: white;
            padding: 13px;
            font-size: 16px;
            border: none;
            color: $primary-color;
            display: flex;
            cursor: pointer;
            background: transparent;
        }

        &-content {
            display: none;
            position: absolute;
            right: 0;
            background-color: #f1f1f1;
            min-width: 100px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;

            a {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: block;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            .dropdown-option {
                color: $primary-color;
            }

            .exit {
                color: $red;
            }
        }
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown:hover .dropbtn {
        background-color: rgba(0, 0, 0, 0.1);
    }
}
</style>
