<template>
    <div v-if="loaded" class="exam">
        <LoadingBar class="bar" :progress="loadingProgress" />
        <router-view v-if="isAdmin"></router-view>
        <ClassifierItems :id="id" :state="state" :groups="groups" :supervise="supervise" v-else />
        <ClassifiersInfo :id="classifier.exam_class_id" :examId="id" v-if="showSupervision" />
    </div>
    <div v-else class="exam loading">
        <LoadingBar class="bar" :progress="loadingProgress" />
        <h2 v-text="loadingInfo"></h2>
    </div>
</template>

<script>
import ClassifierItems from "@/components/exams/ClassifierItems.vue";
import ClassifiersInfo from "@/components/exams/ClassifiersInfo.vue";
import LoadingBar from "@/components/LoadingBar.vue"

export default {
    props: {
        id: {
            required: true,
        },
        state: {
            default: 0,
        },
        supervise: {
            default: false,
        },
    },
    components: {
        ClassifierItems,
        ClassifiersInfo,
        LoadingBar,
    },
    data() {
        return {
            loaded: false,
            thingsToLoad: 5,// prova, items, turmas, classificadores, attribuicoes
        }
    },
    computed: {
        attributions() {
            return this.$store.getters['exams/distribution/attributions'](this.id)
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        classifier() {
            if (this.currentUser == null) {
                return null
            }
            return this.$store.getters['exams/classifiers/userId'](this.currentUser.id)
        },
        classifiers() {
            return this.$store.getters['exams/classifiers/exam'](this.id)
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        fetchedAttributions() {
            return this.$store.getters['exams/distribution/fetchedAttributions'](this.id)
        },
        fetchedClassifiers() {
            return this.$store.getters['exams/classifiers/fetched'](this.id)
        },
        fetchedGroups() {
            return this.$store.getters['exams/groups/fetched'](this.id)
        },
        fetchedItems() {
            return this.$store.getters['exams/items/fetched'](this.id)
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        loadingProgress() {
            let progress = 0
            let step = 100 / this.thingsToLoad
            if (this.exam != null) {
                progress += step
            }
            if (this.fetchedItems) {
                progress += step
            }
            if (this.fetchedGroups) {
                progress += step
            }
            if (this.fetchedClassifiers) {
                progress += step
            }
            if (this.fetchedAttributions) {
                progress += step
            }
            return progress
        },
        showSupervision() {
            if (this.classifier == null) {
                return false
            }
            if (!this.classifier.supervisor) {
                return false
            }
            return this.$route.name == 'Exam'
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role === 1
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.id)
        },
        loadingInfo() {
            if (this.exam == null) {
                return "A carregar prova"
            }
            if (!this.fetchedItems) {
                return "A carregar items"
            }
            if (!this.fetchedGroups) {
                return "A carregar turmas"
            }
            if (!this.fetchedClassifiers) {
                return "A carregar classificadores"
            }
            if (!this.fetchedAttributions) {
                return "A carregar attribuicoes"
            }
            return "Done"
        },
    },
    watch: {
        fetchedAttributions: {
            immediate: true,
            handler() {
                if (!this.fetchedAttributions) {
                    return
                }
                this.loaded = true
            },
        },
        fetchedClassifiers: {
            immediate: true,
            handler() {
                if (!this.fetchedClassifiers || this.fetchedAttributions) {
                    return
                }
                this.loadingProgress += 100 / this.thingsToLoad
                this.$store.dispatch('exams/distribution/getAttributions', { examId: this.id })
            },
        },
        exam: {
            immediate: true,
            handler() {
                if (this.exam == null || this.fetchedItems) {
                    return
                }
                this.loadingProgress += 100 / this.thingsToLoad
                this.$store.dispatch('exams/items/getAll', this.id)
            },
        },
        fetchedGroups: {
            immediate: true,
            handler() {
                if (!this.fetchedGroups || this.fetchedClassifiers) {
                    return
                }
                this.loadingProgress += 100 / this.thingsToLoad
                this.$store.dispatch('exams/classifiers/getAll', { examId: this.id })
            },
        },
        fetchedItems: {
            immediate: true,
            handler() {
                if (!this.fetchedItems || this.fetchedGroups) {
                    return
                }
                this.loadingProgress += 100 / this.thingsToLoad
                this.$store.dispatch('exams/groups/getAll', this.id)
            },
        },
    },
    mounted() {
        this.$store.dispatch('exams/listen', this.id)
        this.$store.dispatch('exams/getId', this.id)
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.exam {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $regular-padding;

    h2 {
        font-weight: bold;
        font-size: 1.5rem;
        margin: auto;
    }

    .bar {
        width: 100%;
        margin: 0;
    }
}
</style>
