<template>
    <div class="app">
        <TopNavBarLogIn v-if="currentUser" class="navbar" />
        <TopNavBar v-else class="navbar" />
        <SideMenu v-if="currentUser" class="sidebar" />
        <router-view v-if="ready" class="routerview" />
        <FooterBar class="footer" />
        <ScrollUpButton />
    </div>
</template>

<script>
import TopNavBar from './components/TopNavBar/TopNavBar.vue'
import FooterBar from '@/components/FooterBar/FooterBar.vue';
import SideMenu from '@/components/SideMenu.vue';
import TopNavBarLogIn from './components/TopNavBar/TopNavBarLogIn.vue';
import ScrollUpButton from '@/components/SCOI/ScrollUpButton/ScrollUpButton.vue';

export default {
    components: {
        TopNavBar,
        FooterBar,
        SideMenu,
        TopNavBarLogIn,
        ScrollUpButton,
    },
    data() {
        return {
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        ready() {
            if (!this.$store.state.users.checkedForUserInLocalStorage) {
                return false
            }
            return true
        },
        token() {
            return this.$store.getters["user/token"]
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(newUser, oldUser) {
                if (this.currentUser != null && oldUser == null) { // Found user (maybe in localStorage)
                    this.$store.dispatch("getUser")
                }
                if (newUser == null && oldUser != null) { // Logout
                    this.$store.commit('checkLocalStorage')
                    this.$router.push({ name: 'entrar' })
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch('settings/getAll')
        this.$store.dispatch('getUserFromLocalStorage')
    }
}
</script>

<style lang="scss">
@import './assets/utilities/_variables.scss';

.noclick {
    pointer-events: none;
}


* {
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $black;
    background: $body-bg ;
}

#app {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.app {
    height: 100vh;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
        'navbar navbar'
        'sidebar routerview'
        'footer footer';

    .navbar {
        grid-area: navbar;
    }

    .sidebar {
        grid-area: sidebar;
    }

    .routerview {
        grid-area: routerview;
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: padding .2s ease-in-out;
        min-height: 0;
    }

    .footer {
        grid-area: footer;
    }
}
</style>



<!-- <template>
  <TopNavBar />
  <div class="flex">
    <SideBarPage v-if="currentUser"/>
    <router-view v-if="ready" />
  </div>
  <FooterBar />
</template>

<script>
import TopNavBar from './components/TopNavBar/TopNavBar.vue'
import FooterBar from '@/components/FooterBar/FooterBar.vue';
import SideBarPage from './views/SideBarPage.vue';


export default {
  components: {
    TopNavBar,
    FooterBar,
    SideBarPage
  },
  data() {
    return {
      goBackRoute: null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    ready() {
      return this.$store.state.checkedForUserInLocalStorage
    },
    token() {
      return this.$store.getters["user/token"]
    },
  },
  watch: {
    async currentUser(current, old) {
      // não tem login
      if (current == null) {
        return this.$router.push("/entrar")
      }
      //Tem login, mas não esta no entrar
      // if (this.$route.name != 'entrar') {
      if (window.location.pathname != "/entrar") {
        return
      }
      // fez login com sucesso
      if (old == null) {
        if (this.goBackRoute) {
          this.$router.push(this.goBackRoute)
        } else {
          this.$router.push("/home")
        }
        this.$store.dispatch("getUser")
      }
    },
    ready() {
      if (this.ready && this.currentUser == null) {
        if (window.location.pathname != "/entrar") {
          this.goBackRoute = window.location.pathname + window.location.search
        }
        this.$router.push("/entrar")
      }
    },
  },
  mounted() {
    this.$store.dispatch("getUserFromLocalStorage")
  }
}
</script>

<style lang="scss">
@import './assets/utilities/_variables.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $black;
  background: $body-bg ;
}

a { 
  text-decoration: none; 
}

.flex {
  display: flex;
  align-items: center;
  padding-top: 40px;

}
</style> -->
