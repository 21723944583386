import axios from '../axiosInstance'

const schools = {
    namespaced: true,
    state: () => ({
        all: [],
        school: null,
    }),
    getters: {
        getId: (state) => (id) => {
            return state.all.find((school) => school.id === parseInt(id));
        },
        ids: (state) => (ids) => {
            let schools = {}
            let found = false
            for (let school of state.all) {
                if (!ids.includes(school.id)) {
                    continue
                }
                found = true
                schools[school.id] = school
            }
            if (found) {
                return schools
            }
            return null
        },
    },
    mutations: {
        add(state, newSchool) {
            state.all.push(newSchool);
        },
        all(state, schools) {
            state.all = schools;
        },
        delete(state, school) {
            const index = state.all.findIndex(({ id }) => school.id === id);
            state.all.splice(index, 1);
        },
        school(state, school) {
            const index = state.all.findIndex(({ id }) => school.id === id);
            if (index < 0) {
                state.all.push(school);
            } else {
                state.all.splice(index, 1, school);
            }
        },
    },
    actions: {
        async create({ commit }, newSchool) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/schools`,
                    newSchool
                );

                commit("add", results.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async delete({ commit }, id) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/schools/${id}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async edit({ commit }, { id, school }) {
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/users/${id}`,
                    school
                );
                commit("school", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getAll({ commit }) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/schools`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getId({ getters, dispatch }, id) {
            if (getters.getId(id) == null) {
                dispatch("fetchId", id);
            }
        },
    },
};

export default schools;
