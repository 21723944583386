<template>
    <div class="schools-container">
        <div class="schools-items">
            <p v-for="column of columns" :key="column.text">{{ column.text }}</p>
        </div>
        <p v-for="school in schools" :key="school.id" class="list">
            <router-link :to="{ name: 'SchoolEdit', params: { id: school.id } }">
                <div class="schools">
                    <p>{{ school.name }}</p>
                    <p>{{ school.code }}</p>
                    <span @click.prevent="$store.dispatch('schools/delete', school.id)"
                        v-text="`${$t('action.remove')}`"></span>
                </div>
            </router-link>
        </p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            columns: [
                { text: `${this.$t('generic.name')}` },
                { text: `${this.$t('generic.code')}` },
            ],

        };
    },
    computed: {
        schools() {
            return this.$store.state.schools.all;
        },
    },
    mounted() {
        this.$store.dispatch("schools/getAll");
    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.schools-container {

    background-color: $white;
    padding: 0 20px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);


    .schools-items {
        padding: 1rem;
        border-bottom: 2px solid $primary-color;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        p {
            margin: 0;
            font-weight: bold;
        }
    }

    .list {
        margin: 0;
        padding-left: 1rem;
        border-top: 1px solid gray;

        &:hover {
            background: rgba($primary-color, 0.1);

        }

        .schools {
            display: grid;
            flex-wrap: wrap;
            grid-template-columns: repeat(5, 1fr);


            span {
                margin-top: 15px;
                color: $red;

                &:hover {
                    margin-top: 12px;
                    font-size: larger;
                }


            }
        }

    }
}
</style>
