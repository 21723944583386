<template>
    <div class="settings">
        <h2 class="title" v-text="$t('generic.general')"></h2>

        <ul>
            <li>
                <label @click.prevent="set(messages, !messages.value)">
                    <span class="text" v-text="$tc('models.message', 0)"></span>
                    <span class="switch" :class="{ on: messages.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(sendToReview, !sendToReview.value)">
                    <span class="text" v-text="$tc('action.shaping.sendToReview', 0)"></span>
                    <span class="switch" :class="{ on: sendToReview.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(saveForLater, !saveForLater.value)">
                    <span class="text" v-text="$tc('action.shaping.seeLater', 0)"></span>
                    <span class="switch" :class="{ on: saveForLater.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(review, !review.value)">
                    <span class="text" v-text="$tc('action.review', 0)"></span>
                    <span class="switch" :class="{ on: review.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(notifications, !notifications.value)">
                    <span class="text" v-text="$tc('models.notification', 0)"></span>
                    <span class="switch" :class="{ on: notifications.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(finish, !finish.value)">
                    <span class="text" v-text="$tc('action.finish', 0)"></span>
                    <span class="switch" :class="{ on: finish.value }"></span>
                </label>
            </li>
            <li>
                <label @click.prevent="set(govAuth, !govAuth.value)">
                    <span class="text" v-text="$tc('generic.govAuth', 0)"></span>
                    <span class="switch" :class="{ on: govAuth.value }"></span>
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'GeneralSettings',
    data() {
        return {
        }
    },
    methods: {
        set(setting, value) {
            if (setting.id != null) {
                this.$store.dispatch('settings/update', { id: setting.id, value })
            } else {
                this.$store.dispatch('settings/create', { name: setting.name, value })
            }
        },
    },
    computed: {
        finish() {
            let finish = this.$store.getters['settings/name']('finishButton')
            if (finish == null) {
                finish = { name: 'finishButton', value: false }
            }
            return finish
        },
        govAuth() {
            let setting = this.$store.getters['settings/name']('govAuth')
            if (setting != null) {
                return setting
            }
            return { name: "govAuth", value: false }
        },
        messages() {
            let messages = this.$store.getters['settings/name']('messages')
            if (messages == null) {
                messages = { name: 'messages', value: false }
            }
            return messages
        },
        notifications() {
            let notifications = this.$store.getters['settings/name']('notifications')
            if (notifications == null) {
                notifications = { name: 'notifications', value: false }
            }
            return notifications
        },
        review() {
            let review = this.$store.getters['settings/name']('review')
            if (review == null) {
                review = { name: 'review', value: false }
            }
            return review
        },
        saveForLater() {
            let saveForLater = this.$store.getters['settings/name']('saveForLater')
            if (saveForLater == null) {
                saveForLater = { name: 'saveForLater', value: false }
            }
            return saveForLater
        },
        sendToReview() {
            let sendToReview = this.$store.getters['settings/name']('sendToReview')
            if (sendToReview == null) {
                sendToReview = { name: 'sendToReview', value: false }
            }
            return sendToReview
        },
    },
    watch: {
    },
    mounted() {
    },
}
</script>

<style lang="scss">
@import "@/assets/utilities/_variables.scss";

.settings {
    padding: $regular-padding;

    .title {
        margin: 0 0 calc($regular-padding/2);
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: fit-content;

        label {
            display: flex;
            width: 100%;
            position: relative;
            align-items: center;
            padding: calc($regular-padding / 2);
            user-select: none;

            &:hover {
                background-color: rgba(black, .1);
            }

            .text {
                margin: 0 $regular-padding 0 0;
            }

            input[type=checkbox] {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .switch {
                position: relative;
                display: block;
                cursor: pointer;
                width: 60px;
                height: 34px;
                background-color: $dark-border-color;
                transition: .4s background-color;
                margin: 0 0 0 auto;

                &.on {
                    background-color: $primary-color;

                    &::before {
                        transform: translateX(26px);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 26px;
                    height: 26px;
                    left: 4px;
                    bottom: 4px;
                    background: $white;
                    transition: .4s transform;
                }
            }
        }
    }
}
</style>
