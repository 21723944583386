<template>
    <BubbleContent :title="`${$t('action.shaping.distributeItemsThroughClassifier')}`" :buttons="button"
        @submit.prevent="$store.dispatch('exams/distribution/attribute', { exam: id, newAttribution: attributionData })">
        <div class="distribution-items">
            <p v-text="`${$t('models.classifier')}`"></p>
            <p v-for="item in items" :key="item.id">
                <span>{{ item.name }}</span>
            </p>
        </div>
        <ExamsDistributionGroupItems v-for="group in groups" :key="group.id" :id="group.id"
            @update="attributeItems($event, group.id)" />
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import ExamsDistributionGroupItems from './ExamsDistributionGroupItems.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
        ExamsDistributionGroupItems,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.distribute')}`, type: 'submit' },
            ],
            attributions: {},
            item_attributions: '',
        };
    },
    methods: {
        attributeItems(value, groupId) {
            this.attributions[groupId] = value
        },
    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return {
                item_attributions: attributions,
            }
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
    },
    watch: {
        attribution: {
            immediate: true,
            handler() {
                if (this.items != null) {
                    this.item_attributions = this.items.item_attributions
                }
            }
        }
    },
    mounted() {
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';




.distribution-items {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;

}
</style>
