<template>
    <div class="progress" v-if="isAdmin">
        <div class="filter">
            <ul>
                <li v-text="$t('generic.general')" @click.prevent="selected = null"
                    :class="{ selected: selected == null }">
                </li>
                <li v-for="item in items" :key="item.id" v-text="item.name" :class="{ selected: item.id == selected }"
                    @click.prevent="toggleItem(item)">
                </li>
            </ul>
        </div>

        <div class="filter">
            <ul>
                <li v-text="$t('action.calculate')" @click.prevent="$store.dispatch('exams/calc', id)"
                    :class="{ selected: selected == null }">
                </li>
            </ul>
        </div>

        <TableCard :columns="columns" :rows="rows" class="progress_table" />

        <TableCard :columns="columnsSupervisors" :rows="rowsSupervisors" class="progress_table" />
    </div>
</template>

<script>
import TableCard from '@/components/TableCard.vue';
import moment from 'moment';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        TableCard,
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        toggleItem(item) {
            this.selected = item.id
        },
    },
    computed: {
        attributions() {
            return this.$store.getters['exams/distribution/attributions'](this.id)
        },
        classifiedTotal() {
            return this.classifiers.reduce((total, classifier) => {
                for (let itemId in classifier.classified_items) {
                    if (total[itemId] == null) {
                        total[itemId] = { total: 0, classified: 0 }
                    }
                    total[itemId].classified += classifier.classified_items[itemId].classified
                    total[itemId].total += classifier.classified_items[itemId].total
                }
                return total
            }, {})
        },
        classifiers() {
            return this.$store.getters['exams/classifiers/groupsClassifiers'](this.groups.map(({ id }) => id))
        },
        columnsSupervisors() {
            let cols = [
                { text: this.$tc('models.supervisor', 2), class: "left" },
                {
                    text: this.$t('generic.toReclassify'),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('generic.reclassified', 0),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('action.finish', 0),
                    class: 'fit nowrap center',
                },
            ]
            return cols
        },
        columns() {
            let cols = [
                { text: this.$tc('models.classifier', 2), class: "left" },
                {
                    text: this.$t('action.shaping.classifying'),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('generic.classified_f', 0),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('generic.classified_f', 0),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('action.finish', 0),
                    class: 'fit nowrap center',
                },
                {
                    text: "2x",
                    class: 'fit nowrap center',
                },
                {
                    text: "3x",
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('generic.conflict', 0),
                    class: 'fit nowrap center',
                },
                {
                    text: this.$tc('generic.conflict', 0),
                    class: 'fit nowrap center',
                },
            ]
            return cols
        },
        currentUser() {
            return this.$store.state.users.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.id)
        },
        rows() {
            return this.classifiers.map((classifier) => {
                let classifierName = classifier.nr_classificador == null ? classifier.name : classifier.nr_classificador
                let row = [
                    { id: 'name', text: classifierName },
                    { id: '1.toClassify', text: 0 },
                    { id: '2.classified', text: 0 },
                    { id: '3.rate', text: 0 },
                    { id: '4.finishesAt', text: "-" },
                    { id: '5.2x', text: 0 },
                    { id: '6.3x', text: 0 },
                    { id: '7.conflict', text: 0 },
                    { id: '8.median', text: 0 },
                ]
                let startDate = this.exam.starts_at
                let endDate = this.exam.ends_at.max(Date.now())
                let days = moment.duration(endDate.diff(startDate)).as('days')
                let classificationRate = 0
                for (let item of this.items) {
                    if (this.selected != null && item.id != this.selected) {
                        continue
                    }
                    if (this.attributions[classifier.id] == null) {
                        continue
                    }
                    if (this.attributions[classifier.id][item.id] == null) {
                        continue
                    }
                    let attribution = this.attributions[classifier.id][item.id]
                    let classified = attribution.classified
                    row[1].text += attribution.classifications_number - attribution.classified
                    row[2].text += attribution.classified
                    row[3].text += attribution.classified
                    classificationRate += classified / days
                    row[5].text += attribution.statistic_double_classifications
                    row[6].text += attribution.statistic_triple_classifications
                }
                row[3].text = classificationRate.toFixed(2)
                let daysToComplete = row[1].text / classificationRate
                if (row[2].text > 0) {
                    row[4].text = `em ${daysToComplete.toFixed(2)} dias (${moment().add(daysToComplete * 24 * 60, 'minutes').locale('pt-PT').calendar()})`
                }
                return row
            })
        },
        rowsSupervisors() {
            return this.supervisors.map((supervisor) => {
                let supervisorName = supervisor.nr_classificador == null ? supervisor.name : supervisor.nr_classificador
                let row = [
                    { id: 'name', text: supervisorName },
                    { id: '1.toReclassify', text: 0 },
                    { id: '2.reclassified', text: 0 },
                    { id: '3.rate', text: 0 },
                ]
                //let startDate = this.exam.starts_at
                //let endDate = this.exam.ends_at.max(Date.now())
                //let days = moment.duration(endDate.diff(startDate)).as('days')
                let classificationRate = 0
                for (let item of this.items) {
                    //let attribution = this.attributions[supervisor.id][item.id]
                    //let classified = attribution.classified
                    if (supervisor.classified_items[item.id] != null) {
                        console.log("SUPERVISOR", supervisor.classified_items[item.id].resolve)
                        row[1].text += supervisor.classified_items[item.id].resolve
                    }
                    //row[2].text += attribution.classified
                    //row[3].text += attribution.classified
                    //classificationRate += classified / days
                }
                row[3].text = classificationRate.toFixed(2)
                let daysToComplete = row[1].text / classificationRate
                if (row[2].text > 0) {
                    row[4].text = `em ${daysToComplete.toFixed(2)} dias (${moment().add(daysToComplete * 24 * 60, 'minutes').locale('pt-PT').calendar()})`
                }
                return row
            })
        },
        supervisors() {
            return this.$store.getters['exams/classifiers/groupsSupervisors'](this.groups.map(({ id }) => id))
        },
    },
    watch: {
    },
    mounted() {
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.progress {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: auto;
    max-height: 100%;
    height: 100%;

    >* {
        flex-shrink: 0;
    }

    &_table {
        max-height: max-content;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;

        li {
            border: 1px solid $primary-color;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
            text-align: center;
            background: white;
            padding: .2em;

            &.selected {
                font-weight: bold;
                background: $primary-color;
                color: $white;
            }
        }
    }
}
</style>
