<template>
    <div class="content-users-create">
        <BubbleContent :title="`${$t('action.addUser')}`" :buttons=button :loadingInfo="loadingInfo" @submit="submit">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputContent :label="`${$t('generic.email')}`" v-model="email" />
                <InputContent :label="`${$t('generic.password')}`" type="password" v-model="password" />
                <InputSelectContent :label="`${$t('generic.accessLevel')}`" :options="options" :choose="choose" />
            </template>
        </BubbleContent>
        <br>
        <UserImport :names="names" />
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import UserImport from '../TabUser/UserImport.vue'
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';
import uuid4 from 'uuid4';

export default {
    components: {
        BubbleContent,
        UserImport,
        InputContent,
        InputSelectContent,
    },
    data() {
        return {
            uid: null,
            name: "",
            email: "",
            password: "",
            options: [
                { text: `${this.$t('action.shaping.chooseAnOptionNotDefine')}`, value: '0' },
                { text: `${this.$t('action.shaping.chooseAnOptionAdmin')}`, value: '1' },
                { text: `${this.$t('action.shaping.chooseAnOptionIave')}`, value: '2' },
            ],
            choose: `${this.$t('action.shaping.chooseAnOption')}`,
            names: [
                { text: `${this.$t('generic.name')}` },
                { text: `${this.$t('generic.email')}` },
                { text: `${this.$t('generic.password')}` },
                { text: `${this.$t('generic.schoolCode')}` },
            ]
        };
    },
    methods: {
        submit() {
            this.$store.dispatch('users/create', { userData: this.newUserData, uid: this.uid })
            this.email = ''
            this.name = ''
            this.password = ''
        },
    },
    computed: {
        button() {
            return [
                { text: `${this.$t('action.save')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.email && this.name && this.password
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        newUserData() {
            return {
                email: this.email,
                name: this.name,
                password: this.password,
            }
        },
    },
    mounted() {
        this.uid = uuid4();
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-users-create {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;
            }


        }
    }

}
</style>
