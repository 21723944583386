<template>
    <ContentCard :buttons=button :title="`${$t('action.shaping.addItem')}`" :loadingInfo="loadingInfo" @submit="submit">
        <template #form>
            <InputContent :label="`${$tc('models.item')}`" v-model="name" />
            <template v-if="exam.kind == 0">
                <p v-text="`${$tc('generic.column', itemColumns.length)}`"></p>
                <div>
                    <p v-for="itemColumn in itemColumns" :key="itemColumn.id" class="box-container-column">
                        <span>{{ itemColumn }}</span>
                        <button class="btn-removeColumn" @click.prevent="removeColumn(itemColumn)"
                            v-text="`${$tc('action.remove')}`"></button>
                    </p>
                </div>
                <input type="text" v-model="searchQuery">
                <div>
                    <p v-for="col in columnItems" :key="col" @click="addColumn(col)">
                        <span>{{ col }}</span>
                    </p>
                </div>
                <InputContent :label="`${$t('generic.effortIndex')}`" type='number' v-model="effort" />
                <InputContent :label="`${$t('generic.overlay')}`" type='number' v-model="overflow" />
                <InputContent :label="`${$t('action.shaping.classifyBySupervisors')}`" type='number'
                    v-model="supervisor_distribution" />
                <div class="radio-container">
                    <InputRadio name="option" class="radio-option" :label="`${$t('generic.latex')}`" v-model="latex"
                        value="1" />
                    <InputRadio name="option" class="radio-option" :label="`${$t('generic.text')}`" v-model="latex"
                        value="0" />
                </div>
            </template>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';
import InputRadio from '@/components/SCOI/Inputs/InputRadio.vue';
import uuid4 from 'uuid4';
import axios from "@/axiosInstance"


export default {
    name: "ExamItemCreate",
    props: {
        id: {
            required: true,
        },
    },
    components: {
        InputContent,
        InputRadio,
        ContentCard
    },
    data() {
        return {
            columns: [],
            uid: uuid4(),
            name: '',
            effort: 1,
            overflow: 10,
            supervisor_distribution: 100,
            latex: '0',
            searchQuery: '',
            itemColumns: [],
            fetchingColumns: 0,
        };
    },
    methods: {
        addColumn(col) {
            this.itemColumns.push(col);
        },
        removeColumn(column) {
            this.itemColumns = this.itemColumns.filter(itemColumn => itemColumn !== column)
        },
        submit() {
            this.$store.dispatch('exams/items/create', { data: this.newItemData, examId: this.id, uid: this.uid })
            this.name = ''
            this.itemColumns = []
        },
        async fetchColumns() {
            let id = this.id
            this.fetchingColumns = id
            const res = await axios.get(
                `${process.env.VUE_APP_API_HOST}/exams/${this.id}/columns`
            )
            if (this.fetchingColumns == id) {
                this.columns = res.data.data
            }
        }
    },
    computed: {
        button() {
            return [
                { text: `${this.$t('action.add')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.name
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        allColumns() {
            return this.columns
        },
        newItemData() {
            return {
                name: this.name,
                effort: this.effort,
                columns: this.itemColumns,
                overflow: this.overflow,
                supervisor_distribution: this.supervisor_distribution,
                latex: this.latex,
            }
        },
        columnItems() {
            if (this.searchQuery) {
                return this.allColumns.filter((column) => column.toLowerCase().includes(this.searchQuery.toString().toLowerCase())).slice(0, 5)
            } else {
                return []
            }
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                this.fetchColumns();
            },
        },
        exam: {
            immediate: true,
            handler() {
                if (this.exam == null) {
                    return
                }
                if (this.exam.kind == 1) {
                    this.overflow = 0
                    this.supervisor_distribution = 0
                    this.itemColumns = [this.exam.student_col]
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.radio-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -1px;

    .radio-option {
        margin: 0;
        text-align: center;
    }
}

span {
    cursor: pointer;
}

.box-container-column {
    padding: 10px;
    width: 60%;
    box-shadow: 0px 2px 6px rgba($black, .4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-removeColumn {
        color: $red;
        border: none;
        border: 1px solid $red;
        cursor: pointer;
        padding: 5px;
        margin-left: 5px;
        border-radius: 5px;
        font-size: small;

        &:hover {
            background-color: $red;
            color: $white;
        }
    }
}
</style>
