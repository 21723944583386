import axios from '@/axiosInstance'

const groups = {
    namespaced: true,
    state: () => ({
        all: [],
        fetched: {},
        group: null,
    }),
    getters: {
        fetched: (state) => (id) => {
            if (state.fetched[id] == null) {
                return false
            }
            return state.fetched[id]
        },
        getId: (state) => (id) => {
            return state.all.find((group) => group.id === parseInt(id));
        },
        exam: (state) => (examId) => {
            examId = parseInt(examId);
            const groups = state.all.filter((group) => group.exam_id === examId);
            return groups
        },
    },
    mutations: {
        add(state, group) {
            group.classifiers = []
            state.all.push(group);
        },
        addClassifiers(state, { id, data }) {
            let group = state.all.find((group) => group.id == id)
            if (group == null) {
                return
            }
            group.classifiers = data
        },
        all(state, { groups, id }) {
            let fetchedItems = {}
            fetchedItems[id] = true
            state.fetched = fetchedItems
            state.all = groups;
        },
        clear(state) {
            state.all = []
            state.group = null
        },
        delete(state, group) {
            const index = state.all.findIndex(({ id }) => group.id === id);
            state.all.splice(index, 1);
        },
        group(state, group) {
            const index = state.all.findIndex(({ id }) => group.id === id);
            if (index < 0) {
                state.all.push(group);
            } else {
                state.all.splice(index, 1, group);
            }
        },
    },
    actions: {
        async create({ commit }, { exam, newGroup }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes`,
                    newGroup
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async delete({ commit }, { exam, examClass }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes/${examClass}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async addClassifier({ commit }, { examClass, classifierId }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/classes/${examClass}/classifiers`,
                    { user_id: classifierId }
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async remClassifier({ commit }, { classifierId }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/classes/classifiers/${classifierId}`,
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async edit({ commit }, { examId, examClass, group }) {
            try {
                console.log(group)
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/classes/${examClass}`,
                    group
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async getAll({ commit }, examId) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/classes`
                );
                commit("all", { groups: results.data.data, id: examId });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getClassifiers({ commit }, id) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/classes/${id}/classifiers`
                );
                commit("addClassifiers", { id, data: results.data.data });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getId({ commit }, { exam, examClass }) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes/${examClass}`
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
    },
};

export default groups;
