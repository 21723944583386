<template>
    <div class="students-container">
        <div class="students-items">
            <p v-for="column of columns" :key="column.text">{{ column.text }}</p>
        </div>
        <p v-for="student in students" :key="student.id" class="list">
            <router-link :to="{ name: 'StudentEdit', params: { id: student.id } }">
                <div class="students">
                    <p>{{ student.name }}</p>
                    <p>{{ student.school }}</p>
                    <span @click.prevent="$store.dispatch('students/delete', student.id)"
                        v-text="`${$t('action.remove')}`"></span>
                </div>
            </router-link>
        </p>
    </div>
</template>
<script>

export default {
    data() {
        return {
            columns: [
                { text: `${this.$t('generic.name')}` },
                { text: `${this.$tc('models.school')}` },
            ],
        };
    },
    computed: {
        students() {
            return this.$store.state.students.all;
        },
    },
    mounted() {
        this.$store.dispatch("students/getAll");
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.students-container {

    background-color: $white;
    padding: 0 20px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);


    .students-items {
        padding: 1rem;
        border-bottom: 2px solid $primary-color;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        p {
            margin: 0;
            font-weight: bold;
        }
    }

    .list {
        margin: 0;
        padding-left: 1rem;
        border-top: 1px solid gray;

        &:hover {
            background: rgba($primary-color, 0.1);

        }

        .students {
            display: grid;
            flex-wrap: wrap;
            grid-template-columns: repeat(5, 1fr);


            span {
                margin-top: 15px;
                color: $red;

                &:hover {
                    margin-top: 12px;
                    font-size: larger;
                }


            }
        }

    }
}
</style>
