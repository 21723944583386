<template>
    <div class="sessions">
        <SessionsTable :exam="exam" :sessions="sessions" class="table" />
        <SessionCreate :exam="exam" :schools="schools" class="create"></SessionCreate>
    </div>
</template>

<script>
import SessionCreate from '@/components/sessions/SessionCreate';
import SessionsTable from '@/components/exams/SessionsTable';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        SessionCreate,
        SessionsTable,
    },
    data() {
        return {
            attributions: {},
        };
    },
    methods: {
        attributeItems(value, groupId) {
            this.attributions[groupId] = value
        },
    },
    computed: {
        canShowSessionTable() {
            if (this.exam == null || this.sessions == null) {
                return false
            }
            return true
        },
        schools() {
            return this.$store.state.schools.all
        },
        sessions() {
            if (this.exam == null) {
                return null
            }
            return this.$store.getters['exams/sessions/exam'](this.exam.id)
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
        file() {
            return this.files[0]
        },
    },
    watch: {
        exam: {
            immediate: true,
            handler() {
                if (this.exam == null) {
                    return
                }
                this.$store.dispatch('exams/sessions/getAll', this.id)
            },
        },
        schools: {
            immediate: true,
            handler() {
                if (this.schools.length != 0) {
                    return
                }
                this.$store.dispatch('schools/getAll')
            },
        },
    },
    mounted() {
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.sessions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-height: 100%;
    padding: $regular-padding;
    overflow: auto;

    .table,
    .create {
        flex-grow: 1;
    }

    .table {
        min-height: fit-content;
        min-height: fit-content;
    }

    .create {
        min-height: fit-content;
    }
}
</style>
