import axios from '@/axiosInstance'
import moment from 'moment'

import classifiers from "@/store/exams/sessions/classifiers.js";
import students from "@/store/exams/sessions/students.js";

function sessionSetup(session) {
    session.created_at = moment(session.created_at)
    session.classification_from = moment(session.classification_from)
    session.classification_to = moment(session.classification_to)
    session.execution_at = moment(session.execution_at)
    session.updated_at = moment(session.updated_at)
    return session
}


const sessions = {
    namespaced: true,

    state: () => ({
        all: [],
        fetched: {},
        group: null,
    }),

    getters: {
        fetched: (state) => (id) => {
            if (state.fetched[id] == null) {
                return false
            }
            return state.fetched[id]
        },
        id: (state) => (id) => {
            return state.all.find((session) => session.id === parseInt(id));
        },
        exam: (state) => (examId) => {
            examId = parseInt(examId);
            const sessions = state.all.filter((group) => group.exam_id === examId);
            return sessions
        },
    },

    mutations: {
        add(state, session) {
            const index = state.all.findIndex(({ id }) => session.id === id);
            session = sessionSetup(session)
            if (index < 0) {
                state.all.push(session);
            } else {
                state.all.splice(index, 1, session);
            }
        },
        all(state, { sessions, id }) {
            let fetchedItems = {}
            fetchedItems[id] = true
            state.fetched = fetchedItems
            for (let session of sessions) {
                sessionSetup(session)
            }
            state.all = sessions;
        },
        clear(state) {
            state.all = []
            state.group = null
        },
        delete(state, group) {
            const index = state.all.findIndex(({ id }) => group.id === id);
            state.all.splice(index, 1);
        },
        group(state, group) {
            const index = state.all.findIndex(({ id }) => group.id === id);
            if (index < 0) {
                state.all.push(group);
            } else {
                state.all.splice(index, 1, group);
            }
        },
    },

    actions: {
        async create({ commit }, { exam, session }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${exam}/sessions`,
                    session
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async update({ commit }, session) {
            console.log(session)
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session.id}`,
                    session
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async delete({ commit }, session) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },


        async remClassifier({ commit }, { classifierId }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/classifiers/${classifierId}`,
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async edit({ commit }, { examId, session, group }) {
            try {
                console.log(group)
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/sessions/${session}`,
                    group
                );
                commit("group", results.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async get({ commit }, id) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${id}`
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getAll({ commit }, examId) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/sessions`
                );
                commit("all", { sessions: results.data.data, id: examId });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async submit({ commit }, { session, students }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/submit`,
                    { students }
                );
                commit("ok", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        }

    },

    modules: {
        classifiers,
        students,
    },
};

export default sessions;
