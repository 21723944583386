<template>
    <div class="messages" v-if="showMessages">
        <TopContainerNav :title="title" />
        <TopContainerNavBody :actions=actions />

        <div class="chat">
            <ChannelButtons :elements="exams" routeName="Channels" routeId="id" />

            <ChannelButtons :elements="channels" routeName="Channels" routeId="channel_id" v-if="channels.length > 1" />

            <ChannelView v-if="computedChannelId" :channel_id="computedChannelId" class="view" />
        </div>
    </div>
</template>

<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';

import ChannelButtons from '@/components/messages/ChannelButtons.vue';
import ChannelView from './messages/ChannelView.vue';

export default {
    props: {
        id: { default: null },
        channel_id: { default: null },
    },
    components: {
        ChannelButtons,
        TopContainerNav,
        TopContainerNavBody,
        ChannelView
    },
    data() {
        return {
        }
    },
    computed: {
        channels() {
            if (this.exam == null) {
                return []
            }
            return this.$store.getters['messages/exam'](this.exam.id)
        },
        computedChannelId() {
            if (this.channels.length == 1) {
                return this.channels[0].id
            }
            return this.channel_id
        },
        exam() {
            if (this.id == null) {
                return null
            }
            return this.$store.getters['exams/getId'](this.id)
        },
        exams() {
            return this.$store.state.exams.all
        },
        title() {
            return this.$tc('models.message', 2)
        },
        showMessages() {
            let setting = this.$store.getters['settings/name']('messages')
            if (setting != null) {
                return setting.value
            }
            return false
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        this.$store.dispatch('messages/getChannels')
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/utilities/variables";

.messages {
    height: 100%;

    .chat {
        height: 100%;
        min-height: 0;
    }

    .view {
        flex-grow: 1;
    }

    .content {
        overflow: auto;
    }

    .chat {
        display: flex;
    }

    .list {
        margin: 0;
        list-style: none;
        padding: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;

        .button {
            padding: .5em;
            font-size: 1rem;
            display: block;

            &.router-link-active {
                background-color: $secondary-color;
            }
        }
    }


}
</style>
