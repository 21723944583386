import axios from '../axiosInstance'
import theEcho from '../echoInstance'

const settings = {
    namespaced: true,
    state: {
        all: [],
        gotAll: false,
    },
    getters: {
        id: (state) => (id) => {
            return state.all.find((setting) => setting.id === parseInt(id));
        },
        name: (state) => (name) => {
            return state.all.find((setting) => setting.name == name);
        },
    },
    mutations: {
        add(state, setting) {
            state.all.push(setting);
        },
        all(state, settings) {
            state.all = settings;
            state.gotAll = true;
        },
        clear(state) {
            state.all = []
        },
        delete(state, setting) {
            const index = state.all.findIndex(({ id }) => setting.id == id);
            if (index < 0) {
                return
            }
            state.all.splice(index, 1);
        },
    },
    actions: {
        async create({ commit }, { name, value }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/settings`,
                    { name, value }
                );
                commit("add", results.data.data);
            } catch (error) {
                console.log('ERROR: ' + error)
            }
        },
        async getAll({ state, commit }) {
            console.log("getting settings")
            if (state.gotAll) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/settings`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
                commit('clear')
            }
        },
        async update({ commit }, { id, value }) {
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/settings/${id}`,
                    { value },
                );
                commit("delete", { id });
                console.log(results.data.data)
                commit("add", results.data.data)
            } catch (error) {
                console.log('ERROR: ' + error)
            }
        },

        listenAll({ state, commit }) {
            if (state.allChannel != null) {
                return
            }
            let channel = theEcho.echo.private('exams')
            console.log("Listening to exams channel")
            channel.listen('Exams\\ExamCreated', (e) => {
                commit('exam', e.exam)
            })
            channel.listen('Exams\\ExamUpdated', (e) => {
                console.log("Exam updated")
                commit('exam', e.exam)
            })
            channel.listen('Exams\\ExamDeleted', (e) => {
                commit('delete', e.exam)
            })
            commit('allChannel', channel)
        },
    },
};

export default settings;
