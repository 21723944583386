import axios from '@/axiosInstance'
import theEcho from '@/echoInstance';

const distribution = {
    namespaced: true,
    state: () => ({
        all: [],
        attributions: [], // deprecate this list
        itemsData: {},
        examsData: {},
        attrs: {},
        attributing: [],
        newAttributions: [],
        loadedAttributions: [],
        progress: {},
        fetchedAttributions: {},
    }),
    getters: {
        attributions: (state) => (exam) => {
            let attrs = state.attrs[exam]
            if (attrs == null) {
                return {}
            }
            return attrs
            // return state.attributions.filter(({ exam_id }) => exam_id == exam)
        },
        attribution: (state) => (exam, classifierId, itemId) => {
            if (state.attrs[exam] == null) {
                return null
            }
            if (state.attrs[exam][classifierId] == null) {
                return null
            }
            return state.attrs[exam][classifierId][itemId]
        },
        allAttributions: (state) => (exam_id) => {
            return [
                ...state.attributions.filter(attr => attr.exam_id == exam_id && attr.selected == true),
                ...state.newAttributions.filter(attr => attr.exam_id == exam_id && attr.selected == true),
            ]
        },
        attributing: (state) => (id) => {
            return state.attributing.find((exam) => exam == id) != null
        },
        classifiers: (state) => (classifier_ids, exam_id = null) => {
            if (exam_id == null) {
                return state.attributions.filter(({ classifier_id }) => classifier_ids.includes(classifier_id))
            }
            let attrs = state.attrs[exam_id]
            if (attrs == null) {
                return []
            }
            attrs = []
            for (let id of classifier_ids) {
                console.log(id)
                console.log(state.attrs)
                attrs = [...attrs, ...Object.values(state.attrs[exam_id][id])]
            }
            return attrs
        },
        examClassifier: (state) => (exam, classifier) => {
            let attrs = state.attrs[exam]
            if (attrs == null) {
                return []
            }
            attrs = attrs[classifier]
            if (attrs == null) {
                return []
            }
            return attrs
        },
        fetchedAttributions: (state) => (id) => {
            if (state.fetchedAttributions[id] == null) {
                return false
            }
            return state.fetchedAttributions[id]
        },
        newAttribution: (state) => (classifierId, itemId) => {
            return state.newAttributions.find((attr) => {
                return attr.classifier_id == classifierId && attr.exam_item_id == itemId
            })
        },
        progress: (state) => (id) => {
            return state.progress[id]
        },
    },
    mutations: {
        addAttributions(state, { attributions, exam, attributing, fetched = false }) {
            if (fetched) {
                state.fetchedAttributions[exam] = fetched
            }
            let distIndex = state.attributing.findIndex((id) => exam == id)
            if (attributing && distIndex < 0) {
                state.attributing.push(exam)
            } else if (!attributing && distIndex > -1) {
                state.attributing.splice(distIndex, 1)
            }

            state.attributions = state.attributions.filter(({ exam_id }) => exam_id != exam)
            let attrs = state.attrs[exam]
            if (attrs == null) {
                attrs = {}
            }
            if (state.examsData[exam] == null) {
                state.examsData[exam] = { assigned: 0, classified: 0 }
            }
            for (let attr of attributions) {
                attr.exam_id = exam
                attr.selected = true
                if (attrs[attr.classifier_id] == null) {
                    attrs[attr.classifier_id] = {}
                }
                attrs[attr.classifier_id][attr.exam_item_id] = attr
                state.attributions.push(attr)
                state.examsData[exam].assigned += attr.classifications_number
                state.examsData[exam].classified += attr.classified
            }
            state.attrs[exam] = attrs
        },
        classification(state, classification) {
            const index = state.all.findIndex(({ id }) => classification.id === id)
            if (index < 0) {
                state.all.push(classification)
            } else {
                state.all.splice(index, 1, classification)
            }
        },
        clear(state) {
            state.all = []
            state.attributions = []
        },
        selectNew(state, { classifier_id, exam_item_id, exam_id }) {
            let attribution = state.newAttributions.find((attr) => {
                return attr.classifier_id == classifier_id && attr.exam_item_id == exam_item_id
            })
            if (attribution == null) {
                attribution = { exam_id, classifier_id, exam_item_id, selected: false, classifications_number: 0 }
                state.newAttributions.push(attribution)
            }
            attribution.selected = !attribution.selected
        },
        loadedAttributions(state, id) {
            if (state.loadedAttributions.includes(id)) {
                return
            }
            state.loadedAttributions.push(id)
        },
        removeAttribution(state, id) {
            let index = state.attributions.findIndex((attr) => attr.id == id)
            if (index < 0) {
                return
            }
            state.attributions.splice(index, 1)
        },
        updateAttribution(state, { attribution, exam }) {
            let attrs = state.attrs[attribution.classifier_id]
            if (attrs == null) {
                attrs = {}
            }
            attribution.exam_id = exam
            if (attrs[attribution.exam_item_id] == null) {
                attrs[attribution.exam_item_id] = {}
            }
            attrs[attribution.exam_item_id] = {
                ...attrs[attribution.exam_item_id],
                ...attribution,
            }
            state.attrs[attribution.classifier_id] = attrs
        },
        updateProgress(state, { id, progress }) {
            state.progress[id] = progress
        },
    },
    actions: {
        async attribute({ commit }, { exam, newAttribution, supervisors }) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/attribute`,
                    { item_attributions: newAttribution, supervisors })
                let data = res.data.data
                console.log(res.data)
                commit('addAttributions', { exam, attributions: data.attributions, attributing: data.attributing })
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async distribute({ commit }, { exam, attribution }) {
            try {
                const results = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/distribute`, attribution)
                commit('classification', results.data.data)

            } catch (error) {
                console.log('ERROR: ' + error)
            }

        },
        async getAttributions({ commit }, { examId }) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${examId}/attributions`
                )
                commit('addAttributions', { exam: examId, attributions: res.data.data, fetched: true })
                commit('loadedAttributions', examId)
            } catch (e) {
                console.log(e)
            }
        },

        listenUserAttributions({ state, commit }, user_id) {
            if (state.channel != null) {
                return
            }
            let channel = theEcho.echo.private(`item-attributions.${user_id}`)
            console.log("Listening to item attribution update channel")
            channel.listen('ItemAttributions\\Updated', (e) => {
                let attr = e.attribution
                attr.exam_id = e.exam_id
                console.log(e)
                commit('addAttributions', { exam: e.exam_id, attributions: [attr] })
            })
            commit('channel', channel)
        },

        listenUpdates({ commit }) {
            console.log('listening to updated')
            let channel = theEcho.echo.private(`exams`)
            channel.listen('ExamDistributionProgressUpdate', (e) => {
                console.log(e)
                commit('updateProgress', { id: e.examId, progress: e.progress })
            })
        },

        async moveAttribution({ commit }, { exam, classifier1, classifier2, examItem }) {
            const basePath = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/move`
            let url = `${basePath}/${classifier1}/${classifier2}`
            if (examItem != null) {
                url += `/${examItem}`
            }
            try {
                const res = await axios.post(url)
                let data = res.data.data
                for (let attr of data) {
                    attr.selected = true
                    if (attr.deleted) {
                        attr.selected = false
                    }
                    // commit('updateAttribution', { exam, attribution: attr })
                    commit('addAttributions', { exam, attributions: [attr] })
                }
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async setAttribution({ commit }, { exam, classifier, examItem, amount, locked }) {
            const basePath = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions`
            const url = `${basePath}/${classifier}/${examItem}`
            try {
                const res = await axios.post(url, { number: amount, locked })
                let attr = res.data.data
                // commit('updateAttribution', { exam, attribution: data })
                commit('addAttributions', { exam, attributions: [attr] })
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async swapAttribution({ commit }, { exam, classifier1, classifier2, examItem }) {
            const basePath = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/swap`
            let url = `${basePath}/${classifier1}/${classifier2}`
            if (examItem != null) {
                url += `/${examItem}`
            }
            try {
                const res = await axios.post(url)
                let data = res.data.data
                for (let attr of data) {
                    attr.selected = true
                    if (attr.deleted) {
                        attr.selected = false
                    }
                    // commit('updateAttribution', { exam, attribution: attr })
                    commit('addAttributions', { exam, attributions: [attr] })
                }
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async redistributeAttribution({ commit }, { exam, classifier, examItem }) {
            const url = `${process.env.VUE_APP_API_HOST}/exams/${exam}/attributions/redistribute/`
                + `${classifier}/${examItem}`
            try {
                const res = await axios.post(url)
                let data = res.data.data
                commit('removeAttribution', data.id)
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
    },
};

export default distribution;
