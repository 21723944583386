<template>
    <ContentCard :title="`${$t('action.distributeClassification')}`" :buttons="button" :loading="loading"
        @submit.prevent="submit">
        <div class="distribution-items">
            <p v-text="`${$tc('models.classifier')}`"></p>
            <p v-for="item in items" :key="item.id">
                <span>{{ item.name }}</span>
            </p>
            <p v-text="`${$t('generic.total')}`"></p>
            <p v-text="`${$t('generic.effort')}`"></p>
        </div>
        <ExamDistributionGroupClassifications v-for="group in groups" :key="group.id" :id="group.id" />
        <div class="classifications-total">
            <p class="total" v-text="`${$t('generic.total')}`"></p>
            <p v-for="item in items" :key="item.id">
                <span>{{ item.multiple_params }}</span>
                <!-- //!check the right parameter -->
            </p>
            <p v-text="`${$t('generic.value')}`"></p>
            <p v-text="`${$t('generic.value')}`"></p>
        </div>
    </ContentCard>
</template>

<script>
import ContentCard from "@/components/ContentCard.vue"
import ExamDistributionGroupClassifications from './ExamDistributionGroupClassifications.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        ContentCard,
        ExamDistributionGroupClassifications,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.distribute')}`, type: 'submit' },
            ],
            attributions: {},
            loading: false,
        }
    },
    methods: {
        submit() {
            this.$store.dispatch('examDistribution/distribute', {
                exam: this.id, attribution: this.attributionData
            })
        },
    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return attributions
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        items() {
            return this.$store.state.exams.items.all
        },
    },
    mounted() { },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';


.distribution-items {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;



}

.classifications-total {
    border-top: 1px solid black;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;

    .total {
        margin-right: 40px;
    }
}
</style>
