const EMPTY_USER = {
    name: "",
    created_at: "",
    updated_at: "",
    last_logged_in_at: "",
    role: 0,
    email: "",
}

import axios from '../axiosInstance'
import theEcho from '../echoInstance'

const users = {
    namespaced: true,
    state: () => ({
        all: [],
        checkedForUserInLocalStorage: false,
        currentUser: null,
        echo: null,
        error: null,
        online: [],
        schoolUsers: {},
        user: null,
        gotAll: false,
    }),
    getters: {
        getId: (state) => (id) => {
            return state.all.find(user => user.id === parseInt(id))
        },
        school: (state) => (id) => {
            let users = state.schoolUsers[id]
            if (users == null) {
                return []
            }
            return users
        },
        withId: (state) => (ids) => {
            if (ids == null) {
                return []
            }
            return state.all.filter(user => ids.includes(user.id))
        },
    },
    mutations: {
        setCurrentUser(state, user) {
            state.currentUser = user;
            if (user != null) {
                let userIndex = state.all.findIndex(({ id }) => id == user.id)
                if (userIndex < 0) {
                    state.all.push(user)
                } else {
                    state.all.splice(userIndex, 1, user)
                }
            }
            state.checkedForUserInLocalStorage = true;
            if (user != null) {
                localStorage.setItem("user", JSON.stringify(user));
                axios.defaults.headers["Authorization"] =
                    "Bearer " + user.api_token;
                theEcho.create(user.api_token)
                let online = theEcho.echo.join('online')
                    .here((users) => {
                        state.online = users
                    })
                    .joining((user) => {
                        state.online.push(user)
                    })
                    .leaving((user) => {
                        state.online = state.online.filter(u => u.id !== user.id)
                    })
                theEcho.echo.leave(online)
            } else {
                delete axios.defaults.headers.Authorization;
            }
        },

        setError(state, error) {
            state.error = error;
        },

        add(state, newUser) {
            state.all.push(newUser);
            let schoolId = newUser.school_id
            if (schoolId == null) {
                return
            }
            if (state.schoolUsers[schoolId] == null) {
                state.schoolUsers[schoolId] = []
            }
            const index = state.schoolUsers[schoolId].findIndex(({ id }) => newUser.id === id)
            if (index < 0) {
                state.schoolUsers[schoolId].push(newUser);
            } else {
                state.schoolUsers.splice(index, 1, newUser)
            }
        },
        all(state, users) {
            state.all = users
            state.gotAll = true
            for (let newUser of users) {
                let schoolId = newUser.school_id
                if (schoolId == null) {
                    continue
                }
                if (state.schoolUsers[schoolId] == null) {
                    state.schoolUsers[schoolId] = []
                }
                const index = state.schoolUsers[schoolId].findIndex(({ id }) => newUser.id === id)
                if (index < 0) {
                    state.schoolUsers[schoolId].push(newUser);
                } else {
                    state.schoolUsers[schoolId].splice(index, 1, newUser)
                }
            }
        },
        clear(state) {
            state.all = []
            state.user = null
        },
        delete(state, user) {
            const index = state.all.findIndex(({ id }) => user.id === id)
            state.all.splice(index, 1)
        },
        user(state, user) {
            const index = state.all.findIndex(({ id }) => user.id === id)
            if (index < 0) {
                state.all.push(user)
            } else {
                state.all.splice(index, 1, user)
            }
        },
    },
    actions: {
        async login({ commit }, { email, password }) {
            commit('setError', null)
            const credentials = {
                email,
                password,
            };
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/login`,
                    credentials
                );
                console.log(results)
                console.log("SEETTINGS CURRENT")
                commit("setCurrentUser", results.data.data);
            } catch (error) {
                commit('setError', error)
            }
        },

        async create({ commit }, { userData, uid }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/users`,
                    userData
                );
                commit("add", results.data);
                let successObj = { message: results.data.message, error: false, done: true, uid }
                commit('loadingMessages/messages', successObj, { root: true })
            } catch (error) {
                console.log("ERROR: " + error);
                let errorObj = error.response.data
                let errorMessage = errorObj.message
                errorObj = { message: errorMessage, error: true, done: true, uid }
                commit('loadingMessages/messages', errorObj, { root: true })
            }
        },

        async delete({ commit }, id) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/users/${id}`
                );
                commit('delete', results.data.data)

            } catch (error) {
                console.log('ERROR: ' + error)
            }

        },
        async edit({ commit }, { id, user, uid }) {
            if (user.password == '') {
                delete (user.password)
            }
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/users/${id}`, user
                );
                commit("user", results.data.data);
                let successObj = { message: results.data.message, error: false, done: true, uid }
                commit('loadingMessages/messages', successObj, { root: true })

            } catch (error) {
                console.log('ERROR: ', error)
                let message = error.message
                if (error.response != null) {
                    message = error.response.data.message
                    for (let m in error.response.data.data) {
                        message += "<br>"
                        message += error.response.data.data[m]
                    }
                }
                const errorObj = { message: message, error: true, done: true, uid }
                commit('loadingMessages/messages', errorObj, { root: true })
            }
        },

        async lowPriorityFetch({ commit, state }, id) {
            if (state.all.findIndex((user) => user.id == id) > -1) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/users/${id}`
                );
                commit("user", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async forceGetAll({ commit }) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/users`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getAll({ commit, state }) {
            if (state.gotAll) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/users`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getId({ getters, dispatch }, id) {
            if (getters.getId(id) == null) {
                dispatch("fetchId", id)
            }
        },
        async fetchId({ commit, dispatch }, id) {
            const emptyUser = { ...EMPTY_USER, id }
            commit("user", emptyUser)
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/users/${id}`
                );
                commit("user", results.data.data);
            } catch (error) {
                commit("delete", emptyUser)
                console.log("ERROR: " + error);
                setTimeout(() => dispatch("fetchId", id), 1000)
            }
        },
        async import({ commit }, { file, uid }) {
            try {
                let formData = new FormData()
                formData.append("file", file)
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/users/import`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                console.log(results)
                let successObj = { message: results.data.message, error: false, done: true, uid }
                commit('loadingMessages/messages', successObj, { root: true })
            } catch (error) {
                console.log("ERROR:" + error)
                let errorObj = error.response.data
                let errorMessage = errorObj.message
                errorObj = { message: errorMessage, error: true, done: true, uid }
                commit('loadingMessages/messages', errorObj, { root: true })
            }
        },
    },
};

export default users;
