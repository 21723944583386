<template>
    <div class="content" v-if='itemParams'>
        <h2 v-text="`${$t('generic.classificationRestriction')}`"></h2>
        <p v-text="`${$t('action.shaping.selectCodesToRestrict')}`"></p>
        <div class="classification-buttons fr fw itemCodes">
            <div class="itemCode fg tc" v-for="param in itemParams" :key="param.name">
                <strong v-if="param.name" v-text="param.name"></strong>
                <ul class="no-bullet">
                    <li class="button" :class="{ active: '' == code }" @click="selectCode(code)"
                        v-for="code in param.codes" v-text="code.value" :key="code.id"></li>
                </ul>
            </div>
        </div>

        <form v-if="selected.length" @submit.prevent="createRestriction()">
            <h4 v-text="`${$t('generic.codeSelected')}`"></h4>
            <div class="classification-buttons fr fw itemCodes">
                <div class="itemCode fg tc" v-for="param in itemParams" :key="param.name">
                    <strong v-if="param.name" v-text="param.name"></strong>
                    <ul class="no-bullet">
                        <li class="button active" v-if="selectedCode(param.name)"
                            @click="unselectCode(selectedCode(param.name))" v-text="selectedCode(param.name).value">
                        </li>
                    </ul>
                </div>
            </div>

            <h4 v-text="`${$t('action.shaping.selectPossibleCodes')}`"></h4>
            <div class="classification-buttons fr fw itemCodes">
                <div class="itemCode fg tc" :class="{ locked: selectedParams.includes(param.name) }"
                    v-for="param in itemParams" :key="param.name">
                    <strong v-if="param.name" v-text="param.name"></strong>
                    <ul class="no-bullet">
                        <li class="button" v-for="code in param.codes" :disabled="selectedParams.includes(param.name)"
                            :class="{ active: selectedCode(param.name) == code || active.includes(code) }"
                            @click="makeActive(code)" v-text="code.value" :key="code.id">
                        </li>
                    </ul>
                </div>
            </div>
            <button class="add-btn" v-text="`${$t('action.add')}`"></button>
        </form>

        <hr />
        <h3 v-text="`${$t('generic.activeRestriction')}`"></h3>
        <form v-for="restriction, index in restrictions" @submit.prevent="deleteRestriction(restriction)" :key="index">
            <div class="classification-buttons fr fw itemCodes">
                <div class="itemCode fg tc" :class="{ locked: selectedParams.includes(param.name) }"
                    v-for="param in itemParams" :key="param.name">
                    <strong v-if="param.name" v-text="param.name"></strong>
                    <ul class="no-bullet">
                        <li class="button" v-for="code in param.codes"
                            :disabled="restriction.rules.blocked.includes(code.id)"
                            :class="{ active: restriction.rules.combination.includes(code.id) }" v-text="code.value"
                            :key="code.id">
                        </li>
                    </ul>
                </div>
            </div>
            <button class="delete" v-text="`${$t('action.shaping.removeRestriction')}`"></button>
        </form>

    </div>
</template>

<script>

export default {
    props: {
        itemCodes: { required: true },
        itemId: { required: true },
    },

    data() {
        return {
            selected: [],
            active: [],
        }
    },

    methods: {
        makeActive(code) {
            let index = this.active.findIndex(({ id }) => id == code.id)
            if (index > -1) {
                this.active.splice(index, 1);
            } else {
                this.active.push(code);
            }
        },
        unselectCode(code) {
            code = this.selected.find(({ name }) => name == code.name)
            let index = this.selected.indexOf(code);
            if (index > -1) {
                this.selected.splice(index, 1);
            }
        },
        selectCode(code) {
            this.unselectCode(code);
            this.selected.push(code);
        },
        selectedCode(paramName) {
            return this.selected.find(({ name }) => name == paramName)
        },
        createRestriction() {
            this.$store.dispatch('exams/items/postRestriction', this.newRestriction)
                .then(() => {
                    this.selected = [];
                    this.active = [];
                });
        },
        deleteRestriction(restriction) {
            this.$store.dispatch('exams/items/deleteRestriction', restriction.id)
        },
    },

    computed: {
        newRestriction() {
            return {
                itemId: this.itemId,
                combination: this.selected.map(({ id }) => id),
                blocked: this.restricted.map(({ id }) => id),
            }
        },
        params() {
            let params = this.itemCodes.map((code) => code.name)
            return params.filter((name, index) => params.indexOf(name) === index)
        },
        paramCodes() {
            let codes = {}
            for (let param of this.params) {
                codes[param] = this.itemCodes.filter(({ name }) => name == param)
            }
            return codes
        },
        restrictions() {
            return this.$store.getters['exams/items/restrictions'](this.itemId)
        },
        itemParams() {
            let params = [];
            let codes = this.itemCodes
            params = [];
            codes = codes.filter(code => code.name !== null);
            for (let index in codes) {
                let code = codes[index];
                let param = params.find(param => param.name == code.name);
                if (!param) {
                    param = { id: code.name, name: code.name, codes: [] };
                    params.push(param);
                }
                param.codes.push(code)
            }
            return params.sort((a, b) => a.name.localeCompare(b.name))
        },
        selectedParams() {
            let params = [];
            for (var code in this.selected) {
                params.push(this.selected[code].name);
            }
            return params;
        },
        restricted() {
            return this.itemCodes.filter((code) => {
                return !(this.selectedParams.includes(code.name) || this.active.includes(code));
            })
        }
    },
    mounted() {
        this.$store.dispatch('exams/items/getRestrictions', this.itemId);
    }
}
</script>


<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.classification-buttons {
    display: flex;
    gap: 1rem;

    .itemCode {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;

        ul {
            padding: 0;
        }

        .button {
            background-color: $white;
            cursor: pointer;
            color: $black;
            padding: 0.5em;
            border: 1px solid $primary-color;
            width: 100%;
            margin-top: 5px;
            list-style-type: none;

            &[disabled=true],
            &:disabled {
                border-color: $black;
                background: rgba($black, .15);
                color: rgba($black, .75);
            }

            &.active {
                background-color: $primary-color;
                color: $white;
            }

        }
    }
}

.add-btn {
    cursor: pointer;
}

button {
    background: transparent;
    border: 1px solid;
    width: 100%;
    padding: .25em;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    color: $primary-color;

    &.delete {
        color: $alert-color;
        cursor: pointer;
    }
}

h4 {
    text-align: center;
    text-transform: uppercase;
    color: $primary-color;
    font-size: 1.1rem;
}

h3 {
    text-align: center;
    text-transform: uppercase;
    color: $primary-color;
    font-size: 1.4rem;
}

hr {
    margin: 3rem 0;
}
</style>
