<template>
    <div class="params">
        <div class="param" v-for="param in params" :key="param">
            <h3 v-if="multi && param != null || !multi && param == null" v-text="param"></h3>
            <template v-if="multi && param != null || !multi && param == null">
                <p v-for="code in paramCodes[param]" :key="code.id" class="box-container-code">
                    <span>
                        <span v-text="code.value"></span>
                        (<span v-text="code.statistic_value"></span>)
                    </span>
                    <button class="btn-removeCode"
                        @click.prevent="$store.dispatch('itemCodes/delete', { item_id: itemId, code_id: code.id })"
                        v-text="`${$t('action.remove')}`"></button>
                </p>
                <ItemCodesCreate class="params-new" :itemId="itemId" :name="param" v-if="param != null" />
            </template>
        </div>
        <div class="newParam" v-if="multi">
            <h3 v-text="`${$t('generic.newParameter')}`"></h3>
            <InputContent class="code-input" label="Nome" v-model="newParam" :itemId="itemId" />
            <ItemCodesCreate class="params-new" :itemId="itemId" :name="newParam" />
        </div>
    </div>
</template>
<script>
import ItemCodesCreate from './ItemCodesCreate.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

export default {
    components: {
        ItemCodesCreate,
        InputContent,
    },
    props: {
        itemId: {
            required: true,
        },
        multi: { required: true },
    },
    data() {
        return {
            newParam: "",
        };
    },
    computed: {
        params() {
            let params = this.itemCodes.map((code) => code.name)
            return params.filter((name, index) => params.indexOf(name) === index)
        },
        paramCodes() {
            let codes = {}
            for (let param of this.params) {
                codes[param] = this.itemCodes.filter(({ name }) => name == param)
            }
            return codes
        },
        itemCodes() {
            return this.$store.getters['itemCodes/getId'](this.itemId)
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.itemId)
        },
    },
    mounted() {
    }

}
</script>
<style lang="scss" scoped>
@import '../../../../../assets/utilities/_variables.scss';

.params {
    display: flex;
    align-items: stretch;
    gap: 10px;

    .param {
        display: flex;
        flex-direction: column;

        .box-container-code {
            padding: 10px;
            width: 100%;
            box-shadow: 0px 2px 6px rgba($black, .4);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-removeCode {
                color: $red;
                border: none;
                border: 1px solid $red;
                cursor: pointer;
                padding: 5px;
                margin-left: 5px;
                border-radius: 5px;
                font-size: small;

                &:hover {
                    background-color: $red;
                    color: $white;
                }
            }
        }


        .material-icons {
            width: 20px;
            fill: $red;
            margin-left: 10px;
            cursor: pointer;


            &:hover {
                font-size: larger;
            }
        }
    }
}

.params-new {
    margin-top: auto;
}

.item-delete {
    margin-top: 15px;
    color: $red;
    cursor: pointer;
}
</style>
