<template>
    <div class="items"
        v-if="exam && (isPratical || Object.keys(attributions).length || supervise || currentUser.role == 2)">
        <BubbleContent v-if="isPratical" :title="title" :buttons="buttons" :loadingInfo="finishLoadingInfo">
            <router-link :to="{ name: 'SessionClassification', params: { session_id: session.id } }" class="itemList"
                v-for="session in sessions" :key="session.id" v-text="session.name">
            </router-link>
        </BubbleContent>

        <BubbleContent v-else :title="title" :buttons="buttons" :loadingInfo="finishLoadingInfo">
            <p class="itemTitle" v-text="`${$tc('models.item')} [ ${$tc('models.answer', 2)} ]`"></p>
            <div v-if="!supervise && currentUser.role != 2">
                <p class="itemList" v-for="attr in attributions" :key="attr.id">
                    <ClassifierItemRow :attr="attr" :state="state" :classifier="classifier" />
                </p>
            </div>
            <div v-else>
                <p class="itemList" v-for="item in items" :key="item.id">
                    <router-link :to="{ name: routes[state], params: { itemId: item.id } }" class="item">
                        <strong>{{ item.name }} </strong>
                        <span v-text="progressText[item.id]"></span>
                    </router-link>
                </p>
            </div>
        </BubbleContent>
    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ClassifierItemRow from '@/components/exams/ClassifierItemRow.vue';

export default {
    props: {
        id: {
            default: null,
        },
        state: { required: true },
        groups: { required: true },
        supervise: { default: false },
    },
    components: {
        BubbleContent,
        ClassifierItemRow,
    },
    data() {
        return {
            fulfilled: true,
            routes: {
                2: "ExamSuperviseItem",
                4: "ExamResolveItem",
            },
            finishLoadingInfo: {
                timeout: 5000,
                done: false,
            },
        }
    },
    methods: {
        finishIt() {
            if (this.classifier == null) {
                return null
            }
            this.$store.dispatch('exams/classifiers/finishIt', this.classifier.id)
        },
    },
    computed: {
        allLocked() {
            for (let i in this.attributions) {
                if (!this.attributions[i].locked) {
                    return false
                }
            }
            return true
        },
        attributions() {
            if (this.supervise || this.currentUser.role == 2) {
                return []
                // return this.items.map((item) => {
                //     return item
                // })
            }
            return this.$store.getters['exams/distribution/examClassifier'](this.id, this.classifier.id)
        },
        buttons() {
            if (this.state != 0 || this.classifier == null) {
                return []
            }
            let disabled = !this.canFinish || this.classifier.classified_items.finished
            return [{ text: 'Finalizar', click: this.finishIt, disabled }]
        },
        canFinish() {
            if (this.state != 0) {
                return false
            }
            for (let i in this.attributions) {
                let attribution = this.attributions[i]
                let classified_items = this.classifier.classified_items[attribution.exam_item_id]
                if (classified_items == null) {
                    return false
                }
                if (classified_items.done == null) {
                    return false
                }
                if (classified_items.done != attribution.classifications_number) {
                    return false
                }
            }
            return true
        },
        classifier() {
            if (this.currentUser == null) {
                return null
            }
            return this.$store.getters['exams/classifiers/userId'](this.currentUser.id)
        },
        currentUser() {
            return this.$root.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        filterItemsByAttribution() {
            const itemAttribution = this.items.filter((item) => item.classifications_number !== 0)
            return itemAttribution
        },
        isPratical() {
            if (this.exam == null) {
                return false
            }
            return this.exam.kind == 1
        },
        items() {
            return this.$store.state.exams.items.all
        },
        progressText() {
            return this.items.reduce((texts, item) => {
                if (this.state == 2) {
                    let count = this.$store.getters['itemClassification/getItemStatus'](item.id, this.state).length
                    texts[item.id] = ` [${count}]`
                }
                else if (this.state == 4 && this.classifier.classified_items[item.id] != null) {
                    let count = this.classifier.classified_items[item.id].resolve
                    if (count == null) {
                        texts[item.id] = ` [0]`
                    } else {
                        texts[item.id] = ` [${count}]`
                    }
                }
                else {
                    texts[item.id] = ` [0]`
                }
                return texts
            }, {})
        },
        sessions() {
            if (this.exam == null) {
                return []
            }
            return this.$store.getters['exams/sessions/exam'](this.exam.id)
        },
        students() {
            if (!this.isPratical) {
                return []
            }
            return this.$store.getters['exams/sessions/students']()
        },
        title() {
            let state = this.$t('action.shaping.classifying')
            if (this.canFinish && this.allLocked) {
                state = this.$t('action.classified')
            }
            return `${state} - ${this.exam.name}`
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler() {
                if (this.currentUser != null) {
                    this.$store.dispatch('exams/distribution/listenUserAttributions', this.currentUser.id)
                }
            },
        },
        classifier: {
            handler() {
                console.log(this.classifier)
                this.finishLoadingInfo = {
                    timeout: 3000,
                    done: true,
                }
            },
        },
        isPratical: {
            immediate: true,
            handler() {
                if (this.isPratical) {
                    this.$store.dispatch('exams/sessions/getAll', this.exam.id)
                }
            },
        },
    },
    mounted() {
        this.$store.dispatch('exams/items/getAll', this.id)
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }

    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.items {
    .itemTitle {
        border-bottom: 2px solid $primary-color;
        margin: 0;
        padding: 1rem 0;

    }

    .itemList {
        border-top: 1px solid gray;
        margin: 0;


        &:hover {
            background: rgba($primary-color, .1);
        }

        .item {
            width: 100%;
            display: block;
            padding: 1rem;
        }
    }
}
</style>
