<template>
    <div class="content-users-edit">
        <BubbleContent :title="`${$t('action.change')}`" :buttons=button :loadingInfo="loadingInfo" @submit="submit">
            <template #form>
                <InputContent :label="`${$t('generic.name')}`" v-model="name" />
                <InputContent :label="`${$t('generic.email')}`" type="email" v-model="email" />
                <InputContent :label="`${$t('generic.password')}`" type="password" v-model="password" />
                <InputSelectContent v-model="role" :label="`${$t('generic.type')}`" :options="options"
                    :choose="choose" />
            </template>
        </BubbleContent>
    </div>
</template>

<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '@/components/input/SelectComponent.vue';
import uuid4 from 'uuid4';


export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            name: "",
            email: "",
            password: "",
            role: 0,
            uid: null,
            options: [
                { text: `${this.$t('action.shaping.chooseAnOptionNotDefine')}`, value: '0' },
                { text: `${this.$t('action.shaping.chooseAnOptionAdmin')}`, value: '1' },
                { text: `${this.$t('action.shaping.chooseAnOptionIave')}`, value: '2' },
            ],
            choose: `${this.$t('action.shaping.chooseAnOption')}`,

        };
    },

    methods: {
        submit() {
            this.$store.dispatch('users/edit', { id: this.id, uid: this.uid, user: this.userData })
        },
    },

    computed: {
        button() {
            return [
                { text: `${this.$t('action.edit')}`, type: 'submit', disabled: !this.canSubmit },
            ]
        },
        canSubmit() {
            return this.email && this.name
        },
        messages() {
            return this.$store.getters['loadingMessages/messages'](this.uid)
        },
        loadingInfo() {
            return this.messages
        },
        user() {
            return this.$store.getters['users/getId'](this.id)
        },
        userData() {
            return {
                email: this.email,
                name: this.name,
                password: this.password,
                role: this.role,
            }
        },

    },

    watch: {
        user: {
            immediate: true,
            handler() {
                if (this.user != null) {
                    this.name = this.user.name
                    this.email = this.user.email
                    this.role = this.user.role
                }
            },
        }
    },

    mounted() {
        this.uid = uuid4();
        if (this.user == null) {
            this.$store.dispatch('users/getId', this.id)
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-users-edit {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }
}
</style>
