import axios from '../axiosInstance'
import theEcho from '../echoInstance'

const exams = {
    namespaced: true,
    state: {
        messages: [],
        channels: [],
        fetchingChannels: false,
        fetchedChannels: false,
    },
    getters: {
        exam: (state) => (id) => {
            return state.channels.filter((channel) => channel.exam_id === parseInt(id));
        },
        channel: (state) => (id) => {
            return state.channels.find((ch) => ch.id == parseInt(id))
        },
        messages: (state) => (id) => {
            return state.messages.filter((ch) => ch.chat_channel_id == parseInt(id))
                .sort((a, b) => a.id - b.id)
        },
    },
    mutations: {
        addChannels(state, channels) {
            state.channels = channels
            state.fetchedChannels = true
            state.fetchingChannels = false
        },
        addMessage(state, message) {
            let index = state.messages.findIndex(({ id }) => message.id == id)
            if (index < 0) {
                state.messages.push(message)
                return
            }
            state.messages.splice(index, 1, message)
        },
        addMessages(state, messages) {
            state.messages = messages
        },
        fetchingChannels(state, value) {
            state.fetchingChannels = value
        },
    },
    actions: {
        // async createMessage({state, commit}, {id, message}) {
        //     const res = await axios.post(
        //         `${process.env.VUE_APP_API_HOST}/chats/${id}`,
        //         message
        //     );
        // },
        async getChannels({ state, commit }) {
            if (state.fetchingChannels || state.fetchedChannels) {
                return
            }
            commit("fetchingChannels", true);
            const res = await axios.get(
                `${process.env.VUE_APP_API_HOST}/chats`
            );
            commit("addChannels", res.data.data);
        },
        async getMessages({ commit }, id) {
            const res = await axios.get(
                `${process.env.VUE_APP_API_HOST}/chats/${id}`
            );
            commit("addMessages", res.data.data);
        },
        listenChannel({ commit }, id) {
            let channel = theEcho.echo.private(`chat.${id}`)
            channel.listen('ChatMessageSent', (e) => {
                console.log(e)
                commit('addMessage', e.message)
            })
        },
    },
};

export default exams;
