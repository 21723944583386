import axios from '@/axiosInstance'

const students = {
    namespaced: true,

    state: {
        all: [],
        schoolStudents: {},
        gotAll: false,
    },

    getters: {
        school: (state) => (id) => {
            let students = state.schoolStudents[id]
            if (students == null) {
                return []
            }
            return students
        },
    },

    mutations: {
        all(state, students) {
            state.all = students
            state.gotAll = true
            for (let newStudent of students) {
                let schoolId = newStudent.school_id
                if (schoolId == null) {
                    continue
                }
                if (state.schoolStudents[schoolId] == null) {
                    state.schoolStudents[schoolId] = []
                }
                const index = state.schoolStudents[schoolId].findIndex(({ id }) => newStudent.id === id)
                if (index < 0) {
                    state.schoolStudents[schoolId].push(newStudent);
                } else {
                    state.schoolStudents[schoolId].splice(index, 1, newStudent)
                }
            }
        },

        // delete(state, student) {
        //     const index = state.all.findIndex(({ id }) => student.id === id);
        //     state.all.splice(index, 1);
        // },
        // student(state, student) {
        //     const index = state.all.findIndex(({ id }) => student.id === id);
        //     if (index < 0) {
        //         state.all.push(student);
        //     } else {
        //         state.all.splice(index, 1, student);
        //     }
        // },
    },

    actions: {
        async getAll({ state, commit }) {
            if (state.gotAll) {
                return
            }
            try {
                const results = await axios.get(`${process.env.VUE_APP_API_HOST}/students`);
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        // async create({ rootState, commit }, newStudent) {
        //     try {
        //         const results = await rootState.axios.post(
        //             `${process.env.VUE_APP_API_HOST}/students`,
        //             newStudent
        //         );

        //         commit("add", results.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },
        // async delete({ rootState, commit }, id) {
        //     try {
        //         const results = await rootState.axios.delete(
        //             `${process.env.VUE_APP_API_HOST}/students/${id}`
        //         );
        //         commit("delete", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },
        // async edit({ rootState, commit }, { id, student }) {
        //     try {
        //         const results = await rootState.axios.put(
        //             `${process.env.VUE_APP_API_HOST}/students/${id}`,
        //             student
        //         );
        //         commit("student", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },
        // async getId({ getters, dispatch }, id) {
        //     if (getters.getId(id) == null) {
        //         dispatch("fetchId", id);
        //     }
        // },
    },
};

export default students;

