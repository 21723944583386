<template>
    <BubbleContent :title="session.name" :buttons="button" @submit="edit">
        <h3 v-text="`${$tc('models.classifier', 2)}:`"></h3>
        <table class="table">
            <thead class="head">
                <tr>
                    <th class="items" v-for="column of columns" :key="column.text">
                        {{ column.text }}
                    </th>
                </tr>
            </thead>
            <tbody clas="classifiers">
                <tr v-for="classifier in classifiers" :key="classifier.id">
                    <td>
                        <span v-if="classifier.name" v-text="classifier.name"></span>
                        <span v-else v-text="classifier.nr_classificador"></span>
                    </td>
                    <td>
                        <span v-text="classifier.classified"></span> /
                        <span v-text="classifier.classifications_total"></span>
                    </td>
                    <td>
                        <span @click.prevent="remove(classifier)" v-text="`${$t('action.remove')}`"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </BubbleContent>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';

export default {
    props: {
        session: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.shaping.updateGroup')}`, type: 'submit' },
            ],
            columns: [
                { text: `${this.$t('generic.name')} / ${this.$t('generic.classifierNumber')}` },
                { text: `${this.$tc('models.classification', 2)}` },
            ],
            searchClassifier: '',
            classifier: null,
            newClassifiers: [],
            removeClassifiers: [],
        };
    },
    methods: {
        remove(classifier) {
            this.$store.dispatch('exams/sessions/classifiers/delete', { session: this.session.id, classifier: classifier.id })
        },
    },
    computed: {
        classifiers() {
            return this.$store.getters['exams/sessions/classifiers/id'](this.session.id)
        },
    },
    watch: {
        group: {
            immediate: true,
            handler() {
                if (this.group == null) {
                    return
                }
                if (this.group.user_id != null) {
                    this.$store.dispatch('users/getId', this.group.user_id)
                }
                if (this.group.users == null) {
                    this.$store.dispatch('exams/groups/getClassifiers', this.id)
                    return
                }
                for (let userId of this.group.users) {
                    this.$store.dispatch('users/getId', userId)
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
        this.$store.dispatch("exams/sessions/classifiers/getAll", this.session.id);
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.table {
    width: 100%;
}

.head {
    text-align: left;
}

.classifiers {
    display: block;
    background: red;

    tr {
        td {
            border-bottom: 1px solid gray;
        }
    }
}
</style>
