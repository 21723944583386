<template>
    <div class="loading" :style="style" v-if="visible">
        <div class="bar"></div>
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            default: 0,
        }
    },
    data() {
        return {
            visible: true,
        }
    },
    computed: {
        style() {
            return {
                '--progress': `${this.progress}%`,
            }
        }
    },
    watch: {
        progress: {
            immediate: true,
            handler() {
                if (this.progress == 0) {
                    this.visible = true
                }
                if (this.progress == 100) {
                    this.visible = false
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.loading {
    height: 4px;
    background: rgba($primary-color, .3) !important;

    .bar {
        width: var(--progress);
        background-color: $primary-color;
        height: 4px;
        transition: .2s ease-out width;
    }
}
</style>
