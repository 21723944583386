<template>
    <div class="session" v-if="session">
        <BubbleContent :title="session.name" :buttons="actions" class="session_edit" :loadingInfo="loading"
            @submit="submit">
            <template #form>
                <InputContent :label="$t('generic.name')" v-model="name" />
                <SelectComponent :label="$t('models.school')" v-model="schoolId" :options="schoolOptions" />
                <InputContent :label="$t('generic.executionDate')" type="datetime-local" v-model="executionDate" />
                <InputContent :label="$t('generic.startAt')" type="datetime-local" v-model="startsAt" />
                <InputContent :label="$t('generic.endAt')" type="datetime-local" v-model="endsAt" />
            </template>
        </BubbleContent>

        <SessionClassifiers :session="session" class="session_classifiers"></SessionClassifiers>
        <SessionUsers :session="session" class="session_users"></SessionUsers>

        <SessionStudents :session="session" class="session_students"></SessionStudents>
        <SchoolStudents :session="session" class="session_schoolStudents"></SchoolStudents>
    </div>
</template>

<script>
import BubbleContent from "@/components/SCOI/BubbleContent/BubbleContent"
import SessionClassifiers from "@/components/exams/SessionClassifiers"
import SessionStudents from "@/components/exams/SessionStudents"
import SessionUsers from "@/components/exams/SessionUsers"
import SchoolStudents from "@/components/exams/SchoolStudents"
import InputContent from "@/components/SCOI/Inputs/InputContent"
import SelectComponent from "@/components/input/SelectComponent"

export default {
    props: {
        id: {
            required: true,
        },
        session_id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
        InputContent,
        SelectComponent,
        SessionClassifiers,
        SessionStudents,
        SessionUsers,
        SchoolStudents,
    },
    data() {
        return {
            name: "",
            schoolId: 0,
            executionDate: "",
            startsAt: "",
            endsAt: "",
            loading: {},
        }
    },
    methods: {
        submit() {
            this.loading = { done: false }
            this.$store.dispatch('exams/sessions/update', {
                id: this.session_id,
                name: this.name,
                school_id: this.schoolId,
                execution_at: this.executionDate,
                classification_from: this.startsAt,
                classification_to: this.endsAt,
            })
        },
        delete() {
            this.loading = { done: false }
            this.$store.dispatch('exams/sessions/delete', this.session_id)
            this.$router.push({ name: 'ExamSessions' })
        },
    },
    computed: {
        actions() {
            return [
                { text: this.$t('action.save'), type: 'submit', action: this.submit },
                { text: this.$t('action.remove'), type: 'submit', class: 'alert', click: this.delete },
            ]
        },
        schools() {
            return this.$store.state.schools.all
        },
        schoolOptions() {
            return this.schools.map((school) => {
                return { text: `[${school.code}] ${school.name}`, value: school.id }
            })
        },
        session() {
            return this.$store.getters['exams/sessions/id'](this.session_id)
        },
    },
    watch: {
        session: {
            immediate: true,
            handler() {
                if (this.session != null) {
                    this.loading = { done: true, timeout: 1000 }
                    this.name = this.session.name
                    this.schoolId = this.session.school_id
                    this.executionDate = this.session.execution_at.format("YYYY-MM-DDTHH:mm")
                    this.startsAt = this.session.classification_from.format("YYYY-MM-DDTHH:mm")
                    this.endsAt = this.session.classification_to.format("YYYY-MM-DDTHH:mm")
                    return
                }
                this.$store.dispatch('exams/sessions/get', this.session_id)
            },
        },
        schools: {
            immediate: true,
            handler() {
                if (this.schools.length != 0) {
                    return
                }
                this.$store.dispatch('schools/getAll')
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.session {
    padding: $regular-padding;
    height: 100%;
    gap: $regular-padding;
    display: grid;
    overflow: auto;
    grid-template-areas:
        "edit edit"
        "classifiers users"
        "students schoolStudents";

    >* {
        max-height: 100%;
    }

    &_edit {
        height: max-content;
        grid-area: edit;
    }

    &_classifiers {
        height: max-content;
        grid-area: classifiers;
    }

    &_users {
        height: max-content;
        grid-area: users;
    }

    &_students {
        height: max-content;
        grid-area: students;
    }

    &_schoolStudents {
        height: max-content;
        grid-area: schoolStudents;
    }
}
</style>
