<template>
    <div class="session" v-if="setupComplete">
        <BubbleContent :title="session.name" :buttons="actions" class="session_edit" :loadingInfo="loading"
            @submit="submit">
            <template #form>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Nome
                            </th>
                            <th v-for="item in items" :key="item.id" v-text="item.name"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="student in students" :key="student.id">
                            <td v-text="student.name"></td>
                            <td v-for="item, id in itemOptions" :key="id">
                                <SelectComponent :label="`${name}`" v-for="codes, name in itemOptions[id]" :key="name"
                                    v-model="selectedCodes[student.id][id][name]" :options="itemOptions[id][name]" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </BubbleContent>

    </div>
</template>

<script>
import BubbleContent from "@/components/SCOI/BubbleContent/BubbleContent"
import SelectComponent from "@/components/input/SelectComponent"

export default {
    props: {
        id: {
            required: true,
        },
        session_id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
        SelectComponent,
    },
    data() {
        return {
            name: "",
            executionDate: "",
            startsAt: "",
            endsAt: "",
            loading: {},
            selectedCodes: {},
            setupComplete: false,
            actions: [
                { text: 'save' }
            ],
        }
    },
    methods: {
        setupSelectedCodes() {
            if (this.items.length == 0 || this.students.length == 0) {
                return
            }
            this.selectedCodes = {}
            for (let student of this.students) {
                this.selectedCodes[student.id] = {}
                for (let itemId in this.itemOptions) {
                    this.selectedCodes[student.id][itemId] = {}
                    for (let name in this.itemOptions[itemId]) {
                        this.selectedCodes[student.id][itemId][name] = null
                    }
                }
            }
            this.setupComplete = true
        },
        submit() {
            this.$store.dispatch('exams/sessions/submit', {
                students: this.classifications,
                session: this.session_id,
            })
            this.loading = { done: true, timeout: 1000 }
        },
    },
    computed: {
        classifications() {
            let classifications = []
            for (let studentId in this.selectedCodes) {
                for (let itemId in this.selectedCodes[studentId]) {
                    for (let name in this.selectedCodes[studentId][itemId]) {
                        let value = this.selectedCodes[studentId][itemId][name]
                        if (value == null) {
                            continue
                        }
                        classifications.push({
                            id: studentId,
                            item_id: itemId,
                            item_code_id: value,
                        })
                    }
                }
            }
            return classifications
        },
        session() {
            return this.$store.getters['exams/sessions/id'](this.session_id)
        },
        students() {
            return this.$store.getters['exams/sessions/students/id'](this.session_id)
        },
        items() {
            if (this.session == null) {
                return []
            }
            return this.$store.getters["exams/items/byExamId"](this.session.exam_id)
        },
        itemOptions() {
            let options = {}
            for (let item of this.items) {
                options[item.id] = item.item_codes.reduce((codes, code) => {
                    if (codes[code.name] == null) {
                        codes[code.name] = []
                    }
                    codes[code.name].push({ text: code.value, value: code.id })
                    return codes
                }, {})
            }
            return options
        },
    },
    watch: {
        items: {
            immediate: true,
            handler() {
                this.setupSelectedCodes()
            },
        },
        students: {
            immediate: true,
            handler() {
                if (this.students.length == 0) {
                    this.$store.dispatch('exams/sessions/students/getAll', this.session_id)
                    return
                }
                this.setupSelectedCodes()
            },
        },
    },
    mounted() {
        this.$store.dispatch("exams/sessions/classifiers/getAll", this.session_id);
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

table {
    width: 100%;

    tbody {
        tr:nth-child(odd) {
            background-color: rgba($secondary-color, .3);
        }

    }
}

.session {
    padding: $regular-padding;
    height: 100%;
    gap: $regular-padding;
    display: grid;
    overflow: auto;
    grid-template-areas:
        "edit edit"
        "classifiers users"
        "students schoolStudents";

    >* {
        max-height: 100%;
    }

    &_edit {
        height: max-content;
        grid-area: edit;
    }

    &_classifiers {
        height: max-content;
        grid-area: classifiers;
    }

    &_users {
        height: max-content;
        grid-area: users;
    }

    &_students {
        height: max-content;
        grid-area: students;
    }

    &_schoolStudents {
        height: max-content;
        grid-area: schoolStudents;
    }
}
</style>
