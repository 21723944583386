import axios from '@/axiosInstance'

const sessionClassifiers = {
    namespaced: true,

    state: () => ({
        all: {},
    }),

    getters: {
        id: (state) => (id) => {
            let classifiers = state.all[id]
            if (classifiers == null) {
                return []
            }
            return classifiers
        },
    },

    mutations: {
        add(state, { classifier, id }) {
            let session = state.all[id]
            if (session == null) {
                session = []
            }
            const index = session.findIndex(({ id }) => classifier.id == id);
            if (index < 0) {
                session.push(classifier);
            } else {
                session.splice(index, 1, classifier);
            }
            state.all[id] = session
        },
        all(state, { classifiers, id }) {
            state.all[id] = classifiers;
        },
        clear(state) {
            state.all = {}
        },
        delete(state, { classifier, id }) {
            const index = state.all[id].findIndex(({ id }) => classifier.id === id);
            state.all[id].splice(index, 1);
        },
    },

    actions: {
        async create({ commit }, { session, user_id }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/classifiers`,
                    { user_id }
                );
                commit("add", { classifier: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async delete({ commit }, { session, classifier }) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/classifiers/${classifier}`,
                );
                commit("delete", { classifier: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        async getAll({ commit }, session) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/sessions/${session}/classifiers`,
                );
                commit("all", { classifiers: results.data.data, id: session });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },

        // async update({ commit }, session) {
        //     console.log(session)
        //     try {
        //         const results = await axios.post(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${session.id}`,
        //             session
        //         );
        //         commit("add", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async get({ commit }, id) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${id}`
        //         );
        //         commit("add", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async getClassifiers({ commit }, id) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/sessions/${id}/classifiers`
        //         );
        //         commit("addClassifiers", { id, data: results.data.data });
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },

        // async getId({ commit }, { exam, session }) {
        //     try {
        //         const results = await axios.get(
        //             `${process.env.VUE_APP_API_HOST}/exams/${exam}/sessions/${session}`
        //         );
        //         commit("group", results.data.data);
        //     } catch (error) {
        //         console.log("ERROR: " + error);
        //     }
        // },
    },
};

export default sessionClassifiers;
