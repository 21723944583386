const loadingMessages = {
    namespaced: true,

    state: {
        messages: {
            null: {
                uid: null,
                message: "A Processar",
                error: null,
                done: true
            }
        },
    },

    getters: {
        uid: (state) => (uid) => {
            if (state.messages == null) {
                return null
            }
            return state.messages[uid]
        },
        messages: (state) => (uid) => {
            return state.messages[uid]
        },
    },

    mutations: {
        clearMessage(state, uid) {
            state.messages[uid] = null
        },
        messages(state, message) {
            state.messages[message.uid] = message
        },
    },

    actions: {
        async messages({ commit }, message) {
            commit("messages", message)
        },
    },
};

export default loadingMessages;
