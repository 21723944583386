<template>
    <div class="show" v-if="isAdmin">
        <ExamInfoClassification v-if="exam" :id="id" />
        <ExamInfoSupervisorClassifiers v-for="group in groups" :key="group.id" :id="group.id" />
    </div>
</template>

<script>
import ExamInfoClassification from '@/components/exams/GeneralInfo.vue';
import ExamInfoSupervisorClassifiers from '@/components/SCOI/TabExams/ExamInfo/ExamInfoSupervisorClassifiers.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamInfoClassification,
        ExamInfoSupervisorClassifiers,
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        groups() {
            return this.$store.getters['exams/groups/exam'](this.id)
        },
        isAdmin() {
            return this.currentUser != null && this.currentUser.role == 1
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.id)
        },
        classifiedTotal() {
            return this.classifiers.reduce((total, classifier) => {
                for (let itemId in classifier.classified_items) {
                    if (total[itemId] == null) {
                        total[itemId] = { total: 0, classified: 0 }
                    }
                    total[itemId].classified += classifier.classified_items[itemId].classified
                    total[itemId].total += classifier.classified_items[itemId].total
                }
                return total
            }, {})
        },
    },
    watch: {
    },
    mounted() {
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.show {
    display: flex;
    flex-direction: column;
    gap: $regular-padding;

    >* {
        flex-shrink: 0;
    }
}
</style>
