<template>
    <div class="tp-container">
        <router-link class="container-scoi" to="/entrar">
            {{ vTextScoi }}
            <small v-text="`${$tc('generic.scoiVersion')}`"></small>
        </router-link>
        <div class="container-links">
            <router-link class="links-anchor" to="/entrar" v-text="`${$t('action.login')}`"></router-link>
            <router-link class="links-anchor" to="/senha-reset"
                v-text="`${$t('action.shaping.recoverPassword')}`"></router-link>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            vTextScoi: `${this.$tc('action.login')}`
        };
    }

}

</script>

<style lang="scss" scoped>
@import '../../assets/utilities/_variables.scss';


.tp {

    &-container {
        @media print {
            display: none;

        }

        .container-scoi {
            color: $primary-color;
            text-decoration: inherit;
        }

        .container-links {
            color: $primary-color;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }

        background: $topbar-bg-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        // FIXED LAYOUT
        height: $topbar-height;
        z-index: 1050;
        position: relative;


        // SHADOW
        &::after {
            content: '';
            position: absolute;
            bottom: -$topbar-shadow-size;
            left: 0;
            right: 0;
            height: $topbar-shadow-size;
            background-image: linear-gradient(180deg, $topbar-shadow-color, rgba($topbar-shadow-color, 0));
        }

        .links-anchor {
            color: inherit;
            text-decoration: inherit;
            display: flex;
            align-items: center;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0);
            transition-property: color, background-color;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

}
</style>
