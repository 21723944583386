import axios from '../axiosInstance'
import theEcho from '../echoInstance'

import answers from "@/store/exams/answers.js";
import attributions from "@/store/exams/attributions.js";
import classifiers from "@/store/exams/classifiers.js";
import distribution from "@/store/exams/distribution.js";
import groups from "@/store/exams/groups.js";
import items from "@/store/exams/items.js";
import sessions from "@/store/exams/sessions.js";

import moment from 'moment';

function setupExam(exam) {
    exam.starts_at = moment(exam.starts_at)
    exam.ends_at = moment(exam.ends_at)
}

const exams = {
    namespaced: true,
    state: {
        all: [],
        allChannel: null,
        examChannels: [],
        gotAll: false,
        exam: null,
        examExportFiles: [],
        examExportFilesDownload: [],
        exportLoadingInfo: { done: false },
    },
    getters: {
        getId: (state) => (id) => {
            return state.all.find((exam) => exam.id === parseInt(id));
        },
        last(state) {
            return state.all.reduce((pr, curr, index) => {
                if (index == 0) return curr
                if (pr.id > curr.id) return pr
                return curr
            }, null)
        },
        examChannel: (state) => (id) => {
            let channelData = state.examChannels.find((data) => data.id == id)
            if (channelData == null) {
                return null
            }
            return channelData.channel
        },
        exportFiles: (state) => (id) => {
            const examFiles = state.examExportFiles.find((files) => parseInt(files.id) === parseInt(id));
            if (examFiles) {
                return examFiles.files
            }
            return []
        },
    },
    mutations: {
        all(state, exams) {
            for (let exam of exams) {
                setupExam(exam)
            }
            state.all = exams;
            state.gotAll = true;
        },
        examChannel(state, { id, channel }) {
            state.examChannels.push({ id, channel })
        },
        allChannel(state, channel) {
            state.allChannel = channel
        },
        clear(state) {
            state.all = []
            state.exam = null
            state.gotAll = false
        },
        delete(state, exam) {
            const index = state.all.findIndex(({ id }) => exam.id === id);
            state.all.splice(index, 1);
        },
        exam(state, exam) {
            const index = state.all.findIndex(({ id }) => exam.id === id);
            setupExam(exam)
            if (index < 0) {
                state.all.push(exam);
            } else {
                exam = { ...state.all[index], ...exam }
                state.all.splice(index, 1, exam);
            }
        },
        examExportFiles(state, { id, files }) {
            const index = state.examExportFiles.findIndex(files => files.id === id);
            if (index < 0) {
                state.examExportFiles.push({ id, files });
            } else {
                state.examExportFiles.splice(index, 1, { id, files });
            }
        },
        examExportFilesDownload(state, { id, downloading }) {
            const index = state.examExportFilesDownload.findIndex(fileId => fileId === id);
            if (index < 0 && downloading) {
                state.examExportFilesDownload.push(id);
            } else if (index >= 0 && !downloading) {
                state.examExportFilesDownload.splice(index, 1);
            }
        },
        setExportLoadingInfo(state, info) {
            console.log("SETTING")
            console.log(info)
            state.exportLoadingInfo = info
        },
    },
    actions: {
        async calc(store, id) {
            try {
                const results = await axios.post(`${process.env.VUE_APP_API_HOST}/exams/${id}/calc`)
                console.log(results)
            } catch (error) {
                console.log(error)
            }
        },
        async create({ commit }, { examData, uid }) {
            try {
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams`,
                    examData
                );
                let exam = results.data.data;
                exam.created = true;
                commit("exam", exam);
                let successObj = {
                    message: results.data.message,
                    error: false,
                    done: true,
                    uid,
                };
                commit("loadingMessages/messages", successObj, { root: true });
            } catch (error) {
                console.log(error);
                let errorObj = error.response.data;
                let errorMessage = errorObj.message;
                errorObj = { message: errorMessage, error: true, done: true, uid };
                commit("loadingMessages/messages", errorObj, { root: true });
            }
        },
        async delete({ commit }, id) {
            try {
                const results = await axios.delete(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}`
                );
                commit("delete", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async download({ state, commit }, { id, file }) {
            try {
                let fileDownloading = state.examExportFilesDownload.includes(file.id)
                if (fileDownloading) {
                    return
                }
                commit("examExportFilesDownload", { id: file.id, downloading: true })
                const res = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/export/${file.id}`,
                    { responseType: 'blob' }
                );
                commit("examExportFilesDownload", { id: file.id, downloading: false })
                let blob = new Blob([res.data], { type: 'application/octet-stream' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = file.filename
                link.click()
            } catch (error) {
                console.log("ERROR:" + error)
            }
        },
        async edit({ commit }, { id, exam, uid }) {
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}`,
                    exam
                );
                commit("exam", results.data.data);
                let successObj = { message: results.data.message, error: false, done: true, uid }
                commit('loadingMessages/messages', successObj, { root: true })
            } catch (error) {
                console.log('ERROR: ' + error)
                let errorObj = error.response.data
                let errorMessage = errorObj.message
                errorObj = { message: errorMessage, error: true, done: true, uid }
                commit('loadingMessages/messages', errorObj, { root: true })
            }
        },
        async getAll({ state, commit }) {
            if (state.gotAll) {
                return
            }
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams`
                );
                commit("all", results.data.data);
            } catch (error) {
                console.log("ERROR: " + error);
                commit('clear')
            }
        },
        async getId({ commit }, id) {
            try {
                const results = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}`
                );
                let exam = results.data.data;
                commit("exam", exam);
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        async getExportFiles({ commit }, id) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/export`
                );
                commit("examExportFiles", { id, files: res.data.data });
            } catch (error) {
                console.log("ERROR: " + error);
            }
        },
        // EXPORT EXAM ACTION
        async export({ commit }, id) {
            commit('setExportLoadingInfo', { done: false })
            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/export`,
                );
                commit('setExportLoadingInfo', { done: true })
                console.log(res.data.data)
            } catch (error) {
                console.log("ERROR:" + error)
            }
        },
        // IMPORT EXAM ANSWERS ACTION
        async import({ commit }, { id, file, attachment, uid }) {
            try {
                let formData = new FormData();
                formData.append("file", file);
                formData.append("attachment", attachment);
                const results = await axios.post(
                    `${process.env.VUE_APP_API_HOST}/exams/${id}/import`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                let exam = results.data.data
                commit('exam', exam)
                let successObj = {
                    message: results.data.message,
                    error: false,
                    done: true,
                    uid,
                };
                commit("loadingMessages/messages", successObj, { root: true });
            } catch (error) {
                console.log("ERROR:" + error)
                let errorObj = error.response.data;
                let errorMessage = errorObj.message;
                errorObj = { message: errorMessage, error: true, done: true, uid };
                commit("loadingMessages/messages", errorObj, { root: true });
            }
        },

        listen({ getters, commit }, id) {
            let channel = getters['examChannel'](id)
            if (channel != null) {
                return
            }
            channel = theEcho.echo.private(`exam.${id}`)
            channel.listen('ExamDistributed', (e) => {
                commit('distribution/addAttributions', { exam: id, attributions: e.attributions })
                commit('exam', e.exam)
            })
            commit('examChannel', { id, channel })
        },

        listenAll({ state, commit }) {
            if (state.allChannel != null) {
                return
            }
            let channel = theEcho.echo.private('exams')
            console.log("Listening to exams channel")
            channel.listen('Exams\\ExamCreated', (e) => {
                commit('exam', e.exam)
            })
            channel.listen('Exams\\ExamUpdated', (e) => {
                console.log("Exam updated")
                commit('exam', e.exam)
            })
            channel.listen('Exams\\ExamDeleted', (e) => {
                commit('delete', e.exam)
            })
            commit('allChannel', channel)
        },
        // RESET EXAM STATE ACTION
        async reset({ commit }, id) {
            const res = await axios.post(
                `${process.env.VUE_APP_API_HOST}/exams/${id}/reset`
            );
            commit("exam", res.data.data);
        },
    },
    modules: {
        answers,
        attributions,
        classifiers,
        distribution,
        items,
        sessions,
        groups,
    },
};

export default exams;
