<template>
    <tr class="info-list-classifiers" :style="{ '--progress': `${progressPercent}%` }" v-if="assigned > 0"
        :class="{ noclick: classified == 0 }">
        <td v-text="name"></td>
        <td v-text="classified"></td>
        <td v-text="assigned - classified"></td>
        <td v-text="assigned"></td>
    </tr>
</template>

<script>
export default {
    props: {
        classifier: {
            required: true,
        },
        examId: {
            required: true,
        },
    },
    data() {
        return {
            assigned: 0,
            classified: 0,
        };
    },
    computed: {
        attributions() {
            console.log("ASDFSAF");
            return this.$store.getters['exams/distribution/examClassifier'](this.examId, this.classifier.id)
        },
        name() {
            if (this.classifier.name) {
                return this.classifier.name
            }
            return this.classifier.nr_classificador
        },
        progressPercent() {
            return (this.classified / this.assigned * 100)
        },
    },
    watch: {
        attributions: {
            immediate: true,
            handler() {
                this.classified = 0
                this.assigned = 0
                for (let i in this.attributions) {
                    let attribution = this.attributions[i]
                    this.classified += attribution.classified
                    this.assigned += attribution.classifications_number
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.info-list-classifiers {
    margin: 0;
    border-top: 1px solid gray;
    position: relative;

    &:not(.noclick) {
        cursor: pointer;
    }

    td {
        padding: calc($regular-padding/2) $regular-padding;
        white-space: nowrap;

        &:first-child {
            width: 100%;
        }

        &:last-child {
            text-align: right;
        }
    }

    &:after {
        content: '';
        height: 100%;
        width: var(--progress);
        left: 0;
        position: absolute;
        background: rgba($secondary-color, .5);
        z-index: 1;
    }

    >* {
        z-index: 10;
        position: relative;
    }

    &:hover {
        background: rgba($primary-color, 0.1);
        cursor: pointer;

    }

}
</style>
