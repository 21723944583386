<template>
    <LoginBody>
        <form class="form-login" @submit.prevent="login">
            <template v-if="token == null && code == null">
                <input autofocus v-model="email" :placeholder="`${$t('generic.email')}`" required type="email"
                    ref="email" :disabled="loading" />
                <strong class="errorMsg" v-if="errorMsg">{{ errorMsg.data.email[0] }}</strong>
                <input v-model="password" :placeholder="$t('generic.password')" required type="password"
                    :disabled="loading" />
                <div class="form-checkbox-container">
                    <input type="checkbox" name="rememberCheckbox" @click="rememberCredentials">
                    <span class="remember-text" v-text="$t('action.rememberMe')"></span>
                </div>
                <strong class="errorMsg" v-if="error" v-text="$t(`errors.${error.code}`)"></strong>
                <a class="oauth" @click.prevent="popup" href="#" v-if="showGovAuth">
                    <img src="@/assets/images/autenticacaogov.png" alt="">
                </a>
                <button class="form-submit" type="submit" :disabled="!canSubmit" v-text="$t('action.login')"></button>
            </template>
            <template v-else-if="token != null">
                <div class="loading loading-error" v-if="error">
                    {{ error }}
                </div>
                <div class="loading" v-else v-text="$t('action.shaping.aboutToLogin')"></div>
            </template>
            <div class="loading" v-else>
                <h2>Acesso Não Autorizado</h2>
            </div>
        </form>
    </LoginBody>
</template>

<script>
import LoginBody from '@/components/LoginBody/LoginBody.vue';

export default {
    props: {
        code: {
            default: null
        },
        token: {
            default: null
        },
    },
    components: {
        LoginBody,
    },
    data() {
        return {
            email: "",
            password: "",
            rememberMe: false,
            changed: false,
            loading: false,
        };
    },
    methods: {
        login() {
            this.loading = true
            this.changed = false
            this.$store.dispatch('users/login', {
                email: this.email,
                password: this.password,
            })
        },
        popup() {
            let url = `${process.env.VUE_APP_AUTH_URL}?`
            url += "response_type=code"
            url += `&client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}`
            // url += "&client_id=BbC5r-VmezHs9tioIopZlJLB"
            url += `&redirect_uri=${encodeURIComponent(window.location.origin + "/auth")}`
            // url += "&redirect_uri=https://www.oauth.com/playground/authorization-code.html"
            url += "&scope=photo"
            url += "&state=CnKD8ekjSuv3pLhS"
            let popup = window.open(url, 'Login', 'width=800,height=600')
            popup.addEventListener('message', (e) => {
                console.log(e)
            })
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.users.currentUser
        },
        error() {
            if (this.changed) {
                return null
            }
            return this.$store.state.users.error
        },
        canSubmit() {
            if (this.error != null) {
                return false
            }
            if (this.email == "" || this.password == "") {
                return false
            }
            return true
        },
        showGovAuth() {
            let setting = this.$store.getters['settings/name']('govAuth')
            if (setting != null) {
                return setting.value
            }
            return false
        },
        errorMsg() {
            return this.$store.state.users.errorMsg
        },
    },
    watch: {
        email() {
            this.changed = true
        },
        password() {
            this.changed = true
        },
        error() {
            if (this.error != null) {
                this.loading = false
                this.$nextTick().then(() => {
                    this.$refs.email.focus()
                })
            }
        },
        token: {
            immediate: true,
            handler() {
                if (this.token != null) {
                    this.$store.dispatch('loginWithToken', this.token)
                }
            }
        },
        currentUser: {
            immediate: true,
            handler() {
                if (this.currentUser != null) {
                    this.$router.push(this.$store.state.afterLoginRoute)
                }
            }
        }
    },
    mounted() {
    }
}

</script>

<style lang="scss" scoped>
@import '../assets/utilities/_variables.scss';

.oauth {
    display: block;
    width: 300px;
    padding: 0;
    margin: auto auto 2rem;
    border: none;
    transition: .2s ease-out filter;

    &:not(:hover) {
        filter: opacity(.75) saturate(0);
    }

    img {
        width: 100%;
    }
}

.lg {
    &-background {
        max-height: 100vh;
        height: 100vh;
        margin: 0;

        &.imageCover {
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-position: center;
        }
    }

    &-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        width: 90%;
        max-width: 500px;
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 10px;
        color: $white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-logo-container {
        margin: 0;
        padding: 25px;
        background: rgba(255, 255, 255, 0.65);
        text-align: center;
        width: 100%;
    }

}

.loading {
    color: $white;
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    margin: 1em 0;
    text-transform: uppercase;
}


.form {
    &-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;
        width: 100%;

        .errormsg {
            display: flex;
            align-items: center;
            padding: 1em 0 0 1em;
            width: 100%;
            color: rgba($red, 1);
        }

        input:not([type=checkbox]) {
            border: none;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            border-bottom: 4px solid rgba($black, 0.6);
            background: none;
            margin-top: 10px;
            outline: none;
            color: $white;

            &:active,
            &:focus {
                border-bottom: 4px solid $white;
                transition: 0.2s border-color ease-out;
            }

            &:disabled {
                border-color: $dark-border-color;
            }

        }

    }

    &-checkbox-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 5em;
        margin-left: 10px;

        .remember-text {
            margin-left: 5px;
        }
    }

    &-submit {
        width: calc(100% + 20px);
        color: $primary-color;
        background-color: rgba(255, 255, 255, 0);
        text-transform: uppercase;
        border-color: transparent;
        border-top: 1px solid $primary-color;
        z-index: 1;
        cursor: pointer;
        transition-duration: 0.4s;
        line-height: 1;
        height: 2em;
        padding: .1em 1em .2em;
        text-align: center;

        &:hover {
            background-color: rgba($black, 0.261);
        }

        &:disabled {
            opacity: .5;
            background-color: rgba(255, 255, 255, 0);
        }

    }
}
</style>
