<template>
    <ExamInfoSupervisor v-if="supervisor" :id="id" />
    <ClassifiersInfo v-if="classifiers" :id="id" :examId="group.exam_id" />
</template>

<script>
import ExamInfoSupervisor from './ExamInfoSupervisor.vue';
import ClassifiersInfo from '@/components/exams/ClassifiersInfo.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamInfoSupervisor,
        ClassifiersInfo,

    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },

}
</script>

<style lang="scss" scoped></style>
