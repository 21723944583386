import axios from "@/axiosInstance"

const itemClassification = {
    namespaced: true,
    state: () => ({
        all: [],
        messages: [],
        fetching: [],
    }),
    getters: {
        getState: (state) => (items, status) => {
            return state.all.filter((classification) => {
                return items.includes(classification.exam_item_id) && classification.state == status
            })
        },
        getItemStatus: (state) => (item, status) => {
            console.log(`getting ${item} ${status}`)
            return state.all.filter((classification) => {
                return classification.exam_item_id == item && classification.state == status
            })
        },
        getId: (state) => (id) => {
            return state.all.filter((classification) => classification.id === parseInt(id))
        },
        getItem: (state) => (itemId, classificationState = 0) => {
            return state.all.filter((classification) => classification.exam_item_id == itemId && classification.state == classificationState)
        },
        fetching: (state) => (pageData) => {
            return state.fetching.findIndex(({ itemId, page, state }) => {
                return itemId == pageData.itemId &&
                    page == pageData.page &&
                    state == pageData.state
            }) > -1
        }
    },
    mutations: {
        addMsg(state, messages) {
            state.messages.push(messages)
        },
        addMany(state, classifications) {
            state.all.push(...classifications)
        },
        add(state, classification) {
            let foundIndex = state.all.findIndex(({ id }) => id == classification.id)
            if (foundIndex < 0) {
                state.all.push(classification)
                return
            }
            state.all.splice(foundIndex, 1, classification)
        },
        all(state, classifications) {
            state.all = classifications;
        },
        clear(state) {
            state.all = []
        },
        fetched(state, pageData) {
            let index = state.fetching.findIndex(({ itemId, page, state }) => {
                return itemId == pageData.itemId &&
                    page == pageData.page &&
                    state == pageData.state
            })
            state.fetching.splice(index, 1)
        },
        fetching(state, pageData) {
            state.fetching.push(pageData)
        },
    },
    actions: {
        async classify({ commit }, { classification, code, state, message }) {
            let classificationId = classification.id
            let params = {
                state,
                itemCodes: code.map(({ id }) => id),
                message,
            }
            try {
                const results = await axios.put(
                    `${process.env.VUE_APP_API_HOST}/exams/items/classifications/${classificationId}`,
                    params
                );
                classification.state = results.data.data.state
                commit('add', results.data.data.classification)
                commit('exams/classifiers/update', {
                    classifierId: results.data.data.classifier_id,
                    itemId: results.data.data.classification.exam_item_id,
                    o: results.data.data.previous_state,
                    n: results.data.data.new_state,
                }, { root: true })
                commit('exams/attributions/update', {
                    itemId: results.data.data.classification.exam_item_id,
                    o: results.data.data.previous_state,
                    n: results.data.data.new_state,
                }, { root: true })
            } catch (error) {
                console.log("ERROR " + error);
            }
        },
        async getToReview({ commit }, { exam, supervisor }) {
            let url = `${process.env.VUE_APP_API_HOST}/exams/${exam}/toreview/${supervisor}`
            try {
                const results = await axios.get(url);
                for (let classification of results.data.data) {
                    commit('add', classification)
                }
            } catch (error) {
                console.log("ERROR " + error);
            }
        },
        async getOne({ commit, getters }, { exam, examItem, page, state = 0, classifier = null }) {
            if (page == null) page = 1
            let pageData = { examId: examItem, page, state }
            if (getters.fetching(pageData)) {
                return
            }
            let classifications = getters['getItem'](examItem, state)
            if (classifications.length >= page) {
                return
            }
            commit('fetching', pageData)
            let url = `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${examItem}/classifications`
            if (classifier != null) {
                url += `/${classifier}`
            }
            try {
                const results = await axios.get(
                    url,
                    {
                        params: {
                            page, state
                        }
                    }
                );
                commit('add', results.data.data)
            } catch (error) {
                console.log("ERROR " + error);
            }
            commit('fetched', pageData)

        },
        storeMessage({ commit }, message) {
            commit('addMsg', message)
        },
    },
};

export default itemClassification;
