<template>
    <div class="classifiers">
        <BubbleContent :title="title" class="classifierInfo" v-if="selectedItem == null">
            <table>
                <thead>
                    <ClassifierInfoHeader :row="columns" @click.prevent="selectClassifier(null)" />
                </thead>
                <tbody v-if="selectedClassifier == null">
                    <ClassifierInfoRow v-for="classifier in classifiers" :examId="examId" :key="classifier.id"
                        :classifier="classifier" @click.prevent="selectClassifier(classifier)" />
                </tbody>
                <tbody v-else>
                    <ClassifierInfoItemRow v-for="item, id in selectedClassifier.classified_items" :key="id"
                        :itemId="id" :info="item" @click.prevent="selectItem(id)" />
                </tbody>
            </table>
        </BubbleContent>
        <AnswerCard v-else :itemId="selectedItem" :id="examId" :state="3" :disabled="true" :title="title"
            :back="() => { selectedItem = null }" :classifierId="selectedClassifier.id"></AnswerCard>
    </div>
</template>

<script>
import AnswerCard from '@/components/exams/AnswerCard.vue'
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ClassifierInfoRow from '@/components/exams/ClassifierInfoRow.vue'
import ClassifierInfoItemRow from '@/components/exams/ClassifierInfoItemRow.vue'
import ClassifierInfoHeader from '@/components/exams/ClassifierInfoHeader.vue'

export default {
    props: {
        id: {
            required: true,
        },
        examId: {
            required: true,
        },
    },
    components: {
        AnswerCard,
        BubbleContent,
        ClassifierInfoRow,
        ClassifierInfoItemRow,
        ClassifierInfoHeader,
    },
    data() {
        return {
            selectedClassifier: null,
            selectedItem: null,
        };
    },
    methods: {
        selectClassifier(classifier) {
            this.selectedClassifier = classifier
        },
        selectItem(id) {
            this.selectedItem = id
        },
    },
    computed: {
        attributions() {
            if (this.selectedClassifier == null) {
                return []
            }
            return this.$store.getters['exams/distribution/examClassifier'](
                this.examId,
                this.selectedClassifier.id
            )
        },
        classifiers() {
            if (this.group) {
                return this.$store.getters['exams/classifiers/groups']([this.group.id])
            }
            return []
        },
        columns() {
            if (this.selectedClassifier == null) {
                return [
                    { text: this.$t('generic.name') },
                    { text: this.$t('generic.classified_f') },
                    { text: this.$t('generic.missing') },
                    { text: this.$t('generic.total') },
                ]
            }
            return [
                { text: this.$t('models.item') },
                { text: this.$t('generic.classified_f') },
                { text: this.$t('generic.missing') },
                { text: this.$t('generic.total') },
            ]
        },
        group() {
            return this.$store.getters['exams/groups/getId'](this.id)
        },
        item() {
            if (this.selectedItem == null) {
                return null
            }
            return this.items.find(({ id }) => id == this.selectedItem)
        },
        items() {
            return this.$store.getters['exams/items/byExamId'](this.examId)
        },
        title() {
            if (this.selectedClassifier == null) {
                return `${this.$t('action.supervise')}: ${this.group.name}`
            }
            if (this.selectedItem == null) {
                return `${this.$t('action.supervise')}: ${this.group.name} > ${this.selectedClassifier.name}`
            }
            return `${this.$t('action.supervise')}: ${this.group.name} > ${this.selectedClassifier.name} > ${this.item.name}`
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

table {
    width: calc(100% + 2 * $regular-padding);
    border-collapse: collapse;
    margin: 0 (-$regular-padding);
}

.classifierInfo {
    min-height: 400px;
}


.info-list-classifiers {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);
        cursor: pointer;

    }

    .info-classifiers {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);

    }
}
</style>
