<template>
    <div :class="messageClass" class="loadingSpin">
        <div v-html="message"></div>
        <IconComponent v-if="icon" :icon="icon" class="icon" />
    </div>
</template>

<script>
import IconComponent from '@/components/IconComponent.vue';
export default {
    props: {
        info: {
            default: null,
        }
    },
    components: {
        IconComponent
    },
    computed: {
        icon() {
            if (this.info.error) {
                return "crossV2"
            }
            if (this.info.done) {
                return "correct"
            }
            return null
        },
        messageClass() {
            if (this.info == null || !this.info.done) {
                return "loader"
            }
            if (this.info.error) {
                return "error"
            }
            return "success"
        },
        message() {
            if (this.info == null || this.info.message == null) {
                return this.$t('action.shaping.processing')
            }
            return this.info.message
        }
    },
}

</script>

<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadingSpin {
    text-align: center;
}

.loader {
    background: rgba($primary-color, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $primary-color;
    font-size: larger;
    font-weight: bold;
}

.loader--hidden {
    opacity: 0;
    visibility: hidden;
}

.loader::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 15px solid $white;
    border-top-color: $primary-color;
    border-radius: 50%;
    animation: loading 0.75s linear infinite;
}

.error {
    background: rgba($red, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $red;
    font-size: larger;
    font-weight: bold;

    .icon {
        width: 80px;
        fill: $red;
    }
}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}

.success {
    background: rgba($green, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.75s, visibility 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    border-radius: 0 0 10px 10px;
    color: $green;
    font-size: larger;
    font-weight: bold;

    .icon {
        width: 90px;
        fill: $green;
    }
}

.success--hidden {
    opacity: 0;
    visibility: hidden;
}
</style>
