<template>
    <tr>
        <th v-for="column, index in row" :key="index" v-text="column.text">
        </th>
    </tr>
</template>

<script>
export default {
    props: {
        row: {
            default: [],
        },
    },
    data() {
        return {
        };
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

tr {
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;

    th {
        padding: $regular-padding;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;

        &:first-child {
            width: 100%;
        }

        &:last-child {
            text-align: right;
        }
    }
}
</style>
