<template>
    <router-link :to="{ name: routes[state], params: { itemId: item.id } }" v-if="item" class="item"
        :class="{ noclick }">
        <strong>{{ item.name }} </strong>
        <span v-text="progressText"></span>
    </router-link>
</template>
<script>

export default {
    props: {
        state: { default: 0 },
        attr: { required: true },
        classifier: { required: true },
    },

    data() {
        return {
            routes: {
                0: "ClassifierExamAnswersContent",
                1: "ClassifiersExamSavedAnswersContent",
                2: "ClassifiersExamToBeReviewdAnswersContent",
                3: "ClassifiersExamToReviewAnswersContent",
            },
        }
    },

    computed: {
        noclick() {
            if (this.state != 0 && this.classified == 0) {
                return true
            }
            return this.state == 0 && this.classified == this.attr.classifications_number
        },
        classified() {
            if (this.item == null) {
                return 0
            }
            if (this.classifier == null) {
                return 0
            }
            let classifiedItem = this.classifier.classified_items[this.item.id]
            let done = 0
            let review = 0
            let saved = 0
            if (classifiedItem != null) {
                if (classifiedItem.done != null) {
                    done = classifiedItem.done
                }
                if (classifiedItem.review != null) {
                    review = classifiedItem.review
                }
                if (classifiedItem.saved != null) {
                    saved = classifiedItem.saved
                }
            }
            switch (this.state) {
                case 0:
                    return (done + review + saved)
                case 1:
                    return saved
                case 2:
                    return review
                case 3:
                    return done
                default:
                    break
            }
            return 0
        },
        item() {
            return this.$store.getters['exams/items/getId'](this.attr.exam_item_id)
        },
        progressText() {
            if (this.item == null) {
                return "...."
            }
            if (this.classifier == null) {
                return "...."
            }
            let text = ` [`
            text += this.classified
            if (this.state == 0) {
                text += ` / ${this.attr.classifications_number}`
            }
            text += `]`
            return text
        },
    },
}
</script>

<style scoped>
.item {
    width: 100%;
    display: block;
    padding: 1rem;
}
</style>
