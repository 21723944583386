<template>
    <div class="content-documents-create">
        <BubbleContent :title="`${$t('action.shaping.addDocument')}`" :buttons=buttons :loadingInfo="info"
            @submit="$store.dispatch('documents/create', newDocumentData)">
            <template #form>
                <InputContent :label="`${$t('generic.title')}`" v-model="title" />
                <SelectComponent :label="`${$tc('models.exam')}`" :options="examSelectOptions" v-model="exam" />
                <InputFile v-model="file" :label="`${$t('action.shaping.pressToChooseFile')}`" />
            </template>
        </BubbleContent>
    </div>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';
import InputFile from '@/components/SCOI/Inputs/InputFile.vue';
import SelectComponent from "@/components/input/SelectComponent";

import { mapState } from 'vuex';

export default {
    components: {
        BubbleContent,
        InputContent,
        InputFile,
        SelectComponent,
    },
    data() {
        return {
            buttons: [
                { text: `${this.$t('action.save')}`, type: 'submit' },
            ],
            exam: null,
            file: null,
            title: "",
        };
    },
    computed: {
        ...mapState({
            created: state => state.documents.created,
            creating: state => state.documents.creating,
        }),
        newDocumentData() {
            return {
                id: this.exam,
                title: this.title,
                file: this.file[0],
            }
        },
        exams() {
            return this.$store.state.exams.all
        },
        examSelectOptions() {
            return this.exams.map(exam => { return { text: exam.name, value: exam.id } })
        },
        info() {
            let info = {
                done: false,
                message: this.$t('action.shaping.uploading')
            }
            if (!this.creating && this.created == null) {
                return info
            }
            if (this.creating) {
                return info
            }
            if (!this.creating) {
                info.message = this.$t('action.shaping.uploaded')
            }
            if (this.created != null) {
                info.done = true
            }
            return info
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.content-documents-create {
    margin: 20px;
    color: $black;

    .field {
        margin-bottom: 1.5em;

        .document-file {
            cursor: pointer;
            color: $primary-color;
        }
    }

    .input {
        opacity: 0;
        overflow: hidden;
    }

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;
            }


        }
    }

}
</style>
