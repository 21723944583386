<template>
    <BubbleContent :title="$tc('action.shaping.addClassifier', 2)">
        <table class="table">
            <thead class="head">
                <tr>
                    <th class="items" v-for="column of columns" :key="column.text">
                        {{ column.text }}
                    </th>
                </tr>
            </thead>
            <tbody class="classifiers">
                <tr v-for="student in students" :key="student.id" @click.prevent="add(student)">
                    <td>
                        <span v-if="student.name" v-text="student.name"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </BubbleContent>
</template>

<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';

export default {
    props: {
        session: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            button: [
                { text: `${this.$t('action.shaping.updateGroup')}`, type: 'submit' },
            ],
            columns: [
                { text: `${this.$t('generic.name')} / ${this.$t('generic.classifierNumber')}` },
            ],
            searchClassifier: '',
            newClassifiers: [],
            removeClassifiers: [],
        };
    },
    methods: {
        add(student) {
            this.$store.dispatch('exams/sessions/students/create', { session: this.session.id, student_id: student.id })
        },
    },
    computed: {
        students() {
            return this.$store.getters['students/school'](this.session.school_id)
        },
    },
    watch: {
    },
    mounted() {
        this.$store.dispatch("students/getAll");
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.table {
    width: 100%;
    margin-bottom: $regular-padding;
}

.head {
    text-align: left;
}

.classifiers {
    tr {
        cursor: pointer;

        td {
            padding: calc($regular-padding/2) 0;
        }
    }
}
</style>
